import React, { useState, useContext, useEffect, memo } from "react";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Draggable from "react-draggable";

// eslint-disable-next-line

import AlertaContext from "../../../../Context/Alerta/AlertaContext";

import AuthContext from "../../../../Context/Auth/AuthContext";
import clienteAxios from "../../../../config/axios";
import Dropzone from "../../../components/Dropzone";
import { FechaExacta, trimPropsItem } from "../../../../config/const";
import { objectDefault as objectDefaultAnticipo } from "../../../Anticipos/data";
import ModalAnticipo from "../../../Anticipos/Componentes/Modal";

// MATERIAL
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  useTheme,
  // FormControlLabel,
  // Switch,
  TextField,
  FormControl,
  Dialog,
  // Collapse,
  // InputAdornment,
  MenuItem,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import {
  PersonAdd,
  Cancel,
  Check,
  Close,
  Create,
  Delete,
  AddCircle,
} from "@mui/icons-material";
import ModalTurnoExtra from "../../../TurnoExtra/Componentes/Modal";
import { objectDefault as objectDefaultTurnoExtra } from "../../../TurnoExtra/data";
import { useModalStyle } from "../../../styles/styleModal";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import useMultimediasUpload from "../../../hooks/useMultimediasUpload";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalAsistencia = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    fromHorarios,
    // nomEstado: "EN PROCESO",
  } = props;

  const [item, setitem] = useState(ObjectoActivo);
  const [Openimagen, setOpenimagen] = useState(false);
  const [cargando, setcargando] = useState(false);
  const [openTurnoExtra, setopenTurnoExtra] = useState(false);
  const [editarTurnoExtra, seteditarTurnoExtra] = useState(false);
  const [clientesData, setclientesData] = useState([]);
  const [multasData, setmultasData] = useState(null);
  const [listaData, setlistaData] = useState([]);
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // const [collapseFotos, setcollapseFotos] = useState(false);
  const [seccionActive, setseccionActive] = useState({
    DatosPrincipales: true,
    Archivos: false,
    Apelacion: false,
    Anular: false,
  });

  const [docuCliente, setdocuCliente] = useState("");
  const { DatosPrincipales, Archivos, Apelacion, Anular } = seccionActive;
  // eslint-disable-next-line
  const theme = useTheme();
  const {
    // nomCodigo,
    // nomOrigen,
    // nomTipo,
    nomPorcentaje,
    // nomSueldo,
    // nomDescuento,
    nomDetalle,
    nomObservacion,
    nomFotos,
    // nomVideo,
    // nomCompartido,
    // nomPuesto,
    nomEstado,
    nomAnulacion,
    // nomCiudad,
    nomFecha,
    nomIdPer,
    nomDocuPer,
    nomNombrePer,
    nomApelacionTexto,
    nomApelacionFotos,
    nomApelacionFecha,
    nomApelacionTextoAceptada,
    nomMostrarEnInforme,
    // nomApelacionFechaAceptada,
    // nomApelacionUserAceptada,
    // nomUser,
    // nomEmpresa,
    // nomFecReg,
    // Todos,
  } = item;
  // eslint-disable-next-line
  const [error, seterror] = useState({
    nomCodigo: false,
    nomOrigen: false,
    nomTipo: false,
    nomPorcentaje: false,
    nomSueldo: false,
    nomDescuento: false,
    nomDetalle: false,
    nomObservacion: false,
    nomFotos: false,
    nomVideo: false,
    nomCompartido: false,
    nomPuesto: false,
    nomEstado: false,
    nomAnulacion: false,
    nomCiudad: false,
    nomFecha: false,
    nomIdPer: false,
    nomDocuPer: false,
    nomNombrePer: false,
    nomApelacionTexto: false,
    nomApelacionFotos: false,
    nomApelacionFecha: false,
    nomApelacionTextoAceptada: false,
    nomApelacionFechaAceptada: false,
    nomApelacionUserAceptada: false,
    nomUser: false,
    nomEmpresa: false,
    nomFecReg: false,
    Todos: false,
  });

  useEffect(() => {
    const getDocuCliente = async () => {
      if (!editar || ObjectoActivo.nomDocuPer === "") {
        return;
      }

      const res = await clienteAxios.get(
        `/personas/filtro/0?&search=${ObjectoActivo.nomDocuPer}`
      );
      setclientesData(res.data.data);
      const itemF = res.data.data.find(
        (clienteData) => clienteData.perDocNumero === ObjectoActivo.nomDocuPer
      );
      if (!itemF?.perDocuCliente && ObjectoActivo.nomPorcentaje !== "00") {
        mostrarAlerta("Esta persona no tiene cliente", "error");
      }

      setdocuCliente(itemF?.perDocuCliente || "");
    };
    getDocuCliente();
    if (editar) {
      const getAnticipos = async () => {
        try {
          const res = await clienteAxios.get(
            `/anticipos/byid?lista=${ObjectoActivo.idAnticipo}`
          );
          setItemsAnticipos(res.data.data);
        } catch (error) {
          setItemsAnticipos([]);
        }
      };
      getAnticipos();
      const getTurnos = async () => {
        try {
          const res = await clienteAxios.get(
            `/turnoextra/byid?lista=${ObjectoActivo.idTurno}`
          );

          setItemTurnoExtra(res.data.data[0]);
        } catch (error) {
          setItemTurnoExtra({});
        }
      };
      getTurnos();
    }
    const getData = async () => {
      const res = await clienteAxios.get(
        "/novedades/filtro/0?search=MULTAS&origen=NOVEDAD"
      );

      setmultasData(res.data.data);
    };
    if (!multasData) {
      getData();
    }
    if (editar) {
      if (ObjectoActivo.accion === "APELACION") {
        setseccionActive({
          Apelacion: true,
        });
      }
      if (
        ObjectoActivo.accion === "ASIGNADA" ||
        ObjectoActivo.accion === "ASIGNADA"
      ) {
        setseccionActive({
          Anular: true,
        });
      }
      setitem({
        ...ObjectoActivo,
      });
    } else {
      setitem({
        ...ObjectoActivo,
      });
    }
    // eslint-disable-next-line
  }, [open]);

  const [itemTurnoExtra, setItemTurnoExtra] = useState({});
  const [itemsAnticipos, setItemsAnticipos] = useState([]);
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });

  useEffect(() => {
    socket.current.on("server:guardadoExitoso", (data) => {
      setitemSocket({
        tipo: "agregar",
        item: data,
      });
      socket.current.on("server:actualizadoExitoso", (data) => {
        setitemSocket({
          tipo: "editar",
          item: data,
        });
      });
      socket.current.on("server:eliminadoExitoso", (data) => {
        setitemSocket({
          tipo: "eliminar",
          item: data,
        });
      });
    });

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { item, tipo } = itemSocket;
    if (!open) {
      return;
    }
    if (tipo === "") {
      return;
    }

    if (tipo === "agregar" && item.tabla === "anticipo") {
      if (item.antUser !== item.result[0].antUser) {
        return;
      }
    }

    if (usuario.usuario !== item.turUser && usuario.usuario !== item.antUser) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }

      if (item.tabla === "turnoextra") {
        if (tipo === "agregar") {
          setItemTurnoExtra(item);
          setitem((prev) => ({ ...prev, idTurno: item.turId }));
        }
        if (tipo === "editar") {
          setItemTurnoExtra(item);
        }
      }

      if (item.tabla === "anticipo") {
        if (tipo === "agregar") {
          setItemsAnticipos(item.result);
        }
        if (tipo === "editar") {
          setItemsAnticipos(
            itemsAnticipos.map((itemAnticipo) =>
              itemAnticipo.antId === item.antId ? item : itemAnticipo
            )
          );
        }
        if (tipo === "eliminar") {
          setItemsAnticipos(
            itemsAnticipos.filter((itemAnticipo) => {
              return Number(itemAnticipo.antId) !== item.antId;
            })
          );
        }
      }
      setitemSocket({ tipo: "", item: {} });
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    funcionAalerta();

    // eslint-disable-next-line
  }, [itemSocket]);

  const defaultActive = (eliminar) => {
    changeObjetoActivoState({
      nomCodigo: "",
      nomOrigen: "",
      nomTipo: "",
      nomPorcentaje: "",
      nomSueldo: "",
      nomDescuento: "",
      nomDetalle: "",
      nomObservacion: "",
      nomFotos: [],
      nomVideo: [],
      nomCompartido: [],
      nomPuesto: [
        // {
        //   ruccliente: "0891763786001",
        //   razonsocial: "COMITÉ PRO MEJORAS URBANIZACIÓN TECNIPETROL",
        //   ubicacion: "ESMERALDAS",
        //   puesto: "TECNIPETROL",
        // },
      ],
      nomEstado: "EN PROCESO",
      nomAnulacion: "",
      nomCiudad: "",
      nomFecha: "",
      nomIdPer: "",
      nomDocuPer: "",
      nomNombrePer: "",
      nomApelacionTexto: "",
      nomApelacionFotos: [],
      nomCorreo: [],
      nomApelacionFecha: "",
      nomApelacionTextoAceptada: "",
      nomApelacionFechaAceptada: "",
      nomApelacionUserAceptada: "",
      nomUser: "",
      nomEmpresa: "",
      nomFecReg: "",
      Todos: "",
    });
    setopen(false);
    seteditar(false);
    seterror({
      nomCodigo: false,
      nomOrigen: false,
      nomTipo: false,
      nomPorcentaje: false,
      nomSueldo: false,
      nomDescuento: false,
      nomDetalle: false,
      nomObservacion: false,
      nomFotos: false,
      nomVideo: false,
      nomCompartido: false,
      nomPuesto: false,
      nomEstado: false,
      nomAnulacion: false,
      nomCiudad: false,
      nomFecha: false,
      nomIdPer: false,
      nomDocuPer: false,
      nomNombrePer: false,
      nomApelacionTexto: false,
      nomApelacionFotos: false,
      nomApelacionFecha: false,
      nomApelacionTextoAceptada: false,
      nomApelacionFechaAceptada: false,
      nomApelacionUserAceptada: false,
      nomUser: false,
      nomEmpresa: false,
      nomFecReg: false,
      Todos: false,
    });
    setdocuCliente("");
    setseccionActive({
      DatosPrincipales: true,
      Archivos: false,
      Apelacion: false,
      Anular: false,
    });
    setimagenes([]);
    setmodificoImagenes(false);
    setcargando(false);
    setopenConfirmDialog(false);
    if (itemTurnoExtra?.turId && eliminar && !editar) {
      try {
        socket.current.emit("client:eliminarData", {
          tabla: "turnoextra",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          turUser: usuario.usuario,
          turEmpresa: usuario.rucempresa,
          turId: itemTurnoExtra.turId,
          turFecReg: itemTurnoExtra.turFecReg,
        });
      } catch (error) {}
    }
    if (itemsAnticipos.length > 0 && eliminar && !editar) {
      itemsAnticipos.forEach((anticipo) => {
        socket.current.emit("client:eliminarData", {
          nombre: usuario.nombre,
          tabla: "anticipo",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          antUser: usuario.usuario,
          antEmpresa: usuario.rucempresa,
          antId: anticipo["antId"],
          antPeriodo: anticipo.antPeriodo,
          antDocuPersona: anticipo.antDocuPersona,
          antTipo: anticipo.antTipo,
        });
      });
    }
    setItemTurnoExtra({});
    setItemsAnticipos([]);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };

  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);

  const validacion = () => {
    if (
      nomPorcentaje === "" ||
      nomObservacion.trim() === "" ||
      nomDetalle.trim() === "" ||
      nomFecha.trim() === "" ||
      nomDocuPer.trim() === "" ||
      (nomPorcentaje === "00" && itemsAnticipos.length === 0)
    ) {
      seterror({
        nomObservacion: nomObservacion.trim() === "" ? true : false,
        nomDetalle: nomDetalle.trim() === "" ? true : false,
        nomFecha: nomFecha.trim() === "" ? true : false,
        nomDocuPer: nomDocuPer.trim() === "" ? true : false,
        nomPorcentaje:
          nomPorcentaje === "" ||
          (nomPorcentaje === "00" && itemsAnticipos.length === 0),
      });
      return true;
    } else {
      return false;
    }
  };
  const handleGuardar = () => {
    if (editar) {
      handleEditar();
    } else {
      handleCrear();
    }
  };
  // eslint-disable-next-line
  const handleCrear = async () => {
    if (
      (nomDetalle === "ABANDONO DE PUESTO" ||
        nomDetalle === "ESTADO ETILICO O ALIENTO A LICOR" ||
        nomDetalle === "FALTA INJUSTIFICADA") &&
      !itemTurnoExtra.turId
    ) {
      return mostrarAlerta("Debe agreagar un turno extra", "error");
    }
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }

    try {
      setcargando(true);

      const resDocumentos = await subirImagenes(imagenes, nomFotos);
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          // PARAMETROS
          nomFotos: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          nomUser: usuario.usuario,
          nomEmpresa: usuario.rucempresa,
          tabla: "nominanovedad",
          idTurno: itemTurnoExtra.turId,
          idAnticipo: itemsAnticipos.map((itemAnticipo) => itemAnticipo.antId),
          fromHorarios,
          // nomEstado: fromHorarios ? "EN PROCESO" : item.nomEstado,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }

    try {
      setcargando(true);

      const resDocumentos = await subirImagenes(imagenes, nomFotos);
      if (ObjectoActivo.accion === "APELACION") {
        return socket.current.emit(
          "client:actualizarData",
          trimPropsItem({
            ...item,
            //APELACION
            nomApelacionFechaAceptada: new Date().toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            }),
            nomApelacionUserAceptada: usuario.usuario,
            nomEstado: "ASIGNADA",
            // PARAMETROS
            nomFotos: resDocumentos,
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            nomUser: usuario.usuario,
            nomEmpresa: usuario.rucempresa,
            tabla: "nominanovedad",
          })
        );
      }
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          // PARAMETROS
          nomFotos: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          nomEmpresa: usuario.rucempresa,
          tabla: "nominanovedad",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  // OBJETO ACTIVO O PLANTILLA
  const [ObjectoActivoTurnoExtra, setObjetoActivoTurnoExtra] = useState(
    objectDefaultTurnoExtra
  );
  // CAMBIA EL OBJETO ACTIVO DEL STATE GENERALMENTE PARA EDITAR
  const changeObjetoActivoStateTurnoExtra = (item) => {
    setObjetoActivoTurnoExtra(item);
  };

  // ABRIR MODAL
  const [openAnticipo, setopenAnticipo] = useState(false);
  // SI SE ESTA EDITANDO O NO
  const [editarAnticipo, seteditarAnticipo] = useState(false);
  // OBJETO ACTIVO O PLANTILLA
  const [ObjectoActivoAnticipo, setObjetoActivoAnticipo] = useState(
    objectDefaultAnticipo
  );
  const changeObjetoActivoStateAnticipo = (item) => {
    setObjetoActivoAnticipo(item);
  };
  if (!open) {
    return null;
  }
  if (!multasData) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <ConfirmacionDialog
          open={openConfirmDialog}
          setopen={() => setopenConfirmDialog(false)}
          titulo={"¿Seguro de realizar esta acción?"}
          categoria="warning"
          botones={[
            {
              tooltip: "GUARDAR",
              texto: "SI",
              funcion: () => {
                handleGuardar();
              },
              disabled: false,
              Icon: Check,
              color: "secondary",
              id: 1,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: { marginRight: ".3rem" },
            },
            {
              tooltip: "NO GUARDAR",
              texto: "NO",
              funcion: () => {
                setopenConfirmDialog(false);
              },
              disabled: false,
              Icon: Close,
              color: "error",
              id: 2,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: {},
            },
          ]}
        />
        <ModalAnticipo
          estadoRequest={"Anticipos"}
          editar={editarAnticipo}
          seteditar={seteditarAnticipo}
          setopen={setopenAnticipo}
          open={openAnticipo}
          ObjectoActivo={ObjectoActivoAnticipo}
          changeObjetoActivoState={changeObjetoActivoStateAnticipo}
          socket={socket}
          desdeDis={false}
        />
        <ModalTurnoExtra
          open={openTurnoExtra}
          setopen={setopenTurnoExtra}
          editar={editarTurnoExtra}
          seteditar={seteditarTurnoExtra}
          ObjectoActivo={ObjectoActivoTurnoExtra}
          changeObjetoActivoState={changeObjetoActivoStateTurnoExtra}
          socket={socket}
          desdeAusencia={true}
        />
        <Draggable handle="#handleMulta">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Dialog
                open={Openimagen}
                onClose={() => setOpenimagen(false)}
                aria-labelledby="draggable-dialog-title"
              >
                <img width="100%" src={Openimagen} alt="imagen" />
              </Dialog>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handleMulta"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"} Multa.
                </Typography>
                <Typography variant="h5" color="initial" align="center">
                  {item.cliId}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive(true);
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={DatosPrincipales ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ DatosPrincipales: true })}
                  >
                    Datos Principales
                  </Button>
                  <Button
                    disabled={editar}
                    variant={Archivos ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ Archivos: true })}
                  >
                    Archivos
                  </Button>
                  {editar ? (
                    <Button
                      variant={Apelacion ? "contained" : null}
                      disableElevation
                      style={{ padding: "8px" }}
                      color="secondary"
                      onClick={() => setseccionActive({ Apelacion: true })}
                    >
                      Status
                    </Button>
                  ) : null}
                </Box>

                {/* <FormControlLabel
                control={
                  <Switch
                    checked={
                      nomEstado === "ACTIVO" || nomEstado === "ASIGNADA"
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setitem({
                        ...item,
                        nomEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                p={1}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems=" center"
                  width="100%"
                >
                  {itemTurnoExtra?.turId ? (
                    <>
                      <Typography
                        variant="body1"
                        color="secondary"
                        fontWeight={"bold"}
                      >
                        Turno Creado:
                        <Typography
                          variant="body1"
                          color="primary"
                          component={"span"}
                        >
                          {" "}
                          {itemTurnoExtra.turDocuPersona}{" "}
                          {itemTurnoExtra.turNomPersona}
                        </Typography>
                      </Typography>
                      <Box display={"flex"} alignItems="center">
                        <IconButton
                          style={{ margin: "0px" }}
                          onClick={() => {
                            changeObjetoActivoStateTurnoExtra(itemTurnoExtra);
                            seteditarTurnoExtra(true);
                            setopenTurnoExtra(true);
                          }}
                        >
                          <Create color="secondary" />
                        </IconButton>
                        <IconButton
                          style={{ margin: "0px" }}
                          onClick={() => {
                            socket.current.emit("client:eliminarData", {
                              rucempresa: usuario.rucempresa,
                              rol: usuario.rol,
                              turUser: usuario.usuario,
                              turEmpresa: usuario.rucempresa,
                              turId: itemTurnoExtra.turId,
                              turFecReg: itemTurnoExtra.turFecReg,
                              tabla: "turnoextra",
                            });
                            setItemTurnoExtra({});
                          }}
                        >
                          <Delete color="error" />
                        </IconButton>
                      </Box>
                    </>
                  ) : null}
                </Box>{" "}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems=" center"
                  width="100%"
                  flexWrap={"wrap"}
                >
                  {itemsAnticipos.map((itemAnticipo) => (
                    <>
                      <Typography
                        key={itemAnticipo.antId}
                        variant="body1"
                        color="primary"
                        fontWeight={"bold"}
                      >
                        Ant:{" "}
                        <Typography
                          variant="body1"
                          color="primary"
                          component={"span"}
                        >
                          {itemAnticipo.antDocuPersona}{" "}
                          {itemAnticipo.antNomPersona} - $
                          {itemAnticipo.antValor} - {itemAnticipo.antPeriodo}
                        </Typography>
                      </Typography>
                      <Box display={"flex"} alignItems="center"></Box>
                    </>
                  ))}
                </Box>
                {DatosPrincipales ? (
                  <>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        disabled={fromHorarios}
                        options={clientesData.map((item) => {
                          return (
                            item.perDocNumero +
                            "/+/" +
                            item.perApellidos +
                            " " +
                            item.perNombres
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").replace("/+/", " ");
                        }}
                        id="controlled-demo"
                        value={
                          (nomDocuPer + " " + nomNombrePer).trim() === ""
                            ? ""
                            : nomDocuPer + " " + nomNombrePer
                        }
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = clientesData.find(
                              (clienteData) =>
                                clienteData.perDocNumero === options[0]
                            );

                            if (itemF.perDocuCliente === "") {
                              mostrarAlerta(
                                "Es probable que esta persona no tenga cliente",
                                "warning"
                              );
                            }
                            setdocuCliente(itemF.perDocuCliente);

                            if (itemF) {
                              setitem({
                                ...item,
                                nomIdPer: itemF.perId,
                                nomDocuPer: itemF.perDocNumero,
                                nomNombrePer:
                                  itemF.perApellidos + " " + itemF.perNombres,
                                nomPuesto: itemF.perPuestoServicio,
                                nomCiudad: itemF.perLugarTrabajo,
                              });
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            disabled={
                              editar ||
                              itemsAnticipos.length > 0 ||
                              fromHorarios
                            }
                            label={"Persona"}
                            fullWidth
                            error={error.nomDocuPer}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta(
                                  "Ingrese un documento",
                                  "error"
                                );
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/personas/filtro/0?&search=${e.target.value}`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }

                                setclientesData(res.data.data);
                              } catch (error) {}
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {!editar ? <> </> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={multasData.map((item) => {
                          return item.novPorcentaje + "/+/" + item.novId;
                        })}
                        disableClearable
                        getOptionLabel={(option) => {
                          return String(option).split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={nomPorcentaje}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = multasData.find(
                              (clienteData) =>
                                clienteData.novId === Number(options[1])
                            );

                            if (itemF) {
                              setitem({
                                ...item,
                                nomPorcentaje: itemF.novPorcentaje,
                                nomCodigo: itemF.novId,
                                nomOrigen: itemF.novOrigen,
                                nomTipo: itemF.novTipo,
                                nomDetalle: "",
                              });
                              setlistaData(itemF.novlista);
                            } else {
                              setitem({
                                ...item,
                                nomPorcentaje: "",
                                nomCodigo: "",
                                nomOrigen: "",
                                nomTipo: "",
                                nomDetalle: "",
                              });
                              setlistaData([]);
                            }
                          } else {
                            setitem({
                              ...item,
                              nomPorcentaje: "",
                              nomCodigo: "",
                              nomOrigen: "",
                              nomTipo: "",
                              nomDetalle: "",
                            });
                            setlistaData([]);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            disabled={editar}
                            required
                            label={"Porcentaje Multa"}
                            fullWidth
                            error={error.nomPorcentaje}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {nomPorcentaje === "00" && nomDocuPer !== "" && (
                                    <>
                                      <Button
                                        disabled={itemsAnticipos.length > 0}
                                        variant="outlined"
                                        size="small"
                                        onClick={() => {
                                          setopenAnticipo(true);
                                          changeObjetoActivoStateAnticipo({
                                            ...ObjectoActivoAnticipo,
                                            antIdPersona: nomIdPer,
                                            antDocuPersona: nomDocuPer,
                                            antNomPersona: nomNombrePer,
                                            antFecha: nomFecha,
                                          });
                                        }}
                                      >
                                        Ag. Anticipo
                                        <AddCircle color="secondary" />
                                      </Button>
                                    </>
                                  )}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={listaData.map((item) => {
                          return item.nombre;
                        })}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                        id="controlled-demo"
                        value={nomDetalle}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            setitem({
                              ...item,
                              nomDetalle: newValue,
                            });
                          } else {
                            setitem({
                              ...item,
                              nomDetalle: "",
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            label={"Tipo Multa"}
                            disabled={editar}
                            fullWidth
                            error={error.nomDetalle}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {nomDetalle === "ABANDONO DE PUESTO" ||
                                  nomDetalle ===
                                    "ESTADO ETILICO O ALIENTO A LICOR" ||
                                  nomDetalle === "FALTA INJUSTIFICADA" ||
                                  (editar && nomEstado === "ASIGNADA") ||
                                  (editar && nomEstado === "EN PROCESO") ? (
                                    <Tooltip title="Agregar Turno Extra">
                                      <IconButton
                                        color={"secondary"}
                                        disabled={
                                          docuCliente === "" ||
                                          itemTurnoExtra?.turId
                                        }
                                        size="small"
                                        onClick={async () => {
                                          try {
                                            const res = await clienteAxios.get(
                                              `/clientes/filtro/0?search=${docuCliente}&estado=CLIENTES`
                                            );

                                            if (!res.data.data[0]) {
                                              return mostrarAlerta(
                                                "Esta persona no tiene cliente",
                                                "error"
                                              );
                                            }
                                            const itemF = res.data.data[0];
                                            changeObjetoActivoStateTurnoExtra({
                                              ...ObjectoActivoTurnoExtra,
                                              turIdCliente: itemF.cliId,
                                              turDocuCliente:
                                                itemF.cliDocNumero,
                                              turNomCliente:
                                                itemF.cliRazonSocial,
                                              turPuesto: [
                                                {
                                                  ubicacion: "",
                                                  puesto: "",
                                                  supervisor: "",
                                                  guardias: "",
                                                  horasservicio: "",
                                                  tipoinstalacion: "",
                                                  vulnerabilidades: "",
                                                  consignas: [],
                                                  id: "",
                                                },
                                              ],
                                            });

                                            setopenTurnoExtra(true);
                                          } catch (error) {
                                            return mostrarAlerta(
                                              "Hubo un error",
                                              "error"
                                            );
                                          }
                                        }}
                                      >
                                        <PersonAdd sx={{ mr: "2rem" }} />
                                      </IconButton>
                                    </Tooltip>
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      disabled={editar}
                      type={"date"}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        min: FechaExacta,
                      }}
                      className={clases.formD}
                      size="small"
                      id="nomFecha"
                      label="Fecha"
                      value={nomFecha}
                      name="nomFecha"
                      error={error.nomFecha}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      disabled={editar}
                      multiline
                      rows={3}
                      variant="outlined"
                      className={clases.formAllW}
                      size="small"
                      id="nomObservacion"
                      label="Observación"
                      value={nomObservacion}
                      name="nomObservacion"
                      error={error.nomObservacion}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : (
                  false
                )}
                {Archivos ? (
                  <>
                    <Dropzone
                      imagenes={imagenes}
                      setimagenes={(e) => {
                        setmodificoImagenes(true);
                        setimagenes(e);
                      }}
                      fotosItem={nomFotos}
                    />
                  </>
                ) : (
                  false
                )}
                {Apelacion ? (
                  <>
                    <Box
                      className={clases.formAllW}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={2}
                    >
                      <Divider style={{ width: "42%" }} />
                      <Typography
                        style={{ width: "100%" }}
                        variant="button"
                        color="primary"
                        align="center"
                        component={"span"}
                      >
                        Verificación
                      </Typography>
                      <Divider style={{ width: "42%" }} />
                    </Box>
                    <TextField
                      disabled={
                        ObjectoActivo.nomEstado === "ASIGNADA" &&
                        !usuario.rol.includes("ADMIN")
                      }
                      className={clases.formD}
                      size="small"
                      id="nomEstado"
                      label="Estado"
                      value={nomEstado}
                      name="nomEstado"
                      error={error.nomEstado}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem disabled value="">
                        Seleccione.
                      </MenuItem>
                      {usuario.rol.includes("ADMIN")
                        ? [
                            "APELACION",
                            "ASIGNADA",
                            "ANULADA",
                            "EN PROCESO",
                          ].map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })
                        : ObjectoActivo.nomEstado === "APELACION"
                        ? ["APELACION", "ASIGNADA", "ANULADA"].map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })
                        : ObjectoActivo.nomEstado === "ASIGNADA"
                        ? ["ASIGNADA"].map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })
                        : ObjectoActivo.nomEstado === "ANULADA"
                        ? ["ANULADA"].map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })
                        : [
                            "APELACION",
                            "ASIGNADA",
                            "ANULADA",
                            "EN PROCESO",
                          ].map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })}
                    </TextField>
                    {editar && (
                      <TextField
                        className={clases.formD}
                        size="small"
                        id="nomMostrarEnInforme"
                        label="Presentar en GOP"
                        value={nomMostrarEnInforme}
                        name="nomMostrarEnInforme"
                        error={error.nomMostrarEnInforme}
                        onChange={(e) => handleChange(e)}
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                      >
                        <MenuItem disabled value="">
                          Seleccione.
                        </MenuItem>
                        {["SI", "NO"].map((item) => {
                          return <MenuItem value={item}>{item} </MenuItem>;
                        })}
                      </TextField>
                    )}
                    <TextField
                      disabled={ObjectoActivo.nomEstado === "ASIGNADA"}
                      multiline
                      rows={3}
                      variant="outlined"
                      className={clases.formAllW}
                      size="small"
                      id="nomApelacionTextoAceptada"
                      label="Status"
                      value={nomApelacionTextoAceptada}
                      name="nomApelacionTextoAceptada"
                      error={error.nomApelacionTextoAceptada}
                      onChange={(e) => handleChange(e)}
                    />
                    <Box
                      width={"100%"}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={2}
                    >
                      <Divider style={{ width: "35%" }} />
                      <Typography
                        style={{ width: "100%" }}
                        variant="button"
                        color="primary"
                        align="center"
                        component={"span"}
                      >
                        Apelación Perjudicado
                      </Typography>
                      <Divider style={{ width: "35%" }} />
                    </Box>
                    <TextField
                      disabled
                      className={clases.formD}
                      size="small"
                      id="nomApelacionTexto"
                      label="Texto"
                      value={nomApelacionTexto}
                      name="nomApelacionTexto"
                      error={error.nomApelacionTexto}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      disabled
                      type={"date"}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        min: FechaExacta,
                      }}
                      className={clases.formD}
                      size="small"
                      id="nomApelacionFecha"
                      label="Fecha"
                      value={
                        nomApelacionFecha ? nomApelacionFecha.substr(0, 10) : ""
                      }
                      name="nomApelacionFecha"
                      error={error.nomApelacionFecha}
                      onChange={(e) => handleChange(e)}
                    />
                    {/* <TextField
                    disabled
                     
                    className={clases.formAllW}
                    size="small"
                    id="collapseFotos"
                    label="Fotos"
                    value={
                      nomApelacionFotos.length === 0
                        ? "NO HAY FOTOS"
                        : "Fotos: " + nomApelacionFotos.length
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton
                            onClick={() => {
                              setcollapseFotos(!collapseFotos);
                            }}
                          >
                            <Image
                              color={collapseFotos ? "primary" : "inherit"}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  /> */}
                    <TextField
                      disabled
                      multiline
                      rows={3}
                      variant="outlined"
                      className={clases.formAllW}
                      size="small"
                      id="nomAnulacion"
                      label="Anulación"
                      value={nomAnulacion}
                      name="nomAnulacion"
                      error={error.nomAnulacion}
                      onChange={(e) => handleChange(e)}
                    />
                    <Box className={clases.formD}>
                      {/* <Collapse in={collapseFotos} timeout="auto" unmountOnExit> */}
                      {nomApelacionFotos.length === 0 ? null : (
                        <ImageList cols={2} sx={{ width: 225, height: 150 }}>
                          {nomApelacionFotos.map((item) => {
                            return (
                              <>
                                <ImageListItem key={item.url}>
                                  <img
                                    onClick={() => {
                                      setOpenimagen(item.url);
                                    }}
                                    src={`${item.url}`}
                                    alt={item.nombre}
                                    loading="lazy"
                                  />
                                </ImageListItem>
                              </>
                            );
                          })}
                        </ImageList>
                      )}
                      {/* </Collapse> */}
                    </Box>
                  </>
                ) : null}
                {Anular ? <></> : null}
              </Box>
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    disableElevation
                    color="primary"
                    disabled={cargando || ObjectoActivo.nomEstado === "ANULADA"}
                    onClick={() => {
                      if (nomEstado === "ASIGNADA" || nomEstado === "ANULADA") {
                        setopenConfirmDialog(true);
                      } else {
                        handleGuardar();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
