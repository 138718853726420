import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";

import AuthContext from "../../../../../Context/Auth/AuthContext";

// MATERIAL
import { MenuItem, TableCell, TableRow, TextField } from "@mui/material";
import { Cancel, Check, Create, Delete } from "@mui/icons-material";
import ConfirmacionDialog from "../../../../../components/Extra/ConfirmacionDialog";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import { usePermiso } from "../../../../hooks/usePermiso";
import DocumentoCelda from "../../../../components/DocumentoCelda";
import ButtonsAction from "../../../../components/ButtonsAction";
import { trimPropsItem } from "../../../../../config/const";
import CampoDoc from "../../../../components/CampoDoc";
const RowAgregar = (props) => {
  const classes = useRowStyles();
  const { itemHijo, itemPadre, index, socket } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  }`;
  const { usuario } = useContext(AuthContext);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [itemEdit, setItemEdit] = useState({ ...itemHijo });
  const [editar, seteditar] = useState(false);
  const [cargando, setcargando] = useState(false);
  const { tienePermiso, alertaPermiso } = usePermiso("EstadoCuentas");
  const botonDisabled = () => {
    if (
      itemEdit.detalle === "" ||
      itemEdit.fecha === "" ||
      itemEdit.cuota === "" ||
      itemEdit.estado === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const botones = [
    {
      tooltip: "Eliminar",
      texto: "",
      funcion: () => {
        if (!tienePermiso("editar")) {
          return alertaPermiso("editar");
        }

        setopenConfirmDialog(true);
      },
      disabled: false,
      Icon: Delete,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Editar",
      texto: "",
      funcion: () => {
        if (!tienePermiso("editar")) {
          return alertaPermiso("editar");
        }

        seteditar(true);
      },
      disabled: false,
      Icon: Create,
      color: "secondary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
  ];
  const botonesEditar = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        seteditar(false);
        setItemEdit(itemHijo);
      },
      disabled: false,
      Icon: Cancel,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar Edición",
      texto: "",
      funcion: () => {
        funcSubmit();
      },
      disabled: botonDisabled() || cargando,
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  const funcSubmit = () => {
    console.log({
      ...itemPadre,
      tabla: "estadocuenta",
      estCuotas: itemPadre.estCuotas.map((item, itemIndex) => {
        if (index === itemIndex) {
          return trimPropsItem({ ...itemEdit });
        } else {
          return item;
        }
      }),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      planUser: usuario.usuario,
      planEmpresa: usuario.rucempresa,
    });

    socket.current.emit("client:actualizarData", {
      ...itemPadre,
      tabla: "estadocuenta",
      estCuotas: itemPadre.estCuotas.map((item, itemIndex) => {
        if (index === itemIndex) {
          return trimPropsItem({ ...itemEdit });
        } else {
          return item;
        }
      }),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      planUser: usuario.usuario,
      planEmpresa: usuario.rucempresa,
    });

    seteditar(false);
  };
  const handleChange = (e) => {
    setItemEdit({
      ...itemEdit,
      [e.target.name]: e.target.value,
    });
  };

  const handleEliminar = async (_id) => {
    socket.current.emit("client:actualizarData", {
      ...itemPadre,
      tabla: "estadocuenta",
      estCuotas: itemPadre.estCuotas.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      planUser: usuario.usuario,
      planEmpresa: usuario.rucempresa,
    });
  };
  useEffect(() => {
    setItemEdit({ ...itemHijo });
    // eslint-disable-next-line
  }, [itemPadre]);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este Item?"
        _id={index}
        funcion={handleEliminar}
      />
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ButtonsAction
          claseCelda={claseCelda}
          row={itemHijo}
          botones={editar ? botonesEditar : botones}
        />
        {/* las demas columnas */}
        {editar ? (
          <>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <CampoDoc
                claseCelda={claseCelda}
                setcargando={setcargando}
                propiedad={"documento"}
                item={itemEdit}
                setitem={setItemEdit}
                celda={true}
                disabled={cargando}
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                multiline
                rows={1}
                autoFocus
                value={itemEdit.detalle}
                name="detalle"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                type="date"
                value={itemEdit.fecha}
                name="fecha"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                type="number"
                value={itemEdit.cuota}
                name="cuota"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                value={itemEdit.estado}
                name="estado"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione.</MenuItem>
                {["PENDIENTE", "CANCELADO"].map((item) => {
                  return <MenuItem value={item}>{item} </MenuItem>;
                })}
              </TextField>
            </TableCell>
            {itemEdit.estado === "CANCELADO" ? (
              <>
                <TableCell
                  fontSize="small"
                  padding="none"
                  align="center"
                  className={classes.celda}
                >
                  <TextField
                    type="date"
                    value={itemEdit.fechaCancelacion}
                    name="fechaCancelacion"
                    onChange={(e) => handleChange(e)}
                    size="small"
                    fullWidth
                  />
                </TableCell>
                <TableCell
                  fontSize="small"
                  padding="none"
                  align="center"
                  className={classes.celda}
                >
                  <TextField
                    multiline
                    maxRows={3}
                    value={itemEdit.observacion}
                    name="observacion"
                    onChange={(e) => handleChange(e)}
                    size="small"
                    fullWidth
                  />
                </TableCell>
              </>
            ) : (
              <>
                <TableCell
                  fontSize="small"
                  padding="none"
                  align="center"
                  className={classes.celda}
                ></TableCell>
                <TableCell
                  fontSize="small"
                  padding="none"
                  align="center"
                  className={classes.celda}
                ></TableCell>
              </>
            )}{" "}
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={itemEdit.pagoCliente}
                name="pagoCliente"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              fontSize="small"
              align="center"
              className={classes.celda}
            >
              <CampoDoc
                setcargando={setcargando}
                propiedad={"documento2"}
                item={itemEdit}
                setitem={setItemEdit}
                celda={true}
                disabled={cargando}
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {itemHijo.user}
            </TableCell>
          </>
        ) : (
          <>
            <DocumentoCelda
              propiedad={itemHijo.documento}
              claseCelda={claseCelda}
            />
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {itemHijo.detalle}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {itemHijo.fecha}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {itemHijo.cuota}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {itemHijo.estado}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {itemHijo.estado === "CANCELADO" && itemHijo.fechaCancelacion}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {itemHijo.estado === "CANCELADO" && itemHijo.observacion}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {itemHijo.pagoCliente}
            </TableCell>
            <DocumentoCelda
              propiedad={itemHijo.documento2}
              claseCelda={claseCelda}
            />
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {itemHijo.user}
            </TableCell>
          </>
        )}
        {/* {editar ? (
          <>
            <TableCell
              fontSize="small"
              sx={{ paddingLeft: "5px" }}
               
              align="center"
              className={claseCelda}
            >
              <TextField
                value={itemEdit.nombre}
                name="nombre"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
    
          </>
        )} */}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
