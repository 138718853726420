import {
  Box,
  Collapse,
  Divider,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  Popper,
  ClickAwayListener,
  Paper,
  Fade,
  MenuList,
  MenuItem,
  Typography,
  IconButton,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import TablaCabecera from "../../../components/TablaCabecera";
import clienteAxios from "../../../../config/axios";
import Cargando from "../../../components/Cargando";
import { useRowStyles } from "../../../styles/stylesRows";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import MenuContext from "../../../../Context/Menu/MenuContext";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { AddCircle } from "@mui/icons-material";

export const Info = withRouter((props) => {
  const {
    row,
    expandir,
    setexpandir,
    anchorEl,
    //*MODALES
    setestadoRequestAnticipo,
    setopenAnticipo,
    changeObjetoActivoStateAnticipo,
    setestadoRequestMulta,
    setopenMulta,
    changeObjetoActivoStateMulta,
    setestadoRequestAusencia,
    setopenAusencia,
    changeObjetoActivoStateAusencia,
    setestadoRequestTurnoExtra,
    setopenTurnoExtra,
    changeObjetoActivoStateTurnoExtra,
  } = props;
  const [data, setdata] = useState(null);
  const { mostrarAlerta } = useContext(AlertaContext);
  const { getPathToredirect } = useContext(MenuContext);
  const getData = async () => {
    try {
      console.log(row);

      const res = await clienteAxios.get(
        `horarios_y_turnos/datos_del_mes_por_persona/${row.perId}`
      );
      console.log({ res });
      setdata(res.data);
    } catch (error) {
      console.log({ error });

      mostrarAlerta("Hubo un error al consultar la información", "error");
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const clases = useRowStyles();
  //   const claseCelda = `${
  //     index % 2 === 0 ? clases.celda : clases.celdaSecundario
  //   }`;
  //   <Button
  //             color="inherit"
  //             aria-describedby={id}
  //             type="button"
  //             onClick={handleClick}
  //             sx={{ fontSize: ".8rem", p: 0.25 }}
  //           >
  //             <Avatar
  //               alt={usuario.nombres}
  //               style={{ marginRight: ".1rem", marginLeft: ".1rem" }}
  //               src={usuario.foto}
  //             />

  //             {usuario.nombre}
  //           </Button>

  return (
    <Popper
      sx={{ zIndex: "3500" }}
      open={expandir}
      anchorEl={anchorEl}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            <ClickAwayListener onClickAway={() => setexpandir(false)}>
              {data ? (
                <MenuList role="menu" color="primary" sx={{ width: "15rem" }}>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <MenuItem
                      sx={{ width: "100%" }}
                      onClick={() => {
                        if (!data.t_anticipos_mes) {
                          return;
                        }
                        const path = getPathToredirect("Anticipos");
                        props.history.push(
                          `${path}?tab=Anticipos&perId=${row.perId}`
                        );
                      }}
                    >
                      <Typography sx={{ mr: 0.5 }} fontWeight={"bold"}>
                        Anticipos:
                      </Typography>{" "}
                      {data.t_anticipos_mes}
                    </MenuItem>
                    <IconButton
                      color="primary"
                      aria-label=""
                      onClick={() => {
                        setexpandir(false);
                        setestadoRequestAnticipo("Anticipos");
                        setopenAnticipo(true);
                        changeObjetoActivoStateAnticipo((prev) => ({
                          ...prev,

                          antIdPersona: row.perId,
                          antDocuPersona: row.perDocNumero,
                          antNomPersona:
                            row.perApellidos + " " + row.perNombres,
                          //*ANTICIPOS
                          // antIdPersona: "",
                          // antDocuPersona: "",
                          // antNomPersona: "",
                        }));
                      }}
                    >
                      <AddCircle />
                    </IconButton>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <MenuItem
                      sx={{ width: "100%" }}
                      onClick={() => {
                        if (!data.t_descuentos_mes) {
                          return;
                        }
                        const path = getPathToredirect("Anticipos");
                        props.history.push(
                          `${path}?tab=Descuentos&perId=${row.perId}`
                        );
                      }}
                    >
                      <Typography sx={{ mr: 0.5 }} fontWeight={"bold"}>
                        Descuentos:
                      </Typography>{" "}
                      {data.t_descuentos_mes}
                    </MenuItem>
                    <IconButton
                      color="primary"
                      aria-label=""
                      onClick={() => {
                        setexpandir(false);
                        setestadoRequestAnticipo("Descuentos");
                        setopenAnticipo(true);
                        changeObjetoActivoStateAnticipo((prev) => ({
                          ...prev,

                          antIdPersona: row.perId,
                          antDocuPersona: row.perDocNumero,
                          antNomPersona:
                            row.perApellidos + " " + row.perNombres,
                          //*ANTICIPOS
                          // antIdPersona: "",
                          // antDocuPersona: "",
                          // antNomPersona: "",
                        }));
                      }}
                    >
                      <AddCircle />
                    </IconButton>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <MenuItem
                      sx={{ width: "100%" }}
                      onClick={() => {
                        if (!data.t_multas_mes) {
                          return;
                        }
                        const path = getPathToredirect("NominaNovedades");
                        props.history.push(`${path}?perId=${row.perId}`);
                      }}
                    >
                      <Typography sx={{ mr: 0.5 }} fontWeight={"bold"}>
                        Multas:
                      </Typography>{" "}
                      {data.t_multas_mes}
                    </MenuItem>
                    <IconButton
                      color="primary"
                      aria-label=""
                      onClick={() => {
                        setexpandir(false);
                        // setestadoRequestMulta,
                        setopenMulta(true);
                        changeObjetoActivoStateMulta((prev) => ({
                          ...prev,
                          nomIdPer: row.perId,
                          nomDocuPer: row.perDocNumero,
                          nomNombrePer: row.perApellidos + " " + row.perNombres,
                          //*MULTA
                          // nomIdPer: itemF.perId,
                          // nomDocuPer: itemF.perDocNumero,
                          // nomNombrePer:
                          //   itemF.perApellidos + " " + itemF.perNombres,
                          // nomPuesto: itemF.perPuestoServicio,
                          // nomCiudad: itemF.perLugarTrabajo,
                        }));
                      }}
                    >
                      <AddCircle />
                    </IconButton>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <MenuItem
                      sx={{ width: "100%" }}
                      onClick={() => {
                        if (!data.t_permisos_mes) {
                          return;
                        }
                        const path = getPathToredirect("Ausencia");
                        props.history.push(`${path}?perId=${row.perId}`);
                      }}
                    >
                      <Typography sx={{ mr: 0.5 }} fontWeight={"bold"}>
                        Permisos:
                      </Typography>{" "}
                      {data.t_permisos_mes}
                    </MenuItem>
                    <IconButton
                      color="primary"
                      aria-label=""
                      onClick={() => {
                        setexpandir(false);
                        // setestadoRequestAusencia,
                        setopenAusencia(true);
                        changeObjetoActivoStateAusencia((prev) => ({
                          ...prev,
                          ausDocuPersona: row.perDocNumero,
                          ausNomPersona:
                            row.perApellidos + " " + row.perNombres,
                          ausIdPersona: row.perId,
                          ausPerIdCliente: row.perIdCliente,
                          ausPerDocuCliente: row.perDocuCliente,
                          ausPerNombreCliente: row.perNombreCliente,
                        }));
                      }}
                    >
                      <AddCircle />
                    </IconButton>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <MenuItem
                      sx={{ width: "100%" }}
                      onClick={() => {
                        if (!data.t_turnosextras_mes) {
                          return;
                        }
                        const path = getPathToredirect("TurnoExtra");
                        props.history.push(`${path}?perId=${row.perId}`);
                      }}
                    >
                      <Typography sx={{ mr: 0.5 }} fontWeight={"bold"}>
                        Turnos Extras:
                      </Typography>{" "}
                      {data.t_turnosextras_mes}
                    </MenuItem>
                    <IconButton
                      color="primary"
                      aria-label=""
                      onClick={() => {
                        setexpandir(false);
                        // setestadoRequestTurnoExtra,
                        setopenTurnoExtra(true);
                        changeObjetoActivoStateTurnoExtra((prev) => ({
                          ...prev,
                          turDocuPersona: row.perDocNumero,
                          turNomPersona:
                            row.perApellidos + " " + row.perNombres,
                          turIdPersona: row.perId,
                        }));
                      }}
                    >
                      <AddCircle />
                    </IconButton>
                  </Box>
                </MenuList>
              ) : (
                <Cargando />
              )}
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
    // <Box margin={1}>
    //   <Divider textAlign="left"> Información </Divider>
    //   {/* <ToolBarTable
    //         ocultarPaginacion={true}
    //         botonesIzquierdos={[
    //           {
    //             tooltip: "Agregar",
    //             texto: "",
    //             funcion: () => {},
    //             disabled: false,
    //             Icon: AddCircle,
    //             color: "primary",
    //             id: 1,
    //             ocultar: false,
    //             tipo: "icono",
    //             variante: "text",
    //             size: "medium",
    //             sx: {},
    //           },
    //         ]}
    //         botonesDerechos={[]}
    //       /> */}
    //   {data ? (
    //     <TableContainer>
    //       <Table size="small" aria-label="purchases">
    //         <TablaCabecera
    //           ocultarPrimeraColumna
    //           columns={[
    //             {
    //               id: "-",
    //               label: "Anticipos",
    //               minWidth: 25,
    //               propiedad: "-",
    //             },
    //             {
    //               id: "-",
    //               label: "Descuentos",
    //               minWidth: 25,
    //               propiedad: "-",
    //             },
    //             {
    //               id: "-",
    //               label: "Multas",
    //               minWidth: 25,
    //               propiedad: "-",
    //             },
    //             {
    //               id: "-",
    //               label: "Permisos",
    //               minWidth: 25,
    //               propiedad: "-",
    //             },
    //             {
    //               id: "-",
    //               label: "Turnos Extras",
    //               minWidth: 25,
    //               propiedad: "-",
    //             },
    //           ]}
    //         />
    //         <TableBody component={"div"}>
    //           <TableRow>
    //             {/* <TableCell size="small" align="center" className={clases.celda}>
    //               Anticipos
    //             </TableCell> */}
    //             <TableCell size="small" align="center" className={clases.celda}>
    //               {data.t_anticipos_mes}
    //             </TableCell>   <TableCell
    //               size="small"
    //               align="center"
    //               className={clases.celda}
    //             >
    //               {data.t_descuentos_mes}
    //             </TableCell>   <TableCell size="small" align="center" className={clases.celda}>
    //               {data.t_multas_mes}
    //             </TableCell>     <TableCell
    //               size="small"
    //               align="center"
    //               className={clases.celda}
    //             >
    //               {data.t_permisos_mes}
    //             </TableCell>       <TableCell size="small" align="center" className={clases.celda}>
    //               {data.t_turnosextras_mes}
    //             </TableCell>
    //           </TableRow>

    //         </TableBody>
    //       </Table>
    //     </TableContainer>
    //   ) : (
    //     <Cargando />
    //   )}
    // </Box>
  );
});
