import React, { memo, useState, useContext, useEffect, useRef } from "react";

import { Box, Button, Divider } from "@mui/material";

import Tabla from "./Componentes/Tabla";
import BarraTablas from "../components/BarraTablas";
import clienteAxios from "../../config/axios";
import AlertaContext from "../../Context/Alerta/AlertaContext";
import AuthContext from "../../Context/Auth/AuthContext";
import Modal from "./Componentes/Modal";
import io from "socket.io-client";
import { socketUrl } from "../../config/const";
import ModalB from "../components/ModalB";
import DialogOpciones from "../components/DialogOpciones";
import { Add, Search } from "@mui/icons-material";
import { usePermiso } from "../hooks/usePermiso";
import {
  columnsImplementarios,
  columnsArmas,
  columnsMuniciones,
  objectDefault,
} from "./data";
import { useCrud } from "../hooks/useCrud";
const Productos = (props) => {
  // DATA DEL STATE
  // REGISTROS O FILAS
  const [rows, setrows] = useState(null);

  // FUNCION QUE CREA EL OBJETO BUSCADOR
  const defaultFullBuscador = () => {
    const json = {};
    columns.forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (itemPropiedad.tipo === "numero") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "fecha") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });
    return json;
  };
  // OBJETO ACTIVO O PLANTILLA
  const [ObjectoActivo, setObjetoActivo] = useState(objectDefault);
  // PAGINACION
  const [pagination, setpagination] = useState(null);
  // FUNCIONES STATE
  // FUNCION QUE OBTIENE LA DATA DE LA TABLA
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    tipo,
    subEstado
  ) => {
    try {
      console.log({
        cantidad,
        page,
        search,
        input,
        orden,
        datos: JSON.parse(datos),
        tipo,
        condicion: subEstado,
      });

      const res = await clienteAxios.post(`/productos/lisByPagination`, {
        cantidad,
        page,
        search,
        input,
        orden,
        datos: JSON.parse(datos),
        tipo,
        condicion: subEstado,
      });
      return res;
    } catch (error) {
      mostrarAlerta("Hubo un error");
    }
  };
  // FUNCION QUE COLOCA LA DATA EN EL STATE
  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    tipo,
    subEstado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      tipo,
      subEstado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  // AGREGA MAS REGISTROS AL STATE
  const addRowsState = async (items) => {
    setrows([...rows, ...items]);
  };
  // REMPLAZA LA CONSULTA ACTUAL POR UNA NUEVA CON OTROS PARAMETROS
  const remplazarRowsState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    tipo,
    subEstado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      tipo,
      subEstado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };

  // CAMBIA EL OBJETO ACTIVO DEL STATE GENERALMENTE PARA EDITAR
  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
  };

  // FIN FUNCIONES STATE
  // FIN DATA STATE

  // FUNCIONES SOCKET
  // NOMBRE DE LA TABLA
  const [tabla] = useState("productos");
  // ITEM DEL SOCKET SE UTILIZA COMO REFERENCIA
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });
  // REFERENCIA DEL SOCKET
  const socket = useRef();
  // USEFFECT QUE ESCUCHUA LOS EVENTOS DEL SOCKET
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  // CUANDO CAMBIA EL OBJETO DEL SOCKET SE ESCUCHA AQUI Y SE DECIDE QUE HACER CON EL
  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }
    if (!rows) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== tabla) {
      return;
    }

    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      agregarState(item);
    }
    if (tipo === "editar") {
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item[campoExport]);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);
  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    usuario: { rucempresa },
    usuario,
  } = useContext(AuthContext);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Productos");
  const [estadoRequest, setestadoRequest] = useState("VESTIMENTAS");
  const [subEstado, setSubEstado] = useState("NUEVO");
  // COLUMNAS
  const [columns, setcolumns] = useState(columnsImplementarios);
  useEffect(() => {
    if (estadoRequest === "VESTIMENTAS" || estadoRequest === "EQUIPOS") {
      setcolumns(columnsImplementarios);
    } else if (estadoRequest === "ARMAS") {
      setcolumns(columnsArmas);
    } else if (estadoRequest === "MUNICIONES") {
      setcolumns(columnsMuniciones);
    }
  }, [estadoRequest]);

  // ABRIR MODAL
  const [open, setopen] = useState(false);
  // BOOLEANO DE SI SE ESTA BUSCANDO O NO
  const [buscando, setbuscando] = useState(false);
  // SI SE ESTA EDITANDO O NO
  const [editar, seteditar] = useState(false);
  // STRING DE BUSQUEDA
  const [search, setsearch] = useState("");
  // BOOLEANO DE SI ESTA CARGANDO
  const [cargando, setcargando] = useState(false);
  // CAMPO POR EL CUAL SE FILTRAN LOS REGISTROS PARA EXPORTAR
  const [campoExport] = useState("prodId");
  // HOOK DEL CRUD
  const {
    agregarState,
    editarState,
    eliminarState,
    eliminarSeleccionState,
  } = useCrud(rows, setrows, campoExport, "prodUser", usuario);
  // ORDEN DE POR DEFECTO
  const [input, setinput] = useState({
    id: campoExport,
    label: "ID",
    minWidth: 150,
    tipo: "string",
    propiedad: campoExport,
  });
  // ARRAY DE EXPORTACION
  const [arrayExport, setarrayExport] = useState([]);
  // BOLLEANO DE SI ESTA ABIERTO EL DIALOG DE OPCIONES
  const [openDialogOpciones, setopenDialogOpciones] = useState(false);
  // ORDEN ASCENDENTE O DESCENDENTE
  const [orden, setorden] = useState(false);
  // CAMPOS DE BUSQUEDA EXPANDIDOS
  const [openMB, setopenMB] = useState(false);
  // OBJETO BUSCADOR
  const [fullBuscador, setfullBuscador] = useState(defaultFullBuscador());

  //pagina actual
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // EVENTO QUE CAMBIA LA DATA DEL OBJETO BUSCADOR
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  // FUNCION DE BUSQUEDA AVANZADA
  const funcionB = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      subEstado
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };
  // FUNCION DE BUSQUEDA RAPIDA
  const handleSearch = async () => {
    setarrayExport([]);
    setcargando(true);
    setbuscando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      subEstado
    );

    setPage(0);
    setcargando(false);
  };
  // FUNCION QUE PONE LA DATA DE POR DEFECTO O HACE UN REFRESH
  const funcionDefault = async () => {
    setcargando(true);
    const fullBRes = defaultFullBuscador();
    setfullBuscador(fullBRes);
    await remplazarRowsState(
      rowsPerPage,
      0,
      "",
      input.id,
      !orden,
      JSON.stringify(fullBRes),
      rucempresa,
      estadoRequest,
      subEstado
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };

  const handleOpen = () => {
    // const objetoVestimenta = {
    //   //   prodId: 1,
    //   prodObservacion: "",
    //   prodMaterial: "",
    //   prodPrecioUnitario: "1",
    //   prodTipo: "VESTIMENTAS", // DE ACUERDO AL TAB
    //   prodSerie: "0", // text
    //   prodNombre: "", // TEXTAREA
    //   prodProductoEstado: "NUEVO", // SELECT => NUEVO,USADO
    //   prodMarca: "",
    //   prodModelo: "",
    //   prodStock: "",
    //   prodDisponible: "",
    //   prodTalla: "40.00", // SELECT => 36,38,40,42,44,46
    //   prodClase: "", // NO
    //   prodTipoArma: "", // NO
    //   prodCalibre: "", // NO
    //   prodColor: "", // NO
    //   prodUso: "", // NO
    //   prodStatus: "", // NO
    //   prodProveedor: [
    //     // TAB (PROVEEDOR) => TABLA
    //     // {
    //     //   id: "12",
    //     //   numDocumento: "0302626288001",
    //     //   nombres: "Hugo",
    //     //   numFactura: "32-32-3-32323",
    //     //   cantidad: "10",
    //     //   fecFactura: "2022-03-02",
    //     // },
    //   ],
    //   prodFotos: [],
    //   prodBodega: [],
    //   prodBodId: "",
    //   prodNomBodega: "",
    //   prodComprobantePdf: "", // TAB (DOCUMENTOS)
    //   prodPermisoPdf: "", // NO
    //   prodFecValidacion: "", // NO
    //   prodFecCaducidad: "", // DATE
    //   prodEstado: "ACTIVA", // SWITCH
    //   prodEmpresa: "",
    //   prodUser: "",
    //   prodFecReg: "",
    //   prodDocumento: "",
    //   prodTotalesStock: [
    //     {
    //       estado: "NUEVO",
    //       cantidad: 0,
    //     },
    //     {
    //       estado: "USADO",
    //       cantidad: 0,
    //     },
    //     {
    //       estado: "OPERABLE",
    //       cantidad: 0,
    //     },
    //     {
    //       estado: "DEFECTUOSO",
    //       cantidad: 0,
    //     },
    //   ],
    // };
    // const objetoEQUIPOS = {
    //   //   prodId: 1,
    //   prodObservacion: "",
    //   prodMaterial: "",
    //   prodPrecioUnitario: "1",
    //   prodTipo: "EQUIPOS", // DE ACUERDO AL TAB
    //   prodSerie: "0", // text
    //   prodNombre: "", // TEXTAREA
    //   prodProductoEstado: "NUEVO", // SELECT => NUEVO,USADO
    //   prodMarca: "",
    //   prodModelo: "",
    //   prodStock: "",
    //   prodDisponible: "",
    //   prodTalla: "40.00", // SELECT => 36,38,40,42,44,46
    //   prodClase: "", // NO
    //   prodTipoArma: "", // NO
    //   prodCalibre: "", // NO
    //   prodColor: "", // NO
    //   prodUso: "", // NO
    //   prodStatus: "", // NO
    //   prodProveedor: [
    //     // TAB (PROVEEDOR) => TABLA
    //     // {
    //     //   id: "12",
    //     //   numDocumento: "0302626288001",
    //     //   nombres: "Hugo",
    //     //   numFactura: "32-32-3-32323",
    //     //   cantidad: "10",
    //     //   fecFactura: "2022-03-02",
    //     // },
    //   ],
    //   prodFotos: [],
    //   prodBodega: [],
    //   prodBodId: "",
    //   prodNomBodega: "",
    //   prodComprobantePdf: "", // TAB (DOCUMENTOS)
    //   prodPermisoPdf: "", // NO
    //   prodFecValidacion: "", // NO
    //   prodFecCaducidad: "", // DATE
    //   prodEstado: "ACTIVA", // SWITCH
    //   prodEmpresa: "",
    //   prodUser: "",
    //   prodFecReg: "",
    //   prodDocumento: "",
    //   prodTotalesStock: [
    //     {
    //       estado: "NUEVO",
    //       cantidad: 0,
    //     },
    //     {
    //       estado: "USADO",
    //       cantidad: 0,
    //     },
    //     {
    //       estado: "OPERABLE",
    //       cantidad: 0,
    //     },
    //     {
    //       estado: "DEFECTUOSO",
    //       cantidad: 0,
    //     },
    //   ],
    // };
    // const objetoArmas = {
    //   prodMaterial: "",
    //   prodPrecioUnitario: "1.00",
    //   prodTipo: "ARMAS", // DE ACUERDO AL TAB
    //   prodSerie: "0",
    //   prodNombre: "",
    //   prodProductoEstado: "NUEVO",
    //   prodMarca: "", // TEXT
    //   prodModelo: "", // TEXT
    //   prodStock: "",
    //   prodDisponible: "",
    //   prodTalla: "", // NO
    //   prodClase: "LETAL", // SELECT => LETAL,NO LETAL
    //   prodTipoArma: "PISTOLA", // SELECT => REVOLVER,PISTOLA,ESCOPETA,FUSIL
    //   prodCalibre: "CAL 22", // SELECT => .38,9MM,.32/CAL 12,CAL 16,CAL 22
    //   prodColor: "", // TEXT
    //   prodUso: "FIJA", // SELECT => FIJA,MOVIL
    //   prodStatus: "APTA", // SELECT => APTA,DESCOMISADA,PERDIDA
    //   prodProveedor: [
    //     // TAB (PROVEEDOR) => TABLA
    //     // {
    //     //   id: "12",
    //     //   numDocumento: "0302626288001",
    //     //   nombres: "Hugo",
    //     //   numFactura: "32-32-3-32323",
    //     //   cantidad: "10",
    //     //   fecFactura: "2022-03-02",
    //     // },
    //   ],
    //   prodBodega: [],
    //   prodBodId: "",
    //   prodNomBodega: "",
    //   prodFotos: [],
    //   prodComprobantePdf: "", // TAB (DOCUMENTOS)
    //   prodPermisoPdf: "", // TAB (DOCUMENTOS)
    //   prodFecValidacion: "", // DATE
    //   prodFecCaducidad: "", // DATE
    //   prodEstado: "ACTIVA", // SWITCH
    //   prodEmpresa: "",
    //   prodUser: "",
    //   prodFecReg: "",
    //   prodDocumento: "",
    //   prodTotalesStock: [
    //     {
    //       estado: "NUEVO",
    //       cantidad: 0,
    //     },
    //     {
    //       estado: "USADO",
    //       cantidad: 0,
    //     },
    //     {
    //       estado: "OPERABLE",
    //       cantidad: 0,
    //     },
    //     {
    //       estado: "DEFECTUOSO",
    //       cantidad: 0,
    //     },
    //   ],
    // };
    // const objetoMuniciones = {
    //   prodTipo: "MUNICIONES",
    //   prodSerie: "0",
    //   prodNombre: "", // disabled => serie, calibre, clase, material
    //   prodPrecioUnitario: "1.00",
    //   prodProductoEstado: "OPERABLE",
    //   prodMarca: "", // imput
    //   prodModelo: "", // interno
    //   prodStock: 0, // interno
    //   prodDisponible: 0, // interno
    //   prodTalla: "", // interno
    //   prodClase: "LETAL", // select => FEOGEO, LETAL
    //   prodMaterial: "PLOMO", // select => PLOMO, ACERO
    //   prodTipoArma: "", // interno
    //   prodCalibre: ".38", // select => .38,9MM, .32/ CAL 12, CAL 16, CAL 22, CAL 20
    //   prodColor: "", // interno
    //   prodUso: "", // interno
    //   prodStatus: "", // interno
    //   prodProveedor: [],
    //   prodBodega: [],
    //   prodBodId: "",
    //   prodNomBodega: "",
    //   prodFotos: [],
    //   prodComprobantePdf: "", // input
    //   prodPermisoPdf: "", // interno
    //   prodFecValidacion: "", // interno
    //   prodFecCaducidad: "", // input
    //   prodEstado: "ACTIVA", // interno
    //   prodEmpresa: "", // interno
    //   prodUser: "", // interno
    //   prodDocumento: "",
    //   prodTotalesStock: [
    //     {
    //       estado: "NUEVO",
    //       cantidad: 0,
    //     },
    //     {
    //       estado: "USADO",
    //       cantidad: 0,
    //     },
    //     {
    //       estado: "OPERABLE",
    //       cantidad: 0,
    //     },
    //     {
    //       estado: "DEFECTUOSO",
    //       cantidad: 0,
    //     },
    //   ],
    // };
    if (estadoRequest === "ARMAS") {
      changeObjetoActivoState({ ...objectDefault, prodTipo: "ARMAS" });
    } else if (estadoRequest === "VESTIMENTAS") {
      changeObjetoActivoState({ ...objectDefault, prodTipo: "VESTIMENTAS" });
    } else if (estadoRequest === "EQUIPOS") {
      changeObjetoActivoState({ ...objectDefault, prodTipo: "EQUIPOS" });
    } else if (estadoRequest === "MUNICIONES") {
      changeObjetoActivoState({ ...objectDefault, prodTipo: "MUNICIONES" });
    }
    setopen(true);
  };

  return (
    <Box>
      <DialogOpciones
        open={openDialogOpciones}
        setopen={setopenDialogOpciones}
        botones={[
          {
            tooltip: "Agregar",
            funcion: () => {
              handleOpen();
            },
            Icon: Add,
            color: "primary",
            id: 1,
            disabled: false,
          },
        ]}
        titulo={"Mas Opciones"}
      />
      <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        LabelBuscador="Buscar: Serie, Nombre"
        funcionDefault={funcionDefault}
        ocultarMigasDePan={false}
        botones={[
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              handleOpen(true);
            },
            disabled: false,
            Icon: Add,
            color: "primary",
            id: 1,
            ocultar: true,
            tipo: "icono",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              if (!tienePermiso("añadir")) {
                return alertaPermiso("añadir");
              }
              handleOpen(true);
            },
            disabled: false,
            Icon: Add,
            color: "primary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
          {
            tooltip: "Busqueda avanzada",
            texto: "",
            funcion: () => {
              setopenMB(true);
            },
            disabled: false,
            Icon: Search,
            color: "success",
            id: 2,
            ocultar: false,
            tipo: "icono",
            variante: "contained",
            size: "medium",
            sx: {
              ml: 1,
            },
          },
        ]}
      />
      <ModalB
        defaultFullBuscador={defaultFullBuscador}
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={`Busqueda avanzada (${estadoRequest} - ${subEstado})`}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        arrayProps={columns}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        funcionDefault={funcionDefault}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems=" stretch"
        width="100%"
      >
        <Box>
          {[
            { estado: "VESTIMENTAS", label: "VESTIMENTAS" },
            { estado: "ARMAS", label: "ARMAS" },
            { estado: "MUNICIONES", label: "MUNICIONES" },
            { estado: "EQUIPOS", label: "EQUIPOS" },
          ].map((itemEstado) => (
            <Button
              key={itemEstado.estado}
              size="small"
              variant={estadoRequest === itemEstado.estado ? "contained" : null}
              onClick={async () => {
                setcargando(true);
                await remplazarRowsState(
                  rowsPerPage,
                  0,
                  "",
                  input.id,
                  orden,
                  JSON.stringify(fullBuscador),
                  rucempresa,
                  itemEstado.estado,
                  subEstado
                );

                setPage(0);
                setestadoRequest(itemEstado.estado);
                setcargando(false);
              }}
              disableElevation
              style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
              color="primary"
            >
              {itemEstado.label}
            </Button>
          ))}
        </Box>
        <Box>
          {/* {["NUEVO", "USADO", "OPERABLE", "BUEN ESTADO", "DEFECTUOSO"].map(
            (itemSubEstado) => (
              <Button
                size="small"
                variant={subEstado === itemSubEstado ? "contained" : null}
                onClick={async () => {
                  setcargando(true);
                  await remplazarRowsState(
                    rowsPerPage,
                    0,
                    "",
                    input.id,
                    orden,
                    JSON.stringify(fullBuscador),
                    rucempresa,
                    estadoRequest,
                    itemSubEstado
                  );
                  setfullBuscador({
                    prodFecReg1: "",
                    prodFecReg2: "",
                  });
                  setPage(0);
                  setSubEstado(itemSubEstado);
                  setcargando(false);
                }}
                disableElevation
                style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
                color="secondary"
              >
                {itemSubEstado}
              </Button>
            )
          )} */}
        </Box>
      </Box>
      <Divider />
      <Tabla
        //PAGINACION Y BUSQUEDA
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setopen={setopen}
        search={search}
        input={input}
        orden={orden}
        setinput={setinput}
        setorden={setorden}
        cargando={cargando}
        setcargando={setcargando}
        fullBuscador={fullBuscador}
        //ESTADO
        rows={rows}
        columns={columns}
        pagination={pagination}
        getDataState={getDataState}
        obtenerState={obtenerState}
        addRowsState={addRowsState}
        remplazarRowsState={remplazarRowsState}
        changeObjetoActivoState={changeObjetoActivoState}
        seteditar={seteditar}
        arrayExport={arrayExport}
        setarrayExport={setarrayExport}
        socket={socket}
        campoExport={campoExport}
        estadoRequest={estadoRequest}
        subEstado={subEstado}
        tabla={tabla}
      />

      <Modal
        editar={editar}
        seteditar={seteditar}
        setopen={setopen}
        open={open}
        ObjectoActivo={ObjectoActivo}
        changeObjetoActivoState={changeObjetoActivoState}
        socket={socket}
        estadoRequest={estadoRequest}
      />
    </Box>
  );
};

export default memo(Productos);
