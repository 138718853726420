export const columns = [
  {
    id: "perId",
    label: "Código",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "perId",
    align: "center",
    ocultar: true,
  },

  {
    id: "perDocNumero",
    label: "Cedula",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "perDocNumero",
    align: "center",
    ocultar: true,
  },

  {
    id: "perApellidos",
    label: "Nombres",
    minWidth: 125,
    tipo: "string",
    orden: 1,
    propiedad: "perApellidos",
    align: "center",
    ocultar: true,
  },

  {
    id: "perFecVinculacion",
    label: "Fec. Vinculación",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "perFecVinculacion",
    align: "center",
    ocultar: true,
  },

  {
    id: "perPuestoServicio",
    label: "Puesto",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "perPuestoServicio",
    align: "center",
    ocultar: true,
  },
  {
    id: "procesos",
    label: "Procesos",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "procesos",
    align: "center",
    ocultar: true,
  },

  {
    id: "perAreaDepartamento",
    label: "Area",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "perAreaDepartamento",
    align: "center",
    ocultar: true,
  },

  {
    id: "perCiudad",
    label: "Ciudad",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "perCiudad",
    align: "center",
    ocultar: true,
  },

  {
    id: "perCtaBancaria",
    label: "Cta. Bancaria",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "perCtaBancaria",
    align: "center",
    ocultar: true,
  },

  {
    id: "perEmail",
    label: "Correo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "perEmail",
    align: "center",
    ocultar: true,
  },

  {
    id: "perTelefono",
    label: "Teléfono",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "perTelefono",
    align: "center",
    ocultar: true,
  },

  {
    id: "perFecReg",
    label: "Fec. Reg",
    minWidth: 100,
    tipo: "fecha",
    orden: 1,
    propiedad: "perFecReg",
    align: "center",
    ocultar: false,
  },

  {
    id: "perUser",
    label: "User",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "perUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  descuentos: [],
  trabaja: 1,
  perCargaFamiliar: "0",
  perDocTipo: "",
  perDocNumero: "",
  perNombres: "",
  perApellidos: "",
  perEstadoCivil: "SOLTERO(a)",
  perTelefono: [],
  perCelular: "",
  otrosDatosLaborales: [],
  perDireccion: "",
  perFecNacimiento: "",
  perNumContrato: "",
  perTipoSangre: "O POSITIVO",
  perPerfil: [],
  perEmail: [],
  perLicencias: ["NINGUNA"],
  perEmpresa: [],
  perCiudad: "",
  perFecVinculacion: "",
  perFormaPago: "EFECTIVO",
  perRemuneracion: "",
  perLugarTrabajo: "",
  perAreaDepartamento: "",
  perPuestoServicio: [],
  perTipoCuenta: "AHORRO",
  perBancoRemuneracion: "",
  perFecRetiro: "",
  perBonos: [],
  perEstado: "ACTIVO",
  perTurno: [],
  perCargo: "ADMINISTRACION",
  perTipoContrato: "TEMPORAL",
  perCtaBancaria: "",
  perPais: "ECUADOR",
  perProvincia: "SANTO DOMINGO DE LOS TSÁCHILAS",
  perCanton: "SANTO DOMINGO",
  perObservacion: "",
  perUser: "",
  perFecReg: "",
  perCv: "",
  //foto
  perFotoCarnet: "",
  perFoto: "",
  perIdCliente: "",
  perDocuCliente: "",
  perNombreCliente: "",
  perGps: {
    longitud: -79.178298,
    latitud: -0.254916,
  },
  //dopc
  documentos: {},
  Todos: "",
  perIdClienteGop: "",
  perDocuClienteGop: "",
  perNombreClienteGop: "",
  perReferencia: "",
  perFotografiasInstalaciones: [],
  genero: "MASCULINO",
  // rucempresa: "PAZVISEG",
  // rol: ["ADMIN"],
  // tabla: "persona",
};
