import React, { memo, useState, useContext, useEffect, useRef } from "react";

import { Box, Divider, IconButton, Modal, Typography } from "@mui/material";

import Tabla from "./Componentes/Tabla";
import BarraTablas from "../components/BarraTablas";
import clienteAxios from "../../config/axios";
import AlertaContext from "../../Context/Alerta/AlertaContext";
import AuthContext from "../../Context/Auth/AuthContext";
import io from "socket.io-client";
import { socketUrl } from "../../config/const";
import DialogOpciones from "../components/DialogOpciones";
import { Add, Cancel, MoreTime, Search } from "@mui/icons-material";
import { usePermiso } from "../hooks/usePermiso";
import { useCrud } from "../hooks/useCrud";
import { columns } from "./data";
import Draggable from "react-draggable";
import { useModalStyle } from "../styles/styleModal";

const ModalHorarios = ({
  openModal,
  setopenModal,
  itemdatoOperativo,
  setitemdatoOperativo,
  funcSubmit,
}) => {
  // DATA DEL STATE
  // REGISTROS O FILAS
  const [rows, setrows] = useState(null);
  // COLUMNAS

  // FUNCION QUE CREA EL OBJETO BUSCADOR
  const defaultFullBuscador = () => {
    const json = {};
    columns.forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (itemPropiedad.tipo === "numero") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "fecha") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });
    return json;
  };

  // PAGINACION
  const [pagination, setpagination] = useState(null);
  // FUNCIONES STATE
  // FUNCION QUE OBTIENE LA DATA DE LA TABLA
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa
  ) => {
    try {
      const res = await clienteAxios.get(
        `/horarios/?cantidad=${cantidad}&page=${page}&search=${
          search ? search : ""
        }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
          datos ? datos : ""
        }&rucempresa=${rucempresa ? rucempresa : ""}`
      );
      return res;
    } catch (error) {
      mostrarAlerta("Hubo un error");
    }
  };
  // FUNCION QUE COLOCA LA DATA EN EL STATE
  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estadoRequest
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estadoRequest
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  // AGREGA MAS REGISTROS AL STATE
  const addRowsState = async (items) => {
    setrows([...rows, ...items]);
  };
  // REMPLAZA LA CONSULTA ACTUAL POR UNA NUEVA CON OTROS PARAMETROS
  const remplazarRowsState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estadoRequest
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estadoRequest
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };

  // FIN FUNCIONES STATE
  // FIN DATA STATE

  // NOMBRE DE LA TABLA
  const [tabla] = useState("horario");

  // ITEM DEL SOCKET SE UTILIZA COMO REFERENCIA
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });
  // REFERENCIA DEL SOCKET
  const socket = useRef();
  // USEFFECT QUE ESCUCHUA LOS EVENTOS DEL SOCKET
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  // CUANDO CAMBIA EL OBJETO DEL SOCKET SE ESCUCHA AQUI Y SE DECIDE QUE HACER CON EL
  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }
    if (!rows) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== tabla) {
      return;
    }

    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      agregarState(item);
    }
    if (tipo === "editar") {
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item[campoExport]);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);
  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    usuario: { rucempresa },
    usuario,
  } = useContext(AuthContext);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Horarios");
  const [estadoRequest] = useState("RIESGO");
  // ABRIR MODAL
  const [open, setopen] = useState(false);
  // BOOLEANO DE SI SE ESTA BUSCANDO O NO
  const [buscando, setbuscando] = useState(false);

  // STRING DE BUSQUEDA
  const [search, setsearch] = useState("");
  // BOOLEANO DE SI ESTA CARGANDO
  const [cargando, setcargando] = useState(false);
  // CAMPO POR EL CUAL SE FILTRAN LOS REGISTROS PARA EXPORTAR
  const [campoExport] = useState("id");
  const {
    agregarState,
    editarState,
    eliminarState,
    eliminarSeleccionState,
  } = useCrud(rows, setrows, campoExport, "user", usuario);
  // ORDEN DE POR DEFECTO
  const [input, setinput] = useState({
    id: campoExport,
    label: "ID",
    minWidth: 150,
    tipo: "string",
    propiedad: campoExport,
  });
  // ARRAY DE EXPORTACION
  const [arrayExport, setarrayExport] = useState(itemdatoOperativo.id_horarios);
  // BOLLEANO DE SI ESTA ABIERTO EL DIALOG DE OPCIONES
  const [openDialogOpciones, setopenDialogOpciones] = useState(false);
  // ORDEN ASCENDENTE O DESCENDENTE
  const [orden, setorden] = useState(false);
  // CAMPOS DE BUSQUEDA EXPANDIDOS
  const [openMB, setopenMB] = useState(false);
  // OBJETO BUSCADOR
  const [fullBuscador, setfullBuscador] = useState(defaultFullBuscador());

  //pagina actual
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // EVENTO QUE CAMBIA LA DATA DEL OBJETO BUSCADOR
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  // FUNCION DE BUSQUEDA AVANZADA
  const funcionB = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };
  // FUNCION DE BUSQUEDA RAPIDA
  const handleSearch = async () => {
    setarrayExport([]);
    setcargando(true);
    setbuscando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );

    setPage(0);
    setcargando(false);
  };
  // FUNCION QUE PONE LA DATA DE POR DEFECTO O HACE UN REFRESH
  const funcionDefault = async () => {
    setcargando(true);
    const fullBRes = defaultFullBuscador();
    setfullBuscador(fullBRes);
    await remplazarRowsState(
      rowsPerPage,
      0,
      "",
      input.id,
      !orden,
      JSON.stringify(fullBRes),
      rucempresa,
      estadoRequest
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };

  const clases = useModalStyle();
  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div
              style={{
                borderRadius: "15px",
                position: "absolute",
                overflow: "scroll",
                maxHeight: "95vh",
              }}
              className={clases.paper}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  Horarios.
                </Typography>

                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      setopenModal(false);
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box>
                <BarraTablas
                  search={search}
                  setsearch={setsearch}
                  funcion={handleSearch}
                  buscando={buscando}
                  setbuscando={setbuscando}
                  LabelBuscador="Buscar por todos los campos"
                  funcionDefault={funcionDefault}
                  ocultarMigasDePan
                  botones={[
                    {
                      tooltip: "Agregar",
                      texto: "Agregar",
                      funcion: () => {
                        setopen(true);
                      },
                      disabled: false,
                      Icon: Add,
                      color: "primary",
                      id: 1,
                      ocultar: true,
                      tipo: "icono",
                      variante: "contained",
                      size: "medium",
                      sx: { marginRight: ".3rem" },
                    },
                    {
                      tooltip: "Agregar",
                      texto: "Agregar",
                      funcion: () => {
                        if (!tienePermiso("añadir")) {
                          return alertaPermiso("añadir");
                        }
                        setopen(true);
                      },
                      disabled: false,
                      Icon: Add,
                      color: "primary",
                      id: 2,
                      ocultar: true,
                      tipo: "boton",
                      variante: "contained",
                      size: "medium",
                      sx: {},
                    },
                    {
                      tooltip: "Agregar Horarios",
                      texto: "Agregar Horarios",
                      funcion: () => {
                        funcSubmit();
                        setopen(true);
                      },
                      disabled: false,
                      Icon: MoreTime,
                      color: "primary",
                      id: 2,
                      ocultar: false,
                      tipo: "boton",
                      variante: "contained",
                      size: "medium",
                      sx: {},
                    },        {
                                tooltip: "Busqueda avanzada",
                                texto: "",
                                funcion: () => {
                                  setopenMB(true);
                                },
                                disabled: false,
                                Icon: Search,
                                color: "success",
                                id: 2,
                                ocultar: false,
                                tipo: "icono",
                                variante: "contained",
                                size: "medium",
                                sx: {
                                  ml: 1,
                                },
                              },
                  ]}
                />

                <DialogOpciones
                  open={openDialogOpciones}
                  setopen={setopenDialogOpciones}
                  botones={[
                    {
                      tooltip: "Agregar",
                      funcion: () => {
                        setopen(true);
                      },
                      Icon: Add,
                      color: "primary",
                      id: 1,
                      disabled: false,
                    },
                  ]}
                  titulo={"Mas Opciones"}
                />

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems=" stretch"
                  width="100%"
                >
                  <Box> </Box>
                </Box>
                <Divider />
                <Tabla
                  //PAGINACION Y BUSQUEDA
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  search={search}
                  input={input}
                  orden={orden}
                  setinput={setinput}
                  setorden={setorden}
                  cargando={cargando}
                  setcargando={setcargando}
                  fullBuscador={fullBuscador}
                  //ESTADO
                  rows={rows}
                  columns={columns}
                  pagination={pagination}
                  getDataState={getDataState}
                  obtenerState={obtenerState}
                  addRowsState={addRowsState}
                  remplazarRowsState={remplazarRowsState}
                  arrayExport={arrayExport}
                  setarrayExport={(e) => {
                    setarrayExport(e);
                    setitemdatoOperativo((prev) => ({
                      ...prev,
                      id_horarios: e,
                    }));
                  }}
                  socket={socket}
                  campoExport={campoExport}
                  estadoRequest={estadoRequest}
                  tabla={tabla}
                  //ROW
                  open={open}
                  setopen={setopen}
                />
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalHorarios);
