import React, { memo, useContext } from "react";

import {
  Autocomplete,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Popper,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  AddCircle,
  Cancel,
  Check,
  Create,
  DeleteForever,
  Restore,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { usePermiso } from "../../../hooks/usePermiso";
import { trimPropsItem } from "../../../../config/const";
import clienteAxios from "../../../../config/axios";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    socket,
    stocksData,
    ocultarAcciones,
    setopenDialogOpcionesProducto,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // EDITAR
  const [editar, seteditar] = useState(false);
  const [productosData, setProductosData] = useState([]);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Stocks");
  // CONTEXT
  const { usuario } = useContext(AuthContext);

  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState({ ...row });
  // COLUMNAS DE LA TABLA HIJA

  //onChange
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (false) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "stocks",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        user: usuario.usuario,
        cod_empresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcSubmit = () => {
    try {
      console.log(
        trimPropsItem({
          ...item,
          tabla: "stocks",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          user: usuario.usuario,
          cod_empresa: usuario.rucempresa,
        })
      );
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "stocks",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          user: usuario.usuario,
          cod_empresa: usuario.rucempresa,
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...row,

          tabla: "stocks",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          user: usuario.usuario + "/+/" + row[campoExport],
          estEmpresa: usuario.rucempresa,
          [campoExport]: row[campoExport],
        })
      );

      seteditar(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVA
  const funcionEdit = () => {
    setitem({ ...row });
    seteditar(true);
  };
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow
        className={classes.root}
        selected={index % 2 === 0}
        component={"form"}
      >
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar(true);
              },
              disabled: usuario.usuario !== row.user.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 1,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled:
                row.apoTipo === "" ||
                row.apoAporte === "" ||
                row.apoValidacion === "true",
              Icon: DeleteForever,
              color: "error",
              id: 2,
              ocultar: editar || row.eliminado || ocultarAcciones,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled:
                row.apoTipo === "" ||
                row.apoAporte === "" ||
                row.apoValidacion === "true",
              Icon: Create,
              color: "secondary",
              id: 3,
              ocultar: editar || row.eliminado || ocultarAcciones,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                seteditar(false);
                setitem({ ...row });
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 4,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar Edición",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 5,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
            // {
            //   tooltip: "Estado",
            //   texto: "",
            //   funcion: (e) => {
            //     setitem({
            //       ...item,
            //       bodeEstado: e.target.checked ? "ACTIVA" : "INACTIVA",
            //     });
            //   },
            //   disabled: !editar,
            //   Icon: "",
            //   color: "primary",
            //   id: 6,
            //   ocultar: row.eliminado,
            //   tipo: "check",
            //   estado: editar
            //     ? item.bodeEstado === "ACTIVA"
            //     : row.bodeEstado === "ACTIVA",
            //   variante: "",
            //   size: "small",
            //   sx: butonIconTransition,
            // },
          ]}
        />

        {editar ? (
          <>
            <>
              <TableCell size="small" align="center" className={claseCelda}>
                <TextField
                  value={item.prodTipo}
                  name="prodTipo"
                  onChange={(e) => {
                    setProductosData([]);
                    setitem({
                      ...item,
                      prodTipo: "",
                      prodNombre: "",
                      prodId: 0,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  size="small"
                  fullWidth
                  select
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem value="">Seleccione.</MenuItem>
                  {["VESTIMENTAS", "ARMAS", "MUNICIONES", "EQUIPOS"].map(
                    (item) => {
                      return <MenuItem value={item}>{item} </MenuItem>;
                    }
                  )}
                </TextField>
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                <FormControl sx={{ width: "100%" }}>
                  <Autocomplete
                    PopperComponent={PopperMy}
                    disableClearable
                    options={productosData.map((item) => {
                      return (
                        item.prodTipo +
                        "/+/" +
                        item.prodNombre +
                        "/+/" +
                        item.prodId
                      );
                    })}
                    getOptionLabel={(option) => {
                      return option.replace("/+/", " ").split("/+/")[0];
                    }}
                    id="controlled-demo"
                    value={item.prodNombre.trim() === "" ? "" : item.prodNombre}
                    onChange={async (event, newValue) => {
                      if (newValue) {
                        const [prodTipo, prodNombre, prodId] = newValue.split(
                          "/+/"
                        );
                        if (!newValue) return;
                        setitem((prev) => ({
                          ...prev,
                          prodTipo,
                          prodNombre,
                          prodId,
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        required
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              <InputAdornment>
                                {params.InputProps.endAdornment}
                                <IconButton
                                  size="small"
                                  aria-label=""
                                  onClick={async () => {
                                    setopenDialogOpcionesProducto(true);
                                  }}
                                >
                                  <AddCircle color="primary" />
                                </IconButton>
                              </InputAdornment>
                            </React.Fragment>
                          ),
                        }}
                        onChange={async (e) => {
                          try {
                            const res = await clienteAxios.get(
                              `productos/getBySearch/serie_tipo_nombre?search=${e.target.value}&tipo=${item.prodTipo}`
                            );
                            console.log({ res });
                            setProductosData(res.data);
                          } catch (error) {}
                        }}
                      />
                    )}
                  />
                </FormControl>
              </TableCell>{" "}
              <TableCell size="small" align="center" className={claseCelda}>
                {item.prodTipo === "VESTIMENTAS" ? (
                  <TextField
                    value={item.talla}
                    name="talla"
                    onChange={(e) =>
                      setitem({
                        ...item,
                        [e.target.name]: e.target.value,
                      })
                    }
                    size="small"
                    fullWidth
                    select
                    SelectProps={{
                      displayEmpty: true,
                    }}
                  >
                    <MenuItem value="">Seleccione.</MenuItem>
                    {[
                      "0",
                      "36",
                      "37",
                      "38",
                      "39",
                      "40",
                      "41",
                      "42",
                      "43",
                      "44",
                      "46",
                      "48",
                      "50",
                      "52",
                    ].map((item) => {
                      return <MenuItem value={item}>{item} </MenuItem>;
                    })}
                  </TextField>
                ) : (
                  "-"
                )}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                <TextField
                  value={item.condicion_producto}
                  name="condicion_producto"
                  onChange={(e) =>
                    setitem({
                      ...item,
                      [e.target.name]: e.target.value,
                    })
                  }
                  size="small"
                  fullWidth
                  select
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  {[
                    "NUEVO",
                    "USADO",
                    "OPERABLE",
                    "BUEN ESTADO",
                    "DEFECTUOSO",
                  ].map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                <FormControl sx={{ width: "100%" }}>
                  <Autocomplete
                    disabled={editar}
                    PopperComponent={PopperMy}
                    options={stocksData.map((item) => {
                      return item.bodeNombre + "/+/" + item.bodeId;
                    })}
                    getOptionLabel={(option) => {
                      return option.replace("/+/", " ").split("/+/")[0];
                    }}
                    id="controlled-demo"
                    value={item.bodeNombre.trim() === "" ? "" : item.bodeNombre}
                    onChange={async (event, newValue) => {
                      if (newValue) {
                        const [bodeNombre, bodeId] = newValue.split("/+/");
                        if (!newValue) return;
                        setitem((prev) => ({
                          ...prev,
                          bodeNombre,
                          stock_bodeId: bodeId,
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" required fullWidth />
                    )}
                  />
                </FormControl>
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                <TextField
                  type="number"
                  value={item.stock_total_por_bodega}
                  name="stock_total_por_bodega"
                  onChange={(e) =>
                    setitem({
                      ...item,
                      [e.target.name]: Math.max(1, e.target.value),
                    })
                  }
                  size="small"
                  fullWidth
                />
              </TableCell>
              {/* <TableCell size="small" align="center" className={claseCelda}>
                <TextField
                  type="number"
                  value={item.maximo}
                  name="maximo"
                  onChange={(e) =>
                    setitem({
                      ...item,
                      [e.target.name]: Math.max(0, e.target.value),
                    })
                  }
                  size="small"
                  fullWidth
                />
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                <TextField
                  type="number"
                  value={item.minimo}
                  name="minimo"
                  onChange={(e) =>
                    setitem({
                      ...item,
                      [e.target.name]: Math.max(0, e.target.value),
                    })
                  }
                  size="small"
                  fullWidth
                />
              </TableCell> */}
              <TableCell size="small" align="center" className={claseCelda}>
                <TextField
                  disabled
                  value={item.stock_disponible}
                  name="stock_disponible"
                  onChange={(e) =>
                    setitem({
                      ...item,
                      [e.target.name]: Math.max(0, e.target.value),
                    })
                  }
                  size="small"
                  fullWidth
                />
              </TableCell>
              <TableCell
                size="small"
                align="center"
                className={claseCelda}
              ></TableCell>
              <TableCell
                size="small"
                align="center"
                className={claseCelda}
              ></TableCell>
            </>
          </>
        ) : (
          <>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {item.prodTipo}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {item.prodNombre}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {item.talla}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.condicion_producto}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.bodeNombre}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.stock_total_por_bodega}
            </TableCell>
            {/* <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.maximo}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.minimo}
            </TableCell> */}
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.stock_disponible}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.user}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {new Date(row.fecha_upd).toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour: "2-digit",
              })}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
