import React, { useState, useContext, useEffect, memo, createRef } from "react";

import Draggable from "react-draggable";

import { PersonAdd, Create, Delete, Download } from "@mui/icons-material";
// eslint-disable-next-line
import Dropzone from "../../components/Dropzone";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";
import { Autocomplete } from "@mui/material";
import { replaceCaracteres, trimPropsItem } from "../../../config/const";

// MATERIAL
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  Fade,
  FormControl,
  Tooltip,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { Cancel, CloudUpload } from "@mui/icons-material";
import { objectDefault } from "../data";
import { objectDefault as objectDefaultTurnoExtra } from "../../TurnoExtra/data";
import ModalTurnoExtra from "../../TurnoExtra/Componentes/Modal";
import CalendarioPlus from "../../components/CalendarioPlus";
import { useModalStyle } from "../../styles/styleModal";
import { mismoDia } from "../../functions/fechas";
import useMultimediasUpload from "../../hooks/useMultimediasUpload";
import CampoDoc from "../../components/CampoDoc";
function getModalStyleOculto() {
  return {
    borderRadius: "15px",
    opacity: ".2",
  };
}
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalAsistencia = (props) => {
  const [focusedDate, setFocusedDate] = useState("");
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    fromHorarios,
    // ausEstado: "APROBADO",
  } = props;

  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  // eslint-disable-next-line
  const [puestosActuales, setpuestosActuales] = useState([]);
  const [turnos, setturnos] = useState([]);
  const [personasData, setpersonasData] = useState([]);
  const containerRef = React.useRef(null);

  const [docuCliente, setdocuCliente] = useState("");
  const [openTurnoExtra, setopenTurnoExtra] = useState(false);
  const [editarTurnoExtra, seteditarTurnoExtra] = useState(false);
  //documento

  // eslint-disable-next-line
  const {
    // infIdDirigido,
    // ausIdPersona,

    // ausIdPersona,
    ausDocuPersona,
    ausNomPersona,
    // ausPuesto,
    ausMotivo,

    ausDetalle,
    ausDocumento,
    ausFotos,
    ausDiasPermiso,
    ausPerNombreCliente,
    ausEstado,
    ausStatusDescripcion,
    ausFechas,
    ausFechasConsultaDB,
  } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({});
  const getCLiente = async () => {
    console.log("hacer lo necesario", ObjectoActivo.ausDocuPersona);
    const res = await clienteAxios.get(
      `/personas/filtro/0?search=${ObjectoActivo.ausDocuPersona}`
    );

    setpersonasData(res.data.data);
    const itemF = res.data.data.find((itemPersona) => {
      return itemPersona.perId === Number(ObjectoActivo.ausIdPersona);
    });

    if (itemF.perDocuCliente === "") {
      return mostrarAlerta(
        "Es probable que esta persona no tenga cliente",
        "warning"
      );
    }
    setdocuCliente(itemF.perDocuCliente);
    setturnos(itemF.perTurno);
    setpuestosActuales(itemF.perPuestoServicio);
    setitem({
      ...ObjectoActivo,
      ausDocuPersona: itemF.perDocNumero,
      ausNomPersona: itemF.perApellidos + " " + itemF.perNombres,
      ausIdPersona: itemF.perId,
      ausPerIdCliente: itemF.perIdCliente,
      ausPerDocuCliente: itemF.perDocuCliente,
      ausPerNombreCliente: itemF.perNombreCliente,
      ausPuesto: [],
    });
  };

  useEffect(() => {
    if (ObjectoActivo.ausDocuPersona !== "") {
      getCLiente();
    }
    if (editar) {
      const getTurnos = async () => {
        const res = await clienteAxios.get(
          `/turnoextra/byid?lista=${ObjectoActivo.idTurno?.join(",")}`
        );
        console.log({ res });

        setItemsTurnos(res.data.data);
      };
      getTurnos();
    }
    setdocuCliente(ObjectoActivo.ausPerDocuCliente);
    console.log(ObjectoActivo);

    setitem(ObjectoActivo);
    // eslint-disable-next-line
  }, [open]);

  const [itemsTurnos, setItemsTurnos] = useState([]);
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });

  useEffect(() => {
    socket.current.on("server:guardadoExitoso", (data) => {
      setitemSocket({
        tipo: "agregar",
        item: data,
      });
      socket.current.on("server:actualizadoExitoso", (data) => {
        setitemSocket({
          tipo: "editar",
          item: data,
        });
      });
    });

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { item, tipo } = itemSocket;
    if (!open) {
      return;
    }
    if (tipo === "") {
      return;
    }
    if (usuario.usuario !== item.turUser) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== "turnoextra") {
      return;
    }
    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      setItemsTurnos((pev) => [...pev, item]);
    }
    if (tipo === "editar") {
      setItemsTurnos(
        itemsTurnos.map((itemOtroLugar) => {
          if (itemOtroLugar.turId === item.turId) {
            return item;
          } else {
            return itemOtroLugar;
          }
        })
      );
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);

  const defaultActive = (eliminar) => {
    changeObjetoActivoState(objectDefault);
    setopen(false);
    seteditar(false);
    seterror({});
    setseccionActive({
      DatosPrincipales: true,
    });
    // setdocumentoinvausDocumento("");
    setdocuCliente("");
    setturnos([]);
    setmodificoImagenes(false);
    setcargando(false);
    setimagenes([]);
    if (itemsTurnos.length > 0 && eliminar && !editar) {
      try {
        socket.current.emit("client:eliminarDataSeleccionado", {
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "turnoextra",
          lista: { sinPadre: itemsTurnos.map((item) => item.turId) },
        });
      } catch (error) {}
    }
    setItemsTurnos([]);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal
  const [modalStyleOculto] = useState(getModalStyleOculto);
  const [modalStyle] = useState(getModalStyle);
  // obteniendo los años
  const [
    ocultar,
    // setocultar
  ] = useState(false);
  const validacion = () => {
    if (
      ausDocuPersona.trim() === "" ||
      ausMotivo.trim() === "" ||
      ausFechasConsultaDB.length === 0 ||
      Number(ausFechasConsultaDB.length) === 0 ||
      itemsTurnos.length === 0 ||
      itemsTurnos
        .map((row) => {
          return Number(row.turDiasTurno);
        })
        .reduce(reducer) !== ausFechasConsultaDB.length
    ) {
      seterror({
        ausDocuPersona: ausDocuPersona.trim() === "" ? true : false,
        ausMotivo: ausMotivo.trim() === "" ? true : false,
        ausFechasConsultaDB: ausFechasConsultaDB.length === 0,
        idTurno:
          Number(ausFechasConsultaDB.length) === 0 ||
          itemsTurnos.length === 0 ||
          itemsTurnos
            .map((row) => {
              return Number(row.turDiasTurno);
            })
            .reduce(reducer) !== ausFechasConsultaDB.length,
      });
      return true;
    } else {
      return false;
    }
  };
  const [seccionActive, setseccionActive] = useState({
    DatosPrincipales: true,
    DatosFechas: false,
    Apelacion: false,
  });
  // eslint-disable-next-line
  const { DatosPrincipales, DatosFechas, Apelacion } = seccionActive;
  // eslint-disable-next-line
  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }

    try {
      setcargando(true);

      const resDocumentos = await subirImagenes(imagenes, ausFotos);
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          ausDiasPermiso: ausFechasConsultaDB.length,
          ausFotos: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          ausUser: usuario.usuario,
          ausEmpresa: usuario.rucempresa,
          tabla: "ausencia",
          idTurno: itemsTurnos.map((itemTurno) => itemTurno.turId),
          // fromHorarios,
          ausEstado: fromHorarios ? "APROBADO" : item.ausEstado,
        })
      );

      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }

    try {
      setcargando(true);

      const resDocumentos = await subirImagenes(imagenes, ausFotos);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          ausDiasPermiso: ausFechasConsultaDB.length,
          ausFotos: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          ausEmpresa: usuario.rucempresa,
          tabla: "ausencia",
          idTurno: itemsTurnos.map((itemTurno) => itemTurno.turId),
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  // OBJETO ACTIVO O PLANTILLA
  const [ObjectoActivoTurnoExtra, setObjetoActivoTurnoExtra] = useState(
    objectDefaultTurnoExtra
  );
  // CAMBIA EL OBJETO ACTIVO DEL STATE GENERALMENTE PARA EDITAR
  const changeObjetoActivoStateTurnoExtra = (item) => {
    setObjetoActivoTurnoExtra(item);
  };

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <ModalTurnoExtra
          open={openTurnoExtra}
          setopen={setopenTurnoExtra}
          editar={editarTurnoExtra}
          seteditar={seteditarTurnoExtra}
          ObjectoActivo={ObjectoActivoTurnoExtra}
          changeObjetoActivoState={changeObjetoActivoStateTurnoExtra}
          socket={socket}
          desdeAusencia={true}
        />
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div
              style={ocultar ? modalStyleOculto : modalStyle}
              className={clases.paper}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"} Permiso.
                </Typography>
                <Typography
                  variant="h5"
                  color="initial"
                  align="center"
                ></Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive(true);
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" center"
                width="100%"
              >
                <Box>
                  <Button
                    variant={
                      DatosPrincipales
                        ? "contained"
                        : error.ausDocuPersona ||
                          error.ausMotivo ||
                          error.idTurno
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.ausDocuPersona || error.ausMotivo || error.idTurno
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ DatosPrincipales: true })}
                  >
                    Datos Principales
                  </Button>
                  <Button
                    variant={
                      DatosFechas
                        ? "contained"
                        : error.ausFechasConsultaDB
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={error.ausFechasConsultaDB ? "error" : "secondary"}
                    onClick={() => setseccionActive({ DatosFechas: true })}
                  >
                    Fechas
                  </Button>

                  {editar ? (
                    <Button
                      variant={Apelacion ? "contained" : null}
                      disableElevation
                      style={{ padding: "8px" }}
                      color="secondary"
                      onClick={() => setseccionActive({ Apelacion: true })}
                    >
                      Status
                    </Button>
                  ) : null}
                </Box>
                <Box>
                  <Typography
                    fontWeight={"bold"}
                    variant="body1"
                    color={error.idTurno ? "error" : "primary"}
                  >
                    Dias necesarios:
                    <Typography component="span">
                      {itemsTurnos.length === 0
                        ? 0
                        : itemsTurnos
                            .map((row) => {
                              return Number(row.turDiasTurno);
                            })
                            .reduce(reducer)}
                      /{ausFechasConsultaDB.length}
                    </Typography>
                  </Typography>
                </Box>
                {/* <FormControlLabel
                control={
                  <Switch
                    checked={
                      conEstado === "ACTIVO" || conEstado === "ACTIVA"
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setitem({
                        ...item,
                        conEstado: e.target.checked ? "ACTIVA" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                    color="primary"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                style={{ maxHeight: "65vh", overflow: "scroll" }}
                p={1}
                ref={containerRef}
              >
                <Fade
                  direction="up"
                  in={DatosPrincipales}
                  style={{ width: "100%" }}
                >
                  <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                    {itemsTurnos.map((itemOtroLugar) => {
                      return (
                        <Box
                          display={"flex"}
                          justifyContent="space-between"
                          alignItems={"center"}
                          className={clases.formD}
                        >
                          <Typography
                            variant="body2"
                            color="secondary"
                            fontWeight={"bold"}
                          >
                            Turno Creado:{" "}
                            <Typography
                              variant="body2"
                              color="primary"
                              component={"span"}
                            >
                              {itemOtroLugar.turDocuPersona}{" "}
                              {itemOtroLugar.turNomPersona} -
                              {itemOtroLugar.turDiasTurno} Día
                            </Typography>
                          </Typography>
                          <Box display={"flex"} alignItems="center">
                            {" "}
                            <IconButton
                              aria-label=""
                              style={{ margin: "0px" }}
                              onClick={() => {
                                changeObjetoActivoStateTurnoExtra(
                                  itemOtroLugar
                                );
                                seteditarTurnoExtra(true);
                                setopenTurnoExtra(true);
                              }}
                            >
                              <Create color="secondary" />
                            </IconButton>
                            <IconButton
                              disabled={editar}
                              style={{ margin: "0px" }}
                              color="error"
                              onClick={() => {
                                socket.current.emit("client:eliminarData", {
                                  rucempresa: usuario.rucempresa,
                                  rol: usuario.rol,
                                  turUser: usuario.usuario,
                                  turEmpresa: usuario.rucempresa,
                                  turId: itemOtroLugar.turId,
                                  turFecReg: itemOtroLugar.turFecReg,
                                  tabla: "turnoextra",
                                });
                                setItemsTurnos(
                                  itemsTurnos.filter(
                                    (itemEliminar) =>
                                      itemOtroLugar.turId !== itemEliminar.turId
                                  )
                                );
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Box>
                        </Box>
                      );
                    })}
                    <Box display="flex" width="100%"></Box>
                    {DatosPrincipales ? (
                      <>
                        {/* PERSONAS */}
                        <FormControl
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                        >
                          <Autocomplete
                            disabled={fromHorarios}
                            options={personasData.map((item) => {
                              return (
                                item.perDocNumero +
                                "/+/" +
                                item.perApellidos +
                                " " +
                                item.perNombres +
                                "/+/" +
                                item.perId
                              );
                            })}
                            getOptionLabel={(option) => {
                              return option.replace("/+/", " ").split("/+/")[0];
                            }}
                            id="controlled-demo"
                            value={
                              (ausDocuPersona + " " + ausNomPersona).trim() ===
                              ""
                                ? ""
                                : ausDocuPersona + " " + ausNomPersona
                            }
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const options = newValue.split("/+/");

                                try {
                                  const itemF = personasData.find(
                                    (itemPersona) => {
                                      return (
                                        itemPersona.perId === Number(options[2])
                                      );
                                    }
                                  );

                                  if (itemF.perDocuCliente === "") {
                                    return mostrarAlerta(
                                      "Es probable que esta persona no tenga cliente",
                                      "warning"
                                    );
                                  }
                                  setdocuCliente(itemF.perDocuCliente);
                                  setturnos(itemF.perTurno);
                                  setpuestosActuales(itemF.perPuestoServicio);
                                  setitem({
                                    ...item,
                                    ausDocuPersona: itemF.perDocNumero,
                                    ausNomPersona:
                                      itemF.perApellidos +
                                      " " +
                                      itemF.perNombres,
                                    ausIdPersona: itemF.perId,
                                    ausPerIdCliente: itemF.perIdCliente,
                                    ausPerDocuCliente: itemF.perDocuCliente,
                                    ausPerNombreCliente: itemF.perNombreCliente,
                                    ausPuesto: [],
                                  });
                                } catch (error) {
                                  return mostrarAlerta(
                                    "No hay personas",
                                    "error"
                                  );
                                }
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                size="small"
                                required
                                fullWidth
                                disabled={fromHorarios}
                                label="Persona"
                                error={error.ausDocuPersona}
                                onChange={async (e) => {
                                  if (e.target.value === "") {
                                    return mostrarAlerta(
                                      "Ingrese datos",
                                      "error"
                                    );
                                  }
                                  try {
                                    const res = await clienteAxios.get(
                                      `/personas/filtro/0?search=${e.target.value}`
                                    );

                                    setpersonasData(res.data.data);
                                    setpuestosActuales([]);
                                  } catch (error) {
                                    setitem({
                                      ...item,
                                      ausDocuPersona: "",
                                      ausNomPersona: "",
                                      ausIdPersona: "",
                                      ausPuesto: [],
                                    });
                                    setpersonasData([]);
                                    setpuestosActuales([]);

                                    return mostrarAlerta(
                                      "No hay personas",
                                      "error"
                                    );
                                  }
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        {/* PUESTOS ACTUALES */}
                        <TextField
                          variant="standard"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="ausMotivo"
                          label="Motivo"
                          value={ausMotivo}
                          name="ausMotivo"
                          error={error.ausMotivo}
                          onChange={(e) => handleChange(e)}
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ displayEmpty: true }}
                          InputProps={{
                            endAdornment: (
                              <React.Fragment>
                                {
                                  <Tooltip title="Agregar Turno Extra">
                                    <IconButton
                                      disabled={
                                        docuCliente === "" ||
                                        ausMotivo === "" ||
                                        ausFechasConsultaDB.length === 0 ||
                                        [...itemsTurnos, { turDiasTurno: 0 }]
                                          .map((row) => {
                                            return (
                                              Number(row.turDiasTurno) || 0
                                            );
                                          })
                                          .reduce(reducer) >=
                                          ausFechasConsultaDB.length
                                      }
                                      size="small"
                                      onClick={async () => {
                                        try {
                                          const res = await clienteAxios.get(
                                            `/clientes/filtro/0?search=${item.ausPerDocuCliente}&estado=CLIENTES`
                                          );
                                          if (!res.data.data[0]) {
                                            return mostrarAlerta(
                                              "Esta persona no tiene cliente",
                                              "error"
                                            );
                                          }

                                          const itemF = res.data.data[0];

                                          changeObjetoActivoStateTurnoExtra({
                                            ...objectDefaultTurnoExtra,
                                            turIdCliente: itemF.cliId,
                                            turDocuCliente: itemF.cliDocNumero,
                                            turNomCliente: itemF.cliRazonSocial,
                                            // turFechas: ausFechas,
                                            // turFechasConsultaDB: ausFechasConsultaDB,
                                            // turDiasTurno:
                                            //   ausFechasConsultaDB.length,
                                            permisosAcubrir: ausDiasPermiso,
                                            turMotivo: ausMotivo,
                                            // turDocuPersona: item.ausDocuPersona,
                                            // turNomPersona: item.ausNomPersona,
                                            // turIdPersona: item.ausIdPersona,
                                            turPuesto: [
                                              {
                                                ubicacion: "",
                                                puesto: "",
                                                supervisor: "",
                                                guardias: "",
                                                horasservicio: "",
                                                tipoinstalacion: "",
                                                vulnerabilidades: "",
                                                consignas: [],
                                                id: "",
                                              },
                                            ],
                                            fechasPermiso: ausFechas,
                                            fechasPermisoConsultaDB: ausFechasConsultaDB,
                                            itemsTurnos,
                                          });

                                          setopenTurnoExtra(true);
                                        } catch (error) {
                                          return mostrarAlerta(
                                            "Hubo un error",
                                            "error"
                                          );
                                        }
                                      }}
                                    >
                                      <PersonAdd
                                        sx={{ mr: "2rem" }}
                                        color={
                                          docuCliente === "" ||
                                          ausMotivo === "" ||
                                          ausFechasConsultaDB.length === 0 ||
                                          [...itemsTurnos, { turDiasTurno: 0 }]
                                            .map((row) => {
                                              return (
                                                Number(row.turDiasTurno) || 0
                                              );
                                            })
                                            .reduce(reducer) >=
                                            ausFechasConsultaDB.length
                                            ? "inherit"
                                            : "secondary"
                                        }
                                      />
                                    </IconButton>
                                  </Tooltip>
                                }
                              </React.Fragment>
                            ),
                          }}
                        >
                          <MenuItem value="">Seleccione.</MenuItem>
                          {[
                            "ENFERMEDAD IESS",
                            "PERMISO PERSONAL",
                            "PATERNIDAD",
                            "DEFUNCION FAMILIAR",
                            "INJUSTIFICADA",
                          ].map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })}
                        </TextField>
                        <TextField
                          disabled
                          inputProps={{
                            className: clases.inputPadding,
                          }}
                          className={clases.formD}
                          size="small"
                          label="Cliente"
                          value={ausPerNombreCliente}
                          name="ausPerNombreCliente"
                          error={error.ausPerNombreCliente}
                          onChange={(e) => handleChange(e)}
                        />

                        <>
                          <CampoDoc
                            setcargando={setcargando}
                            label="Documento"
                            propiedad={"ausDocumento"}
                            item={item}
                            setitem={setitem}
                            celda={false}
                          />
                        </>
                        <TextField
                          multiline
                          rows={3}
                          variant="outlined"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formAllW}
                          size="small"
                          id="ausDetalle"
                          label="Detalle"
                          value={ausDetalle}
                          name="ausDetalle"
                          error={error.ausDetalle}
                          onChange={(e) => handleChange(e)}
                        />
                        <>
                          <Dropzone
                            imagenes={imagenes}
                            setimagenes={(e) => {
                              setmodificoImagenes(true);
                              setimagenes(e);
                            }}
                            fotosItem={ausFotos}
                          />
                        </>
                        {/* 
                      <TextField
                      variant="standard"
                        type={"date"}
                        InputLabelProps={{ shrink: true }}
                      inputProps={{
                          className: clases.inputPadding,
                          min: FechaExacta,
                        }}
                        className={clases.formD}
                        size="small"
                        id="camFecha"
                        label="Fecha"
                        value={camFecha}
                        name="camFecha"
                        error={error.camFecha}
                        onChange={(e) => handleChange(e)}
                      /> */}
                      </>
                    ) : null}
                  </Box>
                </Fade>
                {DatosFechas ? (
                  <>
                    {error.ausFechasConsultaDB ? (
                      <Typography
                        variant="body1"
                        color={"error"}
                        className={clases.formD}
                      >
                        Seleccione al menos una fecha
                      </Typography>
                    ) : null}
                    <CalendarioPlus
                      readOnly={editar}
                      filtro={(dias) => {
                        const res = dias.filter((dia) =>
                          turnos.some((turno) =>
                            turno.fechas.some((fecha) =>
                              mismoDia(Date.parse(dia), fecha.desde)
                            )
                          )
                        );
                        return res;
                      }}
                      propiedad={ausFechas}
                      propiedadDB={ausFechasConsultaDB}
                      focusedDate={focusedDate}
                      setFocusedDate={(e) => {
                        const res = turnos.some((turno) =>
                          turno.fechas.some((fecha) =>
                            mismoDia(Date.parse(e), fecha.desde)
                          )
                        );
                        setFocusedDate(res ? e : "");
                      }}
                      item={item}
                      setitem={(e) => {
                        if (editar) {
                          return setitem((prev) => prev);
                        }
                        const fechasConId = turnos.reduce((acc, obj) => {
                          return [
                            ...acc,
                            ...obj.fechasConsultaDB.map((fechaConsulta) => ({
                              ...fechaConsulta,
                              id: obj.id,
                            })),
                          ];
                        }, []);
                        const ausFechasConsultaDB = e.ausFechasConsultaDB.map(
                          (fechaConsulta) =>
                            fechasConId.find(
                              (fechaConId) =>
                                fechaConId.desde.substring(0, 10) ===
                                fechaConsulta.desde.substring(0, 10)
                            )
                        );
                        setitem({ ...e, ausFechasConsultaDB });
                      }}
                      TextoPropiedad={"ausFechas"}
                      textoPropiedadDB={"ausFechasConsultaDB"}
                      mapDays={({ date, isSameDate }) => {
                        let props = {};
                        turnos.forEach((turno) => {
                          turno.fechas.forEach((fecha) => {
                            if (mismoDia(Date.parse(date), fecha.desde)) {
                              props.style = { backgroundColor: "lightblue" };
                            }
                          });
                        });
                        ausFechas.forEach((ausFecha) => {
                          if (mismoDia(Date.parse(date), ausFecha.desde)) {
                            props.style = { backgroundColor: "lightgreen" };
                          }
                        });
                        if (isSameDate(date, focusedDate)) {
                          props.style = { backgroundColor: "red" };
                        }

                        return props;
                      }}
                      // openCalendario={true}
                      // setopenCalendario={setopenCalendario}
                    />
                  </>
                ) : null}{" "}
                <Fade direction="up" in={Apelacion} style={{ width: "100%" }}>
                  <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                    <Box display="flex" width="100%"></Box>
                    {Apelacion ? (
                      <>
                        <Box
                          className={clases.formAllW}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          mt={2}
                        >
                          <Divider style={{ width: "42%" }} />
                          <Typography
                            style={{ width: "100%" }}
                            variant="button"
                            color="primary"
                            align="center"
                            component={"span"}
                          >
                            Verificación
                          </Typography>
                          <Divider style={{ width: "42%" }} />
                        </Box>
                        <TextField
                          disabled={ObjectoActivo.ausEstado === "ASIGNADA"}
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formAllW}
                          size="small"
                          id="ausEstado"
                          label="Estado"
                          value={ausEstado}
                          name="ausEstado"
                          error={error.ausEstado}
                          onChange={(e) => handleChange(e)}
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ displayEmpty: true }}
                        >
                          <MenuItem disabled value="">
                            Seleccione.
                          </MenuItem>
                          {["EN PROCESO", "APROBADO", "ANULADA"].map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })}
                        </TextField>
                        <TextField
                          disabled={ObjectoActivo.ausEstado === "ASIGNADA"}
                          multiline
                          rows={3}
                          variant="outlined"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formAllW}
                          size="small"
                          id="ausStatusDescripcion"
                          label="Status"
                          value={ausStatusDescripcion}
                          name="ausStatusDescripcion"
                          error={error.ausStatusDescripcion}
                          onChange={(e) => handleChange(e)}
                        />
                      </>
                    ) : null}
                  </Box>
                </Fade>
              </Box>
              {/* <Divider /> */}
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={
                    cargando ||
                    ((ObjectoActivo.ausEstado === "ANULADA" ||
                      ObjectoActivo.ausEstado === "APROBADO") &&
                      editar &&
                      (!usuario.rol.includes("ADMIN") ||
                        (ObjectoActivo.ausEstado === "APROBADO" &&
                          item.ausEstado === "ANULADA")))
                  }
                  onClick={() => {
                    if (editar) {
                      handleEditar();
                    } else {
                      handleCrear();
                    }
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
