export const columns = [
  {
    id: "rolpPeriodo",
    label: "Periodo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "rolpPeriodo",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "rolpDocNumCliente",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "rolpDocNumCliente",
    align: "left",
    ocultar: true,
  },
  {
    id: "tguardias",
    label: "guardias",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tguardias",
    align: "center",
    ocultar: true,
  },

  {
    id: "tsueldo",
    label: "sueldo",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tsueldo",
    align: "center",
    ocultar: true,
  },
];
export const columnsSubHijos = [
  {
    id: "rolpCedula",
    label: "Cédula",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "rolpCedula",
    align: "center",
    ocultar: true,
  },

  {
    id: "rolpNombres",
    label: "Nombres",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "rolpNombres",
    align: "center",
    ocultar: true,
  },

  {
    id: "rolpCargo",
    label: "Cargo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "rolpCargo",
    align: "center",
    ocultar: true,
  },

  {
    id: "rolpCiudad",
    label: "Ciudad",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "rolpCiudad",
    align: "center",
    ocultar: true,
  },

  {
    id: "rolpSueldo",
    label: "Sueldo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "rolpSueldo",
    align: "center",
    ocultar: true,
  },

  {
    id: "rolpDiaLabo",
    label: "Dia Lab.",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "rolpDiaLabo",
    align: "center",
    ocultar: true,
  },

  {
    id: "rolpFecReg",
    label: "Fec. Reg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "rolpFecReg",
    align: "center",
    ocultar: true,
  },

  {
    id: "rolpUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "rolpUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  visualizacion_app: "SI",
  rolpCodigo: "",
  rolpIdCliente: "",
  rolpDocNumCliente: "",
  rolpNomCliente: "",
  rolpCedula: "",
  rolpIdPersona: "",
  rolpNombres: "",
  rolpFoto: "",
  rolpSueldo: "",
  rolpCargo: "",
  rolpCiudad: "",
  rolpDiaLabo: "30",
  rolpPerEstatus: "",
  rolpEstado: "PENDIENTE",
  rolpPeriodo: "",
  rolpNumCuenta: "",
  rolpBancoRemu: "",
  rolpEmail: [],
  rolpIngresos: [],
  rolpEgresos: [],
  rolpFecReg: "",
  rolpUser: "",
  Todos: "",
  rolpDocTipo: "",
  rolpTipoCuenta: "",
  rolpFormaPago: "",
  rolpTelefono: "",
  tguardias: 0,
  tsueldo: "",
  rolpLiquidaciones: [],
  rolpOtros: [],
};
