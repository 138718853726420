import React, { useContext, useEffect, memo, useState, useMemo } from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  Box,
  TextField,
  Dialog,
  MenuItem,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Avatar,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { withRouter } from "react-router-dom";
import Row from "./Row";
import Cargando from "../../../components/Cargando";
import ToolBarTable from "../../../components/ToolBarTable";
import TablaCabecera from "../../../components/TablaCabecera";
import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  BackupTable,
  Cancel,
  DeleteForever,
  ListRounded,
  PictureAsPdf,
  Refresh,
} from "@mui/icons-material";
import DialogOpciones from "../../../components/DialogOpciones";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import clienteAxios from "../../../../config/axios";
import { saveAs } from "save-as";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { useTableStyles } from "../../../styles/styleTable";
import { usePermiso } from "../../../hooks/usePermiso";
import queryString from "query-string";
import { useTheme } from "@emotion/react";
const Tabla = (props) => {
  //clases
  const classes = useTableStyles(); // props
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    setopen,
    search,
    input,
    orden,
    setinput,
    setorden,
    cargando,
    setcargando,
    fullBuscador, //  STATE
    rows,
    columns,
    pagination,
    getDataState,
    obtenerState,
    addRowsState,
    remplazarRowsState,
    changeObjetoActivoState,
    seteditar,
    arrayExport,
    setarrayExport,
    socket,
    campoExport,
    estadoRequest,
    tabla,
    setestadoRequest,
    setsearch,
    setbuscando,
    fromHorarios,
    setestadoRequestAnticipo,
    changeObjetoActivoStateAnticipo,
    setopenAnticipo,
    setopenMulta,
    changeObjetoActivoStateMulta,
    setestadoRequestMulta,
    setopenAusencia,
    changeObjetoActivoStateAusencia,
    setestadoRequestAusencia,
    changeObjetoActivoStateTurnoExtra,
    setestadoRequestTurnoExtra,
    setopenTurnoExtra,
  } = props;
  const {
    usuario: { rucempresa },
    usuario,
  } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);

  const [openDialogOpciones, setopenDialogOpciones] = useState(false);
  const [openCDEliminar, setopenCDEliminar] = useState(false);
  const [cargandoDialogOpciones, setcargandoDialogOpciones] = useState(false);
  const [openDialogPerfil, setopenDialogPerfil] = useState(false);
  const [openDialogEmpresas, setopenDialogEmpresas] = useState(false);
  const perfilD = {
    perId: null,
    newPerfil: "",
    oldPerfil: "",
  };
  const [perfilData, setperfilData] = useState(perfilD);
  const { tienePermiso, alertaPermiso } = usePermiso("Personas");
  const handleEliminar = async () => {
    if (!tienePermiso("eliminar")) {
      return alertaPermiso("eliminar");
    }

    await setcargando(true);

    await setTimeout(async () => {
      socket.current.emit("client:eliminarDataSeleccionado", {
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        tabla,
        lista: arrayExport,
      });
      setcargandoDialogOpciones(false);
      setopenCDEliminar(false);
      setcargando(false);
    }, 500);
  };
  const handleChangePage = async (event, newPage) => {
    if (newPage * rowsPerPage + rowsPerPage > rows.length) {
      setcargando(true);
      const res = await getDataState(
        rowsPerPage,
        newPage,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        estadoRequest
      );

      const data = res.data.data.results;

      setcargando(false);
      const resultado = data.filter((row) => {
        const existe = rows.some((item, index) => {
          return row[campoExport] === item[campoExport];
        });

        return !existe;
      });

      addRowsState(resultado);
    }

    setPage(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    obtenerState(
      event.target.value,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
  };
  const funcionOrdenar = async (column) => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      column.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setPage(0);
    setinput(column);
    setorden(!orden);
    setcargando(false);
  };
  const funcionReload = async () => {
    setcargando(true);
    setRowsPerPage(10);
    setPage(0);
    await obtenerState(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setcargando(false);
  };

  const { idRegistro, notificacion } = queryString.parse(
    window.location.search
  );
  useEffect(() => {
    //eslint-disable-next-line
    const documento = window.location.href.split("documento");
    const rol = window.location.href.split("rol");
    if (documento[1] && rol[1]) {
      const documentoSplit = documento[1].split("&")[0].replace("=", "");
      const rolSplit = rol[1].replace("=", "");
      const estado =
        rolSplit === "SUPERVISOR"
          ? "SUPERVISORES"
          : rolSplit === "GUARDIA"
          ? "GUARDIAS"
          : "ADMINISTRACIÓN";
      setestadoRequest(estado);
      setsearch(documentoSplit);
      setbuscando(true);
      return obtenerState(
        10,
        0,
        documentoSplit,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        estado,
        notificacion === "true",
        idRegistro
      );
    }
    obtenerState(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      notificacion === "true",
      idRegistro
    );

    // eslint-disable-next-line
  }, []);

  const [empresas, setEmpresas] = useState(null);
  useEffect(() => {
    if (!empresas) {
      const getEmpresasData = async () => {
        const res1 = await clienteAxios.get("personas/todo/codigo/empresas");
        const res = await clienteAxios.post("empresas/logos-por-codigos", {
          mis_empresas: res1.data.codigos_empresas.map(
            (codigo) => codigo.codigo
          ),
        });

        setEmpresas(res.data);
      };
      getEmpresasData();
    } // eslint-disable-next-line
  }, []);
  const [misEmpresas, setmisEmpresas] = useState([]);
  useMemo(async () => {
    if (perfilData.empresas) {
      const res = await clienteAxios(
        `personas/codigo/empresas/persona/${perfilData.perId}`
      );
      console.log(res.data.mis_empresas);

      setmisEmpresas(res.data.mis_empresas);
    }
    return [];
  }, [perfilData.empresas]);

  function getStylesMenuItems(name, permisos, theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor:
        permisos.indexOf(name) === -1 ? "white" : theme.palette.primary.main,
      color:
        permisos.indexOf(name) === -1
          ? "black"
          : theme.palette.primary.contrastText,
    };
  }
  const theme = useTheme();
  if (!empresas) {
    return <Cargando titulo={"Cargando..."} />;
  }

  //si no hay usuarios //rows no cargar el componente
  if (!rows) {
    return <Cargando titulo="Cargando" />;
  }
  if (!pagination) {
    return <Cargando titulo="Cargando" />;
  }

  return (
    <>
      <Dialog
        maxWidth="md"
        open={openDialogPerfil}
        keepMounted
        onClose={() => {
          setopenDialogPerfil(false);
          setperfilData(perfilD);
        }}
      >
        <>
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems={"center"}
            >
              <DialogTitle id="alert-dialog-title">
                Cambiar de perfil
              </DialogTitle>{" "}
              <IconButton
                onClick={() => {
                  setopenDialogPerfil(false);
                  setperfilData(perfilD);
                }}
                color="secondary"
              >
                <Cancel />
              </IconButton>
            </Box>

            <Box pr={1} pl={1} pb={1}>
              <DialogContent sx={{ p: 0 }}>
                <TextField
                  fullWidth
                  size="small"
                  label="Perfil"
                  value={perfilData.newPerfil}
                  onChange={(e) =>
                    setperfilData({ ...perfilData, newPerfil: e.target.value })
                  }
                  select
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                >
                  <MenuItem value="">Seleccione perfil.</MenuItem>
                  {["GUARDIA", "SUPERVISOR", "ADMINISTRACION"].map((item) => {
                    return <MenuItem value={item}>{item} </MenuItem>;
                  })}
                </TextField>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={async () => {
                    try {
                      const res = await clienteAxios.post(
                        "personas/updatePerfil/0",
                        perfilData
                      );
                      setopenDialogPerfil(false);
                      setperfilData(perfilD);
                      mostrarAlerta(res.data.msg, "success");
                    } catch (error) {
                      mostrarAlerta("Hubo un error al cambiar perfil", "error");
                    }
                  }}
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={perfilData.newPerfil === ""}
                >
                  GUARDAR
                </Button>
              </DialogActions>
            </Box>
          </Box>
        </>
      </Dialog>
      <Dialog
        maxWidth="lg"
        open={openDialogEmpresas}
        keepMounted
        onClose={() => {
          // setopenDialogEmpresas(false);
          // setperfilData(perfilD);
        }}
      >
        <>
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems={"center"}
            >
              <DialogTitle id="alert-dialog-title">
                Cambiar Empresas {perfilData.perId}
              </DialogTitle>
              <IconButton
                onClick={() => {
                  setopenDialogEmpresas(false);
                  setperfilData(perfilD);
                }}
                color="secondary"
              >
                <Cancel />
              </IconButton>
            </Box>
            <Box p={2} sx={{ height: "50vh" }}>
              <DialogContent sx={{ p: 0 }}>
                <TextField
                  fullWidth
                  size="small"
                  label={"Empresas"}
                  value={misEmpresas}
                  onChange={(e) => {
                    setmisEmpresas(e.target.value);
                  }}
                  select
                  SelectProps={{
                    displayEmpty: true,
                    multiple: true,
                    renderValue: (selected) => {
                      return selected
                        .map((value, index) => {
                          return value;
                        })
                        .join(", ");
                    },
                  }}
                >
                  {empresas.map((item) => {
                    return (
                      <MenuItem
                        value={item.empCodigo}
                        // sx={
                        //   misEmpresas.some(
                        //     (miEmpresa) => miEmpresa === item.empCodigo
                        //   )
                        //     ? (theme) => {
                        //         console.log(theme.palette.primary.main);

                        //         return {
                        //           backgroundColor: theme.palette.primary.main,
                        //           // color: theme.palette.info.main,
                        //         };
                        //       }
                        //     : {}
                        // }
                        style={getStylesMenuItems(
                          item.empCodigo,
                          misEmpresas,
                          theme
                        )}
                      >
                        <ListItemIcon>
                          <Avatar src={item.empLogo} sizes="small" />
                        </ListItemIcon>
                        <ListItemText>{item.empCodigo}</ListItemText>
                      </MenuItem>
                    );
                  })}
                </TextField>
              </DialogContent>
            </Box>{" "}
            <DialogActions>
              <Button
                onClick={async () => {
                  try {
                    const res = await clienteAxios.put(
                      "/personas/updateMisEmpresas/0",
                      {
                        perId: perfilData.perId,
                        mis_empresas: misEmpresas,
                      }
                    );

                    setopenDialogEmpresas(false);
                    setperfilData(perfilD);
                    mostrarAlerta(res.data.msg, "success");
                  } catch (error) {
                    mostrarAlerta("Hubo un error al cambiar perfil", "error");
                  }
                }}
                color="primary"
                variant="contained"
                fullWidth
              >
                GUARDAR
              </Button>
            </DialogActions>
          </Box>
        </>
      </Dialog>
      <Paper className={classes.root}>
        <ConfirmacionDialog
          open={openCDEliminar}
          setopen={setopenCDEliminar}
          categoria="warning"
          titulo={`¿Esta seguro de eliminar estos registros?`}
          botones={[
            {
              tooltip: "Se eliminaran los registros",
              texto: "SI",
              funcion: async () => {
                await handleEliminar();
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: { marginRight: ".3rem" },
            },
            {
              tooltip: "Cancelar",
              texto: "NO",
              funcion: () => {
                setopenCDEliminar(false);
              },
              disabled: false,
              Icon: Cancel,
              color: "secondary",
              id: 2,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: {},
            },
          ]}
        />
        <DialogOpciones
          open={openDialogOpciones}
          setopen={setopenDialogOpciones}
          botones={[
            {
              tooltip: "Carnet",
              funcion: async () => {
                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/carnet?perId=${arrayExport}&rucempresa=${usuario.rucempresa}`
                );
              },
              Icon: PictureAsPdf,
              color: "error",
              id: 1,
              disabled: cargandoDialogOpciones,
            },
            {
              tooltip: "Excel",
              funcion: async () => {
                try {
                  setcargandoDialogOpciones(true);
                  const json = {
                    lista: arrayExport,
                    rucempresa: usuario.rucempresa,
                    nombre: usuario.nombre,
                    usuario: usuario.usuario,
                  };

                  const res = await clienteAxios.post(
                    `/reportes/excel/lista_personas`,
                    {
                      ...json,
                    },
                    { responseType: "blob" }
                  );
                  const excelBlob = new Blob([res.data], {
                    type:
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  });
                  saveAs(excelBlob, `${tabla}.xlsx`);
                  setopen(false);
                  setcargandoDialogOpciones(false);
                } catch (error) {
                  mostrarAlerta("Hubo un error", "error");
                  setopen(false);
                  setcargandoDialogOpciones(false);
                }
              },
              Icon: BackupTable,
              color: "success",
              id: 1,
              disabled: cargandoDialogOpciones,
            },
            {
              tooltip: "Exportar",
              funcion: async () => {
                try {
                  setcargandoDialogOpciones(true);
                  const json = {
                    search,
                    lista: arrayExport.map((itemExport) => itemExport),
                    rucempresa: usuario.rucempresa,
                    rol: usuario.rol,
                    search_avanzado: fullBuscador,
                  };

                  const res = await clienteAxios.post(
                    `reportes/${tabla}`,
                    {
                      ...json,
                    },
                    { responseType: "blob" }
                  );
                  const pdfBlob = new Blob([res.data], {
                    type: "application/pdf",
                  });
                  saveAs(pdfBlob, `${tabla}.pdf`);
                  setopen(false);
                  setcargandoDialogOpciones(false);
                } catch (error) {
                  mostrarAlerta("Hubo un error", "error");
                  setopen(false);
                  setcargandoDialogOpciones(false);
                }
              },
              Icon: PictureAsPdf,
              color: "error",
              id: 1,
              disabled: cargandoDialogOpciones,
            },
            {
              tooltip: "Claves",
              funcion: async () => {
                try {
                  setcargandoDialogOpciones(true);
                  const json = {
                    search,
                    lista: arrayExport.map((itemExport) => itemExport),
                    rucempresa: usuario.rucempresa,
                    rol: usuario.rol,
                  };

                  const res = await clienteAxios.post(
                    `reportes/claves_personal`,
                    {
                      ...json,
                    },
                    { responseType: "blob" }
                  );
                  const pdfBlob = new Blob([res.data], {
                    type: "application/pdf",
                  });
                  saveAs(pdfBlob, `claves_personal.pdf`);
                  setopen(false);
                  setcargandoDialogOpciones(false);
                } catch (error) {
                  mostrarAlerta("Hubo un error", "error");
                  setopen(false);
                  setcargandoDialogOpciones(false);
                }
              },
              Icon: PictureAsPdf,
              color: "avatar",
              id: 1,
              disabled: cargandoDialogOpciones,
              ocultar: !usuario.rol.includes("ADMIN"),
            },
          ]}
          titulo={"Acciones"}
        />
        <ToolBarTable
          pagination={pagination}
          rowsPerPage={rowsPerPage}
          rows={rows}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          ocultarPaginacion={false}
          botonesIzquierdos={[
            {
              tooltip: "Actualizar",
              texto: "Actualizar",
              funcion: () => {
                funcionReload(true);
              },
              disabled: false,
              Icon: Refresh,
              color: "primary",
              id: 1,
              ocultar: false,
              tipo: "boton",
              variante: "text",
              size: "medium",
              sx: {},
            },
          ]}
          botonesDerechos={[
            {
              tooltip: "Exportar",
              texto: "",
              funcion: () => {
                setopenDialogOpciones(true);
              },
              disabled: arrayExport.length === 0,
              Icon: ListRounded,
              color: "primary",
              id: 1,
              ocultar: fromHorarios,
              tipo: "icono",
              variante: "text",
              size: "medium",
              sx: { margin: 0, padding: 0 },
            },
          ]}
        />
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" id="tabla" size="small">
            <TableHead>
              <TablaCabecera
                rows={rows}
                setarrayExport={fromHorarios ? null : setarrayExport}
                campoExport={campoExport}
                columns={columns}
                habilitarOrdenar={true}
                //nuevos
                input={input}
                orden={orden}
                funcionOrdenar={funcionOrdenar}
                ocultarPrimeraColumna={false}
              />
            </TableHead>
            <TableBody>
              {cargando ? (
                <TableCell
                  size="small"
                  align="center"
                  colSpan={columns.length + 2}
                >
                  <Cargando titulo="Cargando..." />
                </TableCell>
              ) : (
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <Row
                        fromHorarios={fromHorarios}
                        setopenDialogPerfil={setopenDialogPerfil}
                        setopenDialogEmpresas={setopenDialogEmpresas}
                        setperfilData={setperfilData}
                        key={row[campoExport]}
                        arrayExport={arrayExport}
                        setarrayExport={setarrayExport}
                        row={row}
                        index={index}
                        campoExport={campoExport}
                        seteditar={seteditar}
                        setopen={setopen}
                        changeObjetoActivoState={changeObjetoActivoState}
                        socket={socket}
                        estadoRequest={estadoRequest}
                        setestadoRequestAnticipo={setestadoRequestAnticipo}
                        changeObjetoActivoStateAnticipo={
                          changeObjetoActivoStateAnticipo
                        }
                        setopenAnticipo={setopenAnticipo}
                        setopenMulta={setopenMulta}
                        changeObjetoActivoStateMulta={
                          changeObjetoActivoStateMulta
                        }
                        setestadoRequestMulta={setestadoRequestMulta}
                        setopenAusencia={setopenAusencia}
                        changeObjetoActivoStateAusencia={
                          changeObjetoActivoStateAusencia
                        }
                        setestadoRequestAusencia={setestadoRequestAusencia}
                        changeObjetoActivoStateTurnoExtra={
                          changeObjetoActivoStateTurnoExtra
                        }
                        setestadoRequestTurnoExtra={setestadoRequestTurnoExtra}
                        setopenTurnoExtra={setopenTurnoExtra}
                      />
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
export default memo(withRouter(Tabla));
