import React, { memo, useState, useContext, useEffect, useRef } from "react";

import { Box, Button, Divider } from "@mui/material";

import Tabla from "./Componentes/Tabla";
import BarraTablas from "../../components/BarraTablas";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import Modal from "./Componentes/Modal";
import io from "socket.io-client";
import { socketUrl } from "../../../config/const";
import ModalB from "../../components/ModalB";
import DialogOpciones from "../../components/DialogOpciones";
import { Add, MenuBook, UploadFile, Menu, Search } from "@mui/icons-material";
import { usePermiso } from "../../hooks/usePermiso";

const Base = (props) => {
  // DATA DEL STATE
  // REGISTROS O FILAS
  const [rows, setrows] = useState(null);
  // COLUMNAS
  const columns = [
    {
      id: "cliId",
      label: "Código",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "cliId",
      align: "center",
      ocultar: true,
    },

    {
      id: "cliFoto",
      label: "Foto",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "cliFoto",
      align: "center",
      ocultar: true,
    },

    {
      id: "cliDocNumero",
      label: "Documento",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "cliDocNumero",
      align: "center",
      ocultar: true,
    },

    {
      id: "cliRazonSocial",
      label: "Razón Social",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "cliRazonSocial",
      align: "left",
      ocultar: true,
    },

    {
      id: "cliNombreComercial",
      label: "Nombre Comercial",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "cliNombreComercial",
      align: "left",
      ocultar: true,
    },

    {
      id: "cliTelefono",
      label: "Teléfono",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "cliTelefono",
      align: "center",
      ocultar: true,
    },
    {
      id: "perFijo",
      label: "Per. Fijo",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "perFijo",
      align: "center",
      ocultar: true,
    },
    {
      id: "Contratados",
      label: "Contratados",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "Contratados",
      align: "center",
      ocultar: true,
    },
    {
      id: "Disponibles",
      label: "Disponibles",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "Disponibles",
      align: "center",
      ocultar: true,
    },
    {
      id: "cliUser",
      label: "User",
      minWidth: 25,
      tipo: "string",
      orden: 1,
      propiedad: "cliUser",
      align: "center",
      ocultar: true,
    },
  ];
  // FUNCION QUE CREA EL OBJETO BUSCADOR
  const defaultFullBuscador = () => {
    const json = {};
    columns.forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (itemPropiedad.tipo === "numero") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "fecha") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });
    return json;
  };
  // OBJETO ACTIVO O PLANTILLA
  const [ObjectoActivo, setObjetoActivo] = useState({
    //   cliId: 1,
    perGuardias: "1",
    documentos: {},
    cliTiempoInicioContrato: "",
    cliTiempoFinalContrato: "",
    perPerfil: ["CLIENTE"],
    cliDocTipo: "RUC",
    cliDocNumero: "",
    cliRazonSocial: "",
    cliNombreComercial: "",
    cliNombres: "",
    cliDireccion: "",
    cliInstitucion: "PUBLICA",
    cliUbicacion: {
      longitud: -79.178298,
      latitud: -0.254916,
    },
    cliTelefono: [],
    cliCelular: "",
    perEmail: [],
    cliAdministradorContrato: "",
    cliCiudad: "",
    cliArmasAsignadas: [],
    cliDatosOperativos: [],
    cliEquiposAsignados: [],
    cliOtrosEquipos: "",
    cliEmpresa: [],
    perPais: "ECUADOR",
    perProvincia: "SANTO DOMINGO DE LOS TSÁCHILAS",
    perCanton: "SANTO DOMINGO",
    perEstado: "ACTIVO",
    perFoto: "",
    perFotografiasInstalaciones: [],
    perLogo: "",
    cliUser: "",
    cliObservacion: "",
    cliFecReg: "",
    Todos: "",
    perFijo: "1",
    Contratados: "",
    Disponibles: "",
    genero: "MASCULINO",
  });
  // PAGINACION
  const [pagination, setpagination] = useState(null);
  // FUNCIONES STATE
  // FUNCION QUE OBTIENE LA DATA DE LA TABLA
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    try {
      const res = await clienteAxios.get(
        `/clientes/?cantidad=${cantidad}&page=${page}&search=${
          search ? search : ""
        }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
          datos ? datos : ""
        }&rucempresa=${rucempresa ? rucempresa : ""}&estado=${
          estado ? estado : ""
        }`
      );

      return res;
    } catch (error) {
      mostrarAlerta("Hubo un error");
    }
  };
  // FUNCION QUE COLOCA LA DATA EN EL STATE
  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  // AGREGA MAS REGISTROS AL STATE
  const addRowsState = async (items) => {
    setrows([...rows, ...items]);
  };
  // REMPLAZA LA CONSULTA ACTUAL POR UNA NUEVA CON OTROS PARAMETROS
  const remplazarRowsState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estadoRequest
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estadoRequest
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  // AGREGA UN REGISTRO AL STATE
  const agregarState = async (item) => {
    try {
      setrows([item, ...rows]);

      setpagination({
        ...pagination,
        numRows: pagination.numRows + 1,
      });
    } catch (error) {}
  };

  // EDITA UN REGISTRO DEL STATE
  const editarState = async (item) => {
    try {
      const resRows = rows.map((itemR) => {
        if (itemR[campoExport] === item[campoExport]) {
          return item;
        } else {
          return itemR;
        }
      });

      setrows(resRows);
    } catch (error) {}
  };
  // ELIMINA UN REGISTRO DEL STATE
  const eliminarState = async (_id) => {
    try {
      const resRows = rows.filter((row) => {
        return row[campoExport] !== _id;
      });
      setrows(resRows);
      setpagination({
        ...pagination,
        numRows: pagination.numRows - 1,
      });
    } catch (error) {}
  };
  // ELIMINA REGISTROS SELECICONADOS DEL STATE
  const eliminarSeleccionState = async (_ids) => {
    const resRows = rows.filter((row) => {
      return !_ids.some((item) => {
        return item === row[campoExport];
      });
    });
    setpagination({
      ...pagination,
      numRows: pagination.numRows - _ids.length,
    });
    setrows(resRows);
  };
  // CAMBIA EL OBJETO ACTIVO DEL STATE GENERALMENTE PARA EDITAR
  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
  };

  // FIN FUNCIONES STATE
  // FIN DATA STATE

  // FUNCIONES SOCKET
  // NOMBRE DE LA TABLA
  const [tabla] = useState("cliente");
  // ITEM DEL SOCKET SE UTILIZA COMO REFERENCIA
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });
  // REFERENCIA DEL SOCKET
  const socket = useRef();
  // USEFFECT QUE ESCUCHUA LOS EVENTOS DEL SOCKET
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  // CUANDO CAMBIA EL OBJETO DEL SOCKET SE ESCUCHA AQUI Y SE DECIDE QUE HACER CON EL
  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }
    if (!rows) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== tabla) {
      return;
    }

    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      agregarState(item);
    }
    if (tipo === "editar") {
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item[campoExport]);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);
  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    usuario: { rucempresa },
    usuario,
  } = useContext(AuthContext);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Clientes");
  const [estadoRequest, setestadoRequest] = useState("ACTIVA");
  // ABRIR MODAL
  const [open, setopen] = useState(false);
  // BOOLEANO DE SI SE ESTA BUSCANDO O NO
  const [buscando, setbuscando] = useState(false);
  // SI SE ESTA EDITANDO O NO
  const [editar, seteditar] = useState(false);
  // STRING DE BUSQUEDA
  const [search, setsearch] = useState("");
  // BOOLEANO DE SI ESTA CARGANDO
  const [cargando, setcargando] = useState(false);
  // CAMPO POR EL CUAL SE FILTRAN LOS REGISTROS PARA EXPORTAR
  const [campoExport] = useState("cliId");
  // ORDEN DE POR DEFECTO
  const [input, setinput] = useState({
    id: campoExport,
    label: "ID",
    minWidth: 150,
    tipo: "string",
    propiedad: campoExport,
  });
  // ARRAY DE EXPORTACION
  const [arrayExport, setarrayExport] = useState([]);
  // BOLLEANO DE SI ESTA ABIERTO EL DIALOG DE OPCIONES
  const [openDialogOpciones, setopenDialogOpciones] = useState(false);

  // ORDEN ASCENDENTE O DESCENDENTE

  const [orden, setorden] = useState(false);
  // CAMPOS DE BUSQUEDA EXPANDIDOS
  const [openMB, setopenMB] = useState(false);
  // OBJETO BUSCADOR
  const [fullBuscador, setfullBuscador] = useState(defaultFullBuscador());

  //pagina actual
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // EVENTO QUE CAMBIA LA DATA DEL OBJETO BUSCADOR
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  // FUNCION DE BUSQUEDA AVANZADA
  const funcionB = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };
  // FUNCION DE BUSQUEDA RAPIDA
  const handleSearch = async () => {
    setarrayExport([]);
    setcargando(true);
    setbuscando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );

    setPage(0);
    setcargando(false);
  };
  // FUNCION QUE PONE LA DATA DE POR DEFECTO O HACE UN REFRESH
  const funcionDefault = async () => {
    setcargando(true);
    const fullBRes = defaultFullBuscador();
    setfullBuscador(fullBRes);
    await remplazarRowsState(
      rowsPerPage,
      0,
      "",
      input.id,
      !orden,
      JSON.stringify(fullBRes),
      rucempresa,
      estadoRequest
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };
  const inputEl = useRef();
  const funcArchivo = async (e) => {
    if (!e.target.files[0]) {
      return;
    } else {
      if (e.target.files[0].name.split(".")[1] !== "xlsx") {
        return mostrarAlerta("El archivo no es xlsx", "error");
      } else {
        try {
          setcargando(true);
          const formData = new FormData();
          formData.append("archivo", e.target.files[0]);
          const res = await clienteAxios.post(
            "/clientes/importar_from_excel/0",
            formData
          );
          setrows(res.data.data);
          setpagination({
            current: 0,
            next: 1,
            numRows: res.data.data.length,
            perPage: 10,
          });
          setcargando(false);
          return mostrarAlerta("Importación exitosa", "success");
        } catch (error) {
          return mostrarAlerta(
            "Error Importar Revisar que todas columna este llenas",
            "error"
          );
        }
      }
    }
  };
  return (
    <Box>
      {" "}
      <input
        style={{ display: "none" }}
        type="file"
        name="myImage"
        onChange={async (e) => {
          funcArchivo(e);
        }}
        ref={inputEl}
      />
      <DialogOpciones
        open={openDialogOpciones}
        setopen={setopenDialogOpciones}
        botones={[
          {
            tooltip: "Archivo Importación",
            funcion: () => {
              window.open("https://backsafe.neitor.com/datos_clientes.xlsx");
              setopenDialogOpciones(false);
            },
            Icon: MenuBook,
            color: "success",
            id: 1,
            disabled: false,
          },
          {
            tooltip: "Importar Registros",
            funcion: () => {
              inputEl.current.click();
              setopenDialogOpciones(false);
            },
            Icon: UploadFile,
            color: "primary",
            id: 1,
            disabled: false,
          },
        ]}
        titulo={"Mas Opciones"}
      />
      <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        LabelBuscador="Buscar por todos los campos"
        funcionDefault={funcionDefault}
        ocultarMigasDePan={false}
        botones={[
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              setopen(true);
            },
            disabled: false,
            Icon: Add,
            color: "primary",
            id: 1,
            ocultar: true,
            tipo: "icono",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              if (!tienePermiso("añadir")) {
                return alertaPermiso("añadir");
              }
              setopen(true);
            },
            disabled: false,
            Icon: Add,
            color: "primary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
          {
            tooltip: "OPCIONES",
            texto: "OPCIONES",
            funcion: () => {
              setopenDialogOpciones(true);
              // window.open("https://backsafe.neitor.com/datos_personas.xlsx");
            },
            disabled: false,
            Icon: Menu,
            color: "success",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {
              ml: 1,
            },
          },        {
                      tooltip: "Busqueda avanzada",
                      texto: "",
                      funcion: () => {
                        setopenMB(true);
                      },
                      disabled: false,
                      Icon: Search,
                      color: "success",
                      id: 2,
                      ocultar: false,
                      tipo: "icono",
                      variante: "contained",
                      size: "medium",
                      sx: {
                        ml: 1,
                      },
                    },
        ]}
      />
      <ModalB
        defaultFullBuscador={defaultFullBuscador}
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={"Busqueda avanzada (Clientes)"}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        arrayProps={columns}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        funcionDefault={funcionDefault}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems=" stretch"
        width="100%"
      >
        <Box>
          {["ACTIVA", "INACTIVA"].map((itemEstado) => (
            <Button
              key={itemEstado}
              size="small"
              variant={estadoRequest === itemEstado ? "contained" : null}
              onClick={async () => {
                setcargando(true);
                await remplazarRowsState(
                  rowsPerPage,
                  0,
                  "",
                  input.id,
                  orden,
                  JSON.stringify({
                    fechaFactura1: "",
                    fechaFactura2: "",
                    fechaAutorizacion1: "",
                    fechaAutorizacion2: "",
                    compFecReg1: "",
                    compFecReg2: "",
                  }),
                  rucempresa,
                  itemEstado
                );

                setPage(0);
                setestadoRequest(itemEstado);
                setcargando(false);
              }}
              disableElevation
              style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
              color="primary"
            >
              {itemEstado}
            </Button>
          ))}
        </Box>
      </Box>
      <Divider />
      <Tabla
        //PAGINACION Y BUSQUEDA
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setopen={setopen}
        search={search}
        input={input}
        orden={orden}
        setinput={setinput}
        setorden={setorden}
        cargando={cargando}
        setcargando={setcargando}
        fullBuscador={fullBuscador}
        //ESTADO
        rows={rows}
        columns={columns}
        pagination={pagination}
        getDataState={getDataState}
        obtenerState={obtenerState}
        addRowsState={addRowsState}
        remplazarRowsState={remplazarRowsState}
        changeObjetoActivoState={changeObjetoActivoState}
        seteditar={seteditar}
        arrayExport={arrayExport}
        setarrayExport={setarrayExport}
        socket={socket}
        campoExport={campoExport}
        estadoRequest={estadoRequest}
        tabla={tabla}
      />
      <Modal
        editar={editar}
        seteditar={seteditar}
        setopen={setopen}
        open={open}
        ObjectoActivo={ObjectoActivo}
        changeObjetoActivoState={changeObjetoActivoState}
        socket={socket}
        estadoRequest={estadoRequest}
      />
    </Box>
  );
};

export default memo(Base);
