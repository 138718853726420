import React, { useReducer, memo } from "react";
import clienteAxios from "../../config/axios";
import { OBTENER, EDITAR, CHANGE_ACTIVO } from "../../types";

//context
import MenuContext from "./MenuContext";
//reducer
import MenuReducer from "./MenuReducer";
const MenuState = (props) => {
  const initialState = {
    rows: null,
    columns: [
      {
        id: "nombre",
        label: "nombre",
        minWidth: 150,
      },
      {
        id: "permisos",
        label: "permisos",
        minWidth: 150,
      },
      {
        id: "iconoIzquierda",
        label: "icono Izquierda",
        minWidth: 150,
      },
      {
        id: "iconoDerecha",
        label: "icono Derecha",
        minWidth: 150,
      },
      {
        id: "orden",
        label: "orden",
        minWidth: 50,
      },
    ],
    categoriaActiva: {
      sidId: 0,
      sidInfo: {
        orden: 1,
        mostrar: "NO",
        descripcion: "",
        path: "",
        name: "",
        icon: "",
        component: "",
        layout: "",
        tipo: "",
        ruta: false,
        expandible: true,
        permisos: ["USUARIO"],
        añadir: ["USUARIO"],
        editar: ["USUARIO"],
        ver: ["USUARIO"],
        eliminar: ["USUARIO"],
        impExp: ["USUARIO"],
        bloquearAreaDepartamento: "NO",
        tutoriales: [],
        areasDepartamento: [],
      },
    },
  };

  const [state, dispatch] = useReducer(MenuReducer, initialState);

  const obtenerCategorias = async () => {
    const res = await clienteAxios.get("/sidebar");
    console.log({ res });

    try {
      dispatch({
        type: OBTENER,
        payload: res.data.data,
      });
    } catch (error) {}
  };

  const editarCategoria = async (categoria) => {
    try {
      await clienteAxios.put(`/sidebar/${categoria.sidId}`, {
        info: categoria.sidInfo,
      });

      dispatch({
        type: EDITAR,
        payload: categoria,
      });
    } catch (error) {}
  };

  const changeCategoriaActiva = (asignatura) => {
    dispatch({
      type: CHANGE_ACTIVO,
      payload: asignatura,
    });
  };
  const getPathToredirect = (component) => {
    const itemFind = state.rows.find(
      (item) => item.sidInfo.component === component
    );
    console.log({ itemFind });
    if (itemFind.sidInfo.tipo === "categoria") {
      return itemFind.sidInfo.path;
    }
    if (itemFind.sidInfo.tipo === "subCategoria") {
      const categoria = state.rows.find(
        (item) => item.sidInfo.path === itemFind.sidInfo.categoria
      );
      return categoria.sidInfo.path + itemFind.sidInfo.path;
    }
    if (itemFind.sidInfo.tipo === "subsubCategoria") {
      // itemFind.sidInfo.subcategoria;
      // subCategoria.sidInfo.path;
      const subcategoria = state.rows.find(
        (item) => item.sidInfo.path === itemFind.sidInfo.subcategoria
      );
      const categoria = state.rows.find(
        (item) => item.sidInfo.path === subcategoria.sidInfo.categoria
      );

      return (
        itemFind.sidInfo.layout +
        categoria.sidInfo.path +
        subcategoria.sidInfo.path +
        itemFind.sidInfo.path
      );
    }

    // sidInfo.component
  };
  return (
    <MenuContext.Provider
      value={{
        //state
        rows: state.rows,
        columns: state.columns,
        categoriaActiva: state.categoriaActiva,
        // funciones
        obtenerCategorias,
        editarCategoria,
        changeCategoriaActiva,
        getPathToredirect,
      }}
    >
      {props.children}
    </MenuContext.Provider>
  );
};

export default memo(MenuState);
