import React, {
  useState,
  useContext,
  useEffect,
  memo,
  createRef,
  useMemo,
} from "react";
import Draggable from "react-draggable";
// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import clienteAxios from "../../../../config/axios";
import {
  validarDocumento,
  // validarDocumento,
  validarEmail,
} from "../../../../config/validaciones";

import { Check, Download, Map, Search } from "@mui/icons-material";

import RowPuesto from "./RowsPuesto/RowPuesto";
import RowAgregarPuesto from "./RowsPuesto/RowAgregarPuesto";
import RowBono from "./RowBono/RowBono";
import RowAgregarBono from "./RowBono/RowAgregarBono";
import {
  getMonths,
  FechaExacta,
  navegadorCompatible,
  replaceCaracteres,
  trimPropsItem,
} from "../../../../config/const";
import {
  Typography,
  Button,
  LinearProgress,
  linearProgressClasses,
  RadioGroup,
  Radio,
} from "@mui/material";
import Turnos from "./Turnos";
import Dropzone from "../../../components/Dropzone";
// MATERIAL
import {
  Modal,
  Box,
  Divider,
  IconButton,
  FormControlLabel,
  Switch,
  TextField,
  MenuItem,
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  Input,
  Chip,
  useTheme,
  Avatar,
  Tooltip,
  Paper,
  Table,
  TableContainer,
  TablePagination,
  TableBody,
  TableHead,
  // Slide,
  // Grow,
  Autocomplete,
} from "@mui/material";
import {
  AddCircle,
  Cancel,
  Delete,
  CloudUpload,
  // CloudDownload,
} from "@mui/icons-material";
import DialogMap from "../../../components/DialogMap";
import TablaCabecera from "../../../components/TablaCabecera";
import ToolBarTable from "../../../components/ToolBarTable";
import { useModalStyle } from "../../../styles/styleModal";
import { objectDefault } from "../data";
import useMultimediasUpload from "../../../hooks/useMultimediasUpload";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import RowAgregarDescuento from "./Descuento/RowAgregarDescuento";
import RowDescuento from "./Descuento/RowDescuento";
import CamposAcumulables from "../../../components/Campos/CamposAcumulables";
import Vinculacion from "./Vinculacion/Vinculacion";

import iess from "../../../../assets/img/iess.jpg";
import sicosep from "../../../../assets/img/sicosep.png";
import sut from "../../../../assets/img/sut.png";
import { FormLabel } from "@material-ui/core";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

export const ModalPersonas = (props) => {
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    estadoRequest,
    fromAvisoSalida,
  } = props;
  const [openMap, setopenMap] = useState(false);
  const [clientesDataLaborales, setclientesDataLaborales] = useState([]);
  const [clientesData, setclientesData] = useState([]);
  const [clienteProveedor, setclienteProveedor] = useState("GUARDIAS");
  const [bancos, setbancos] = useState(null);
  const [lugaresTrabajoData, setlugaresTrabajoData] = useState(null);
  const [ciudadesData, setciudadesData] = useState(null);
  const [documentosArray, setdocumentosArray] = useState([]);
  const [documentosObject, setdocumentosObject] = useState({});
  const [objetoReferencias, setobjetoReferencias] = useState({});
  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const [agregandoBodega, setagregandoBodega] = useState(false);
  const [agregandoDescuento, setagregandoDescuento] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [archivoFoto, setarchivoFoto] = useState("");
  const [imagenMostradaFoto, setimagenMostradaFoto] = useState(null);
  const [archivoCarnet, setarchivoCarnet] = useState("");
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();
  const [error, seterror] = useState({});
  const [modalStyle] = useState(getModalStyle);
  const [seccionActive, setseccionActive] = useState({
    DatosGenerales: false,
    PuestoServicio: false,

    DatosLaborales: true,
    HorarioIncapaciad: false,
    Bonos: false,
    TurnosSeccion: false,
    FotografiasSeccion: false,
  });

  const SumaTExperiencia = () => {
    return otrosDatosLaborales.filter((item) => item.tipo === "EXPERIENCIA")
      .length === 0
      ? "0 "
      : otrosDatosLaborales
          .filter((item) => item.tipo === "EXPERIENCIA")
          .map((item) => {
            if (item.desde === "" || item.hasta === "") {
              return 0;
            }
            const optionsDesde = item.desde.split("-");
            const añoDesde = Number(optionsDesde[0]) * 12;
            const mesesDesde = Number(optionsDesde[1]);
            const TotalDesde = añoDesde + mesesDesde;

            const optionsHasta = item.hasta.split("-");
            const añoHasta = Number(optionsHasta[0]) * 12;
            const mesesHasta = Number(optionsHasta[1]) + 1;
            const TotalHasta = añoHasta + mesesHasta;

            return Number(TotalHasta - TotalDesde);
          })
          .reduce(reducer) / 12;
  };
  const defaultActive = () => {
    setdocSearch("");
    changeObjetoActivoState(objectDefault);
    setopen(false);
    seteditar(false);
    seterror({});
    setseccionActive({
      DatosGenerales: false,
      PuestoServicio: false,
      DatosLaborales: true,
      HorarioIncapaciad: false,
      Bonos: false,
      TurnosSeccion: false,
    });
    setseccionActiveDatosL({
      DatosPrincipales: false,
      TurnosLaborales: true,
    });
    setcargando(false);
    setarchivoCarnet("");
    setarchivoFoto("");
    setimagenMostradaFoto(null);
    setobjetoReferencias(null);
    setdocumentosObject(null);
    setclientesData([]);
    setimagenes([]);
    setmodificoImagenes(false);
    setopenConfirmDialog(false);
  };
  const submit = () => {
    if (true) {
      return false;
    } else {
      return true;
    }
  };
  const sinValidacion = useMemo(
    () =>
      editar &&
      (ObjectoActivo.trabaja !== item.trabaja ||
        ObjectoActivo.perEstado !== item.perEstado),
    [item, ObjectoActivo, editar]
  );
  const validacion = () => {
    if (sinValidacion) {
      return false;
    }

    if (
      turno_horario.length === 0 ||
      item.turno_horario.some((item) => {
        return (
          item.clienteIngreso === "" ||
          item.idClienteIngreso === "" ||
          item.docClienteIngreso === "" ||
          item.puestoIngreso === "" ||
          item.clienteSalida === "" ||
          item.idClienteSalida === "" ||
          item.docClienteSalida === "" ||
          item.puestoSalida === "" ||
          item.turno === "" ||
          item.ingreso === "" ||
          item.salida === "" ||
          item.fechas.length === 0 ||
          item.fechasConsultaDB.length === 0
        );
      })
    ) {
      mostrarAlerta("Faltan datos obligatorios por llenar", "error");
      seterror({
        turno_horario:
          turno_horario.length === 0 ||
          item.turno_horario.some((item) => {
            return (
              item.clienteIngreso === "" ||
              item.idClienteIngreso === "" ||
              item.docClienteIngreso === "" ||
              item.puestoIngreso === "" ||
              item.clienteSalida === "" ||
              item.idClienteSalida === "" ||
              item.docClienteSalida === "" ||
              item.puestoSalida === "" ||
              item.turno === "" ||
              item.ingreso === "" ||
              item.salida === "" ||
              item.fechas.length === 0 ||
              item.fechasConsultaDB.length === 0
            );
          })
            ? true
            : false,
      });
      return true;
    } else {
      return false;
    }
  };

  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);

  const focusInputDocumento = (itemPropiedad) => {
    objetoReferencias[itemPropiedad.propiedad].current.click();
  };
  const onChangeDocumento = async (e, itempropiedad) => {
    if (!e.target.files[0]) {
      return;
    }
    if (itempropiedad.extension === "PDF") {
      if (
        !["pdf"].some((item) => item === e.target.files[0].type.split("/")[1])
      ) {
        return mostrarAlerta("El archivo debe ser PDF", "error");
      }
    }
    if (itempropiedad.extension === "IMAGEN") {
      if (
        !["png", "jpg", "jpeg"].some(
          (item) => item === e.target.files[0].type.split("/")[1]
        )
      ) {
        return mostrarAlerta("Solo archivos png, jpg o jpeg", "error");
      }
    }
    try {
      setcargando(true);
      const formDataLogos = new FormData();
      let arrayEliminar = [];

      if (documentos[itempropiedad.propiedad] === "") {
        await formDataLogos.append("archivo", e.target.files[0]);
      } else {
        arrayEliminar.push(documentos[itempropiedad.propiedad]);
        await formDataLogos.append("archivo", e.target.files[0]);
      }

      formDataLogos.append("carpeta", "documentos");

      const resDocumentos = await clienteAxios.post(
        "/subir_eliminar_archivo/subir",
        formDataLogos
      );

      const resEliminados = await clienteAxios.post(
        "/subir_eliminar_archivo/eliminar",
        { urls: arrayEliminar.filter((itemE) => itemE !== "") }
      );
      setitem({
        ...item,
        documentos: {
          ...item.documentos,
          [itempropiedad.propiedad]: resDocumentos.data.url,
        },
      });

      setdocumentosObject({
        ...documentosObject,
        [itempropiedad.propiedad]: e.target.files[0],
      });
      setcargando(false);
    } catch (error) {
      console.log({ error });

      setcargando(false);
      setdocumentosObject({
        ...documentosObject,
        [itempropiedad.propiedad]: documentosObject[itempropiedad.propiedad],
      });

      return mostrarAlerta("Hubo un error", "error");
    }
  };
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  //props

  // eslint-disable-next-line

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // eslint-disable-next-line

  //foto

  const onChangeFileFoto = (e) => {
    if (!e.target.files[0]) {
      setimagenMostradaFoto(null);
      return setarchivoFoto("");
    }

    if (
      !["png", "jpg", "jpeg"].some(
        (item) => item === e.target.files[0].type.split("/")[1]
      )
    ) {
      setimagenMostradaFoto(null);
      setarchivoFoto("");
      return mostrarAlerta("Solo archivos png, jpg o jpeg", "error");
    }

    setarchivoFoto(e.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e) => {
      e.preventDefault();
      setimagenMostradaFoto(e.target.result);
    };
  };
  //Carnet

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStylesEspecialidades(name, permisos, theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor: permisos.indexOf(name) === -1 ? "white" : "#31b2f7",
    };
  }

  const theme = useTheme();
  const [docSearch, setdocSearch] = useState("");
  const [cargandoDoc, setcargandoDoc] = useState(false);
  useEffect(() => {
    setclienteProveedor(estadoRequest);

    const getData = async () => {
      try {
        if (editar) {
          setdocSearch(ObjectoActivo.perDocNumero);

          setitem({
            ...ObjectoActivo,
          });
        } else {
          if (estadoRequest === "SUPERVISORES") {
            setdocSearch(ObjectoActivo.perDocNumero);
            setitem({
              ...ObjectoActivo,
              perCargo: "SUPERVISOR",
              perAreaDepartamento: "OPERATIVO-S",
            });
          } else if (estadoRequest === "ADMINISTRACIÓN") {
            console.log(ObjectoActivo.perDocNumero);
            setdocSearch(ObjectoActivo.perDocNumero);
            setitem({
              ...ObjectoActivo,
              perCargo: "ADMINISTRACION",
            });
          } else if (estadoRequest === "GUARDIAS") {
            setdocSearch(ObjectoActivo.perDocNumero);
            setitem({
              ...ObjectoActivo,
              perCargo: "GUARDIA",
              perAreaDepartamento: "OPERATIVO-G",
            });
          }
        }
      } catch (error) {
        if (open) {
          mostrarAlerta(
            "NO HAY ARCHIVOS DINÁMICOS, REVISAR LA CONFIGURACIÓN DE DOCUMENTOS",
            "error"
          );
        }
        setdocumentosArray([]);
        setdocumentosObject({});
        setobjetoReferencias({});
        setdocSearch(ObjectoActivo.perDocNumero);
        if (estadoRequest === "SUPERVISORES") {
          setitem({
            ...ObjectoActivo,
            perCargo: "SUPERVISOR",
            perAreaDepartamento: "OPERATIVO-S",
          });
        } else if (estadoRequest === "ADMINISTRACIÓN") {
          setitem({
            ...ObjectoActivo,
            perCargo: "ADMINISTRACION",
          });
        } else if (estadoRequest === "GUARDIAS") {
          setitem({
            ...ObjectoActivo,
            perCargo: "GUARDIA",
            perAreaDepartamento: "OPERATIVO-G",
          });
        } else {
          setitem({
            ...ObjectoActivo,
          });
        }
      }
    };
    getData();
    // eslint-disable-next-line
  }, [ObjectoActivo, open]);

  useEffect(() => {
    if (!bancos || lugaresTrabajoData) {
      try {
        const getData = async () => {
          const resBancos = await clienteAxios.get(`/bancos/filtro/0`);

          setbancos(resBancos.data.data);

          const resLugaresTrabajo = await clienteAxios.get(
            `/lugartrabajo/filtro/0`
          );

          setlugaresTrabajoData(resLugaresTrabajo.data.data);
          const resCiudades = await clienteAxios.get(`/ciudades/filtro/0`);

          setciudadesData(resCiudades.data.data);
        };
        getData();
      } catch (error) {}
    } // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  const {
    trabaja,
    perCargaFamiliar,
    // perPerfil,
    // perDocTipo,
    perDocNumero,
    perNombres,
    perApellidos,
    perEstadoCivil,
    perTelefono,
    // perCelular,

    perDireccion,
    perFecNacimiento,
    // perNumContrato,
    perTipoSangre,
    perEmail,
    perFoto,
    otrosDatosLaborales,
    perFecVinculacion,
    perFormaPago,
    perRemuneracion,
    perLugarTrabajo,
    perAreaDepartamento,
    perPuestoServicio,
    perTipoCuenta,
    perBancoRemuneracion,
    perFecRetiro,
    perBonos,
    descuentos,
    perEstado,
    turno_horario,
    perCargo,
    perTipoContrato,
    perLicencias,
    perCtaBancaria,
    // perEmpresa,
    // perPais,
    // perProvincia,
    // perCanton,
    perObservacion,
    // perUser,
    // perFecReg,
    // errorAutorizacion,
    //foto
    perFotoCarnet,
    //docs
    documentos,
    perCiudad,
    // perIdCliente,
    perDocuCliente,
    perNombreCliente,
    perGps,
    perFotografiasInstalaciones,
    // GOP
    // perIdClienteGop,
    perDocuClienteGop,
    perNombreClienteGop,
    perReferencia,
    genero,
  } = item;
  // eslint-disable-next-line

  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);

    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal

  const {
    DatosGenerales,
    DatosLaborales,
    PuestoServicio,
    HorarioIncapaciad,
    Bonos,
    FotografiasSeccion,
  } = seccionActive;

  const [seccionActiveDatosL, setseccionActiveDatosL] = useState({
    DatosPrincipalesLaborales: false,
    TurnosLaborales: true,
    OtrosLaborales: false,
    VinculacionSeccion: false,
  });
  const {
    DatosPrincipalesLaborales,
    TurnosLaborales,
    OtrosLaborales,
    VinculacionSeccion,
  } = seccionActiveDatosL;

  // eslint-disable-next-line
  const handleCrear = async () => {
    try {
      await validarDocumento(perDocNumero);

      // mostrarAlerta("Identificación válida", "success");
    } catch (error) {
      console.log({ error });
      mostrarAlerta(error, "error");
      return seterror({
        ...error,
        perDocNumero: true,
      });
    }
    console.log("hola");

    if (validacion()) {
      return;
    }

    try {
      setcargando(true);
      const obligatorios = documentosArray.filter(
        (documentoItem) => documentoItem.obligatorio === "SI"
      );
      obligatorios.map((obligatorioItem) => {
        if (documentos[obligatorioItem.propiedad] === "") {
          return {
            ...obligatorioItem,
            error: true,
          };
        } else {
          return {
            ...obligatorioItem,
            error: false,
          };
        }
      });
      const existeSinLlenar = obligatorios.some((obligatorioItem) => {
        return documentos[obligatorioItem.propiedad] === "";
      });
      if (existeSinLlenar) {
        const llenos = documentosArray.map((obligatorioItem) => {
          if (
            documentos[obligatorioItem.propiedad] === "" &&
            obligatorioItem.obligatorio === "SI"
          ) {
            return {
              ...obligatorioItem,
              error: true,
            };
          } else {
            return {
              ...obligatorioItem,
              error: false,
            };
          }
        });

        setcargando(false);
        return setdocumentosArray(llenos);
      }

      const formDataLogosFoto = new FormData();
      let arrayEliminarFoto = item.perFoto && archivoFoto ? [perFoto] : [];

      await formDataLogosFoto.append("archivo", archivoFoto);
      formDataLogosFoto.append("carpeta", "documentos");

      const resDocumentosFoto = archivoFoto
        ? await clienteAxios.post(
            "/subir_eliminar_archivo/subir",
            formDataLogosFoto
          )
        : null;

      await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
        urls: arrayEliminarFoto.filter((itemE) => itemE !== ""),
      });

      const formDataCarnet = new FormData();
      let arrayEliminarCarnet = item.perFotoCarnet ? [perFotoCarnet] : [];

      await formDataCarnet.append("archivo", archivoCarnet);
      formDataCarnet.append("carpeta", "documentos");

      const resDocumentosCarnet = archivoCarnet
        ? await clienteAxios.post(
            "/subir_eliminar_archivo/subir",
            formDataCarnet
          )
        : null;

      await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
        urls: arrayEliminarCarnet.filter((itemE) => itemE !== ""),
      });

      const fotos = {
        perFoto: resDocumentosFoto?.data?.url
          ? resDocumentosFoto.data.url
          : perFoto,
        perFotoCarnet: resDocumentosCarnet?.data?.url
          ? resDocumentosCarnet.data.url
          : perFotoCarnet,
      };
      const resFotografias = await subirImagenes(
        imagenes,
        perFotografiasInstalaciones
      );
      if (item.perId) {
        if (!fotos.perFoto) {
          setcargando(false);

          setopenConfirmDialog(false);
          setseccionActive({ FotografiasSeccion: true });
          return mostrarAlerta("FOTO DE PERFIL REQUERIDA", "error");
        }
        if (resFotografias.length === 0) {
          setcargando(false);

          setopenConfirmDialog(false);
          setseccionActive({ FotografiasSeccion: true });
          return mostrarAlerta("FOTO DE INSTALACIONES REQUERIDA", "error");
        }
        const itemEnv = trimPropsItem({
          avisoSalida: fromAvisoSalida,
          dataAntiguo: {
            perIdCliente: ObjectoActivo.perIdCliente,
            perDocuCliente: ObjectoActivo.perDocuCliente,
            perNombreCliente: ObjectoActivo.perNombreCliente,
            perEstado: ObjectoActivo.perEstado,
          },
          ObjectoAntiguo: ObjectoActivo.turno_horario,
          ...item,
          perFotografiasInstalaciones: resFotografias,
          //fotos
          ...fotos,
          //params
          perPerfil:
            clienteProveedor === "GUARDIAS"
              ? [...new Set([...item.perPerfil, "GUARDIA"])]
              : clienteProveedor === "SUPERVISORES"
              ? [...new Set([...item.perPerfil, "SUPERVISOR"])]
              : [...new Set([...item.perPerfil, "ADMINISTRACION"])],
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          perUser: usuario.usuario,
          perEmpresa: [...new Set([...item.perEmpresa, usuario.rucempresa])],
          tabla: "horarios_y_turnos",
        });
        socket.current.emit("client:actualizarData", itemEnv);
      } else {
        if (!fotos.perFoto) {
          setcargando(false);

          setopenConfirmDialog(false);
          setseccionActive({ FotografiasSeccion: true });
          return mostrarAlerta("FOTO DE PERFIL REQUERIDA", "error");
        }
        if (resFotografias.length === 0) {
          setcargando(false);

          setopenConfirmDialog(false);
          setseccionActive({ FotografiasSeccion: true });
          return mostrarAlerta("FOTO DE INSTALACIONES REQUERIDA", "error");
        }
        const itemEnv = trimPropsItem({
          ...item,
          avisoSalida: fromAvisoSalida,
          //fotos
          perFotografiasInstalaciones: resFotografias,
          ...fotos,
          //params
          perPerfil:
            clienteProveedor === "GUARDIAS"
              ? [...new Set([...item.perPerfil, "GUARDIA"])]
              : clienteProveedor === "SUPERVISORES"
              ? [...new Set([...item.perPerfil, "SUPERVISOR"])]
              : [...new Set([...item.perPerfil, "ADMINISTRACION"])],
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          perUser: usuario.usuario,
          perEmpresa: [...new Set([...item.perEmpresa, usuario.rucempresa])],
          tabla: "horarios_y_turnos",
        });

        socket.current.emit("client:guardarData", itemEnv);
      }

      setcargando(false);
      return defaultActive();
    } catch (error) {
      console.log({ error });
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    // try {
    //   await validarDocumento(perDocNumero);
    //   // mostrarAlerta("Identificación válida", "success");
    // } catch (error) {
    //   mostrarAlerta(error, "error");
    //   return seterror({
    //     ...error,
    //     perDocNumero: true,
    //   });
    // }
    // console.log("hola");

    if (validacion()) {
      return;
    }

    try {
      setcargando(true);
      // const obligatorios = documentosArray.filter(
      //   (documentoItem) => documentoItem.obligatorio === "SI"
      // );

      // obligatorios.map((obligatorioItem) => {
      //   if (documentos[obligatorioItem.propiedad] === "") {
      //     return {
      //       ...obligatorioItem,
      //       error: true,
      //     };
      //   } else {
      //     return {
      //       ...obligatorioItem,
      //       error: false,
      //     };
      //   }
      // });

      // const existeSinLlenar = obligatorios.some((obligatorioItem) => {
      //   return documentos[obligatorioItem.propiedad] === "";
      // });

      // if (existeSinLlenar) {
      //   const llenos = documentosArray.map((obligatorioItem) => {
      //     if (
      //       documentos[obligatorioItem.propiedad] === "" &&
      //       obligatorioItem.obligatorio === "SI"
      //     ) {
      //       return {
      //         ...obligatorioItem,
      //         error: true,
      //       };
      //     } else {
      //       return {
      //         ...obligatorioItem,
      //         error: false,
      //       };
      //     }
      //   });

      //   mostrarAlerta("Faltan documentos por subir.", "error");
      //   setcargando(false);
      //   return setdocumentosArray(llenos);
      // }

      // const formDataLogosFoto = new FormData();
      // let arrayEliminarFoto = item.perFoto && archivoFoto ? [perFoto] : [];

      // await formDataLogosFoto.append("archivo", archivoFoto);
      // formDataLogosFoto.append("carpeta", "documentos");

      // const resDocumentosFoto = archivoFoto
      //   ? await clienteAxios.post(
      //       "/subir_eliminar_archivo/subir",
      //       formDataLogosFoto
      //     )
      //   : null;

      // await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
      //   urls: arrayEliminarFoto.filter((itemE) => itemE !== ""),
      // });

      // const formDataCarnet = new FormData();
      // let arrayEliminarCarnet = item.perFotoCarnet ? [perFotoCarnet] : [];

      // await formDataCarnet.append("archivo", archivoCarnet);
      // formDataCarnet.append("carpeta", "documentos");

      // const resDocumentosCarnet = archivoCarnet
      //   ? await clienteAxios.post(
      //       "/subir_eliminar_archivo/subir",
      //       formDataCarnet
      //     )
      //   : null;

      // await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
      //   urls: arrayEliminarCarnet.filter((itemE) => itemE !== ""),
      // });

      // const fotos = {
      //   perFoto: resDocumentosFoto?.data?.url
      //     ? resDocumentosFoto.data.url
      //     : perFoto,
      //   perFotoCarnet: resDocumentosCarnet?.data?.url
      //     ? resDocumentosCarnet.data.url
      //     : perFotoCarnet,
      // };
      // console.log({ fotos });

      // const resFotografias = await subirImagenes(
      //   imagenes,
      //   perFotografiasInstalaciones
      // );

      // if (!fotos.perFoto && !sinValidacion) {
      //   setcargando(false);

      //   setopenConfirmDialog(false);
      //   setseccionActive({ FotografiasSeccion: true });
      //   return mostrarAlerta("FOTO DE PERFIL REQUERIDA", "error");
      // }
      // if (resFotografias.length === 0 && !sinValidacion) {
      //   setcargando(false);

      //   setopenConfirmDialog(false);
      //   setseccionActive({ FotografiasSeccion: true });
      //   return mostrarAlerta("FOTO DE INSTALACIONES REQUERIDA", "error");
      // }
      const itemEnv = trimPropsItem({
        ...item,

        //params
        horario_antiguo: ObjectoActivo.turno_horario,
        rol: usuario.rol,
        rucempresa: usuario.rucempresa,
        tabla: "horarios_y_turnos",
      });
      console.log({ itemEnv });
      socket.current.emit("client:actualizarData", itemEnv);
      setcargando(false);
      return defaultActive();
    } catch (error) {
      console.log({ error });
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  // eslint-disable-next-line

  if (!open) {
    return null;
  }

  if (!bancos) {
    return null;
  }
  if (!ciudadesData) {
    return null;
  }
  if (!lugaresTrabajoData) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <ConfirmacionDialog
          open={openConfirmDialog}
          setopen={setopenConfirmDialog}
          categoria="warning"
          titulo={`${
            cargando
              ? "Cargando: "
              : `¿Esta seguro de ${
                  editar ? "editar" : "crear"
                } un usuario con este rol: `
          } ${
            clienteProveedor === "GUARDIAS"
              ? "Guardia"
              : clienteProveedor === "SUPERVISORES"
              ? "Supervisor"
              : clienteProveedor === "ADMINISTRACIÓN"
              ? "Administración"
              : ""
          } ${cargando ? "" : "?"}`}
          botones={[
            {
              tooltip: `Se  ${editar ? "editara" : "creara"} el registro`,
              texto: "SI",
              funcion: () => {
                if (editar) {
                  handleEditar();
                } else {
                  handleCrear();
                }
              },
              disabled: cargando,
              Icon: Check,
              color: "success",
              id: 1,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: { marginRight: ".3rem" },
            },
            {
              tooltip: "Cancelar",
              texto: "NO",
              funcion: () => {
                setopenConfirmDialog(false);
              },
              disabled: cargando,
              Icon: Cancel,
              color: "error",
              id: 2,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: {},
            },
          ]}
        />
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar " : "Agregar "}
                  {clienteProveedor === "GUARDIAS"
                    ? "Guardia"
                    : clienteProveedor === "SUPERVISORES"
                    ? "Supervisor"
                    : clienteProveedor === "ADMINISTRACIÓN"
                    ? "Administración"
                    : ""}
                  .
                </Typography>
                <Typography variant="h9" color="initial" align="center">
                  {item.perId} ---{" "}
                  {`${item.perDocNumero} ${item.perApellidos} ${item.perNombres}`}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box display="flex" flexWrap={"wrap"}>
                  {/* <Button
                    className={clases.buttonTab}
                    variant={DatosLaborales ? "contained" : "outlined"}
                    disableElevation
                    color={
                      error.perFecVinculacion ||
                      error.perLugarTrabajo ||
                      error.perBancoRemuneracion ||
                      error.turno_horario ||
                      error.otrosDatosLaborales
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ DatosLaborales: true })}
                  >
                    Datos Laborales
                  </Button> */}
                </Box>
                <Box>
                  {/* <FormControlLabel
                    sx={{ mr: 2 }}
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={trabaja === 1 ? true : false}
                        onChange={(e) => {
                          setitem({
                            ...item,
                            trabaja: e.target.checked ? 1 : 0,
                          });
                        }}
                        name="antoine"
                        color="secondary"
                      />
                    }
                    label="Trabaja"
                  />
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={
                          perEstado === "ACTIVO" || perEstado === "ACTIVA"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setitem({
                            ...item,
                            perEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                          });
                        }}
                        name="antoine"
                        color="secondary"
                      />
                    }
                    label="Estado"
                  /> */}
                </Box>
              </Box>
              <Divider />
              <Box className={clases.container}>
                {DatosLaborales ? (
                  <>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems=" stretch"
                      width="100%"
                    >
                      <Box>
                        <Button
                          variant={TurnosLaborales ? "contained" : "outlined"}
                          disableElevation
                          style={{
                            padding: "8px",
                            borderRadius: "20px 20px 0px 0px",
                          }}
                          turno_horario
                          color={error.turno_horario ? "error" : "primary"}
                          onClick={() =>
                            setseccionActiveDatosL({
                              TurnosLaborales: true,
                            })
                          }
                        >
                          Horarios y turnos
                        </Button>
                      </Box>
                    </Box>
                    <Divider style={{ width: "100%" }} />

                    <>
                      {TurnosLaborales ? (
                        <Turnos
                          estadoRequest={estadoRequest}
                          item={item}
                          setitem={setitem}
                          error={error}
                        />
                      ) : null}
                    </>
                  </>
                ) : null}
              </Box>
              {cargando && <LinearProgress />}
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box
                    component={"img"}
                    sx={{ width: "85px", cursor: "pointer" }}
                    onClick={() => {
                      window.open(
                        "https://www.iess.gob.ec/empleador-web/pages/principal.jsf"
                      );
                    }}
                    src={iess}
                    alt="iess"
                  />
                  <Box
                    component={"img"}
                    sx={{ width: "85px", cursor: "pointer" }}
                    onClick={() => {
                      window.open(
                        "https://sicosep.ministeriodelinterior.gob.ec/empresas/mdi/mdig/mdi1"
                      );
                    }}
                    src={sicosep}
                    alt="sicosep"
                  />
                  <Box
                    component={"img"}
                    sx={{ width: "85px", cursor: "pointer" }}
                    onClick={() => {
                      window.open(
                        "https://sut.trabajo.gob.ec/mrl/loginContratos.xhtml"
                      );
                    }}
                    src={sut}
                    alt="sut"
                  />
                </Box>
                <Typography
                  variant="h9"
                  color="initial"
                  align="center"
                  width={"100%"}
                >
                  {item.perDocuCliente} {item.perNombreCliente}
                </Typography>
                <Box
                  width="50%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={submit() || cargando}
                    onClick={() => {
                      setopenConfirmDialog(true);
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalPersonas);
