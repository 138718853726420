import React, { memo, useContext } from "react";

import { TableCell, TableRow, Typography } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../components/ButtonsAction";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import {
  Cancel,
  CopyAll,
  Create,
  Delete,
  DeleteForever,
  Email,
  MenuOpen,
  PictureAsPdf,
  QrCode,
  ReadMore,
  Restore,
} from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import { trimPropsItem } from "../../../../../config/const";
import clienteAxios from "../../../../../config/axios";
import ModalEmail from "./ModalEmail";
import ModalQR from "./ModalQR";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    // HOOK DE PERMISO
    tienePermiso,
    alertaPermiso,
    // ROW
    index,
    rowSubHijo,
    // rowSubHijo,
    rowPadre,
    // OPEN Y EDIT
    setopen,
    seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // FUNCION STATE
    changeObjetoActivoState,
    // SOCKET
    socket,
    tabla,
    // ESTADO,
    estadoRequest,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowSubHijo.editado === true
      ? classes.celdaEditado
      : rowSubHijo.nuevo === true
      ? classes.celdaNuevo
      : rowSubHijo.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;

  const [openModalEmail, setopenModalEmail] = useState(false);
  // QR
  const [itemQr, setitemQr] = useState({
    invld: "",
    rucempresa: "",
    hoja: "A4",
    cantidad: "1",
    ancho: "95",
    alto: "95",
  });
  const [expandido, setExpandido] = useState(false);
  const [openQr, setopenQr] = useState(false);
  // CONTEXT
  const [TextExpandible, setTextExpandible] = useState({});
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      console.log({
        tabla,
        [campoExport]: rowSubHijo[campoExport],
        rucempresa: usuario.rucempresa,
        distri_FecUpd: rowSubHijo.distri_FecUpd,
        distri_IdCliente: rowSubHijo.distri_IdCliente,
      });

      socket.current.emit("client:eliminarData", {
        tabla,
        [campoExport]: rowSubHijo[campoExport],
        rucempresa: usuario.rucempresa,
        distri_FecUpd: rowSubHijo.distri_FecUpd,
        distri_IdCliente: rowSubHijo.distri_IdCliente,
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...rowSubHijo,
          nombre: usuario.nombre,
          tabla,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          rolpUser: usuario.usuario + "/+/" + rowSubHijo[campoExport],
          rolpEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {}
  };
  if (rowSubHijo.eliminado === true) {
    return null;
  }
  return (
    <React.Fragment>
      <ModalEmail
        open={openModalEmail}
        setopen={setopenModalEmail}
        row={rowSubHijo}
        estadoRequest={estadoRequest}
      />
      <ModalQR
        item={itemQr}
        setitem={setitemQr}
        open={openQr}
        setopen={setopenQr}
      />
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={
          rowSubHijo.distri_IdsDescuento.length > 0
            ? `¿Esta seguro de eliminar este registro?, tiene descuentos asociados los cuales quedarán ANULADOS`
            : `¿Esta seguro de eliminar este registro?`
        }
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={rowSubHijo}
          agrupado={true}
          rowPadre={rowPadre}
          botones={[
            // {
            //   tooltip: expandido ? "Contraer" : "Expandir",
            //   texto: "",
            //   funcion: () => {
            //     setExpandido(!expandido);
            //   },
            //   disabled: false,
            //   Icon: expandido ? MenuOpen : ReadMore,
            //   color: "primary",
            //   id: 2,
            //   ocultar: rowSubHijo.eliminado,
            //   tipo: "icono",
            //   variante: "contained",
            //   size: "small",
            //   sx: butonIconTransition,
            // },
            {
              tooltip: "PDF",
              texto: "",
              funcion: () => {
                window.open(
                  `${
                    clienteAxios.defaults.baseURL
                  }reportes/dotacion_descuento_individual?distri_Id=${
                    rowSubHijo.distri_Id
                  }&rucempresa=${usuario.rucempresa}&usuario=${
                    usuario.usuario
                  }&nombre=${usuario.nombre}&devolucion=${estadoRequest ===
                    "DEVOLUCION"}`
                );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 2,
              ocultar: rowSubHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },

            {
              tooltip: "Correo",
              texto: "",
              funcion: () => {
                setopenModalEmail(true);
              },
              disabled: false,
              Icon: Email,
              color: "secondary",
              id: 2,
              ocultar: rowSubHijo.eliminado,
              // !(
              //   estadoRequest === "DISTRIBUCION" || estadoRequest === "PEDIDO"
              // ) ||
              // !expandButtons,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "QR",
              texto: "",
              funcion: () => {
                setitemQr({
                  ...itemQr,
                  distri_Id: rowSubHijo.distri_Id,
                  // rucempresa: rowSubHijo.//disEmpresa,
                });
                setopenQr(true);
              },
              disabled: false,
              Icon: QrCode,
              color: "primary",
              ocultar: rowSubHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Copiar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                setopen(true);
                changeObjetoActivoState(rowSubHijo);
              },
              disabled: false,
              Icon: CopyAll,
              color: "primary",
              id: 2,
              ocultar: rowSubHijo.eliminado,
              // !expandButtons,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                setopen(true);
                seteditar(true);
                changeObjetoActivoState(rowSubHijo);
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: rowSubHijo.eliminado,
              // !expandButtons,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }

                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: Delete,
              color: "error",
              id: 2,
              ocultar: rowSubHijo.eliminado || estadoRequest === "DEVOLUCION",
              // !expandButtons,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // sx: butonIconTransition,
            },
          ]}
        />

        <>
          {" "}
          <TableCell size="small" align="center" className={claseCelda}>
            {rowSubHijo.distri_PuestoCliente}
          </TableCell>
          <TableCell
            size="small"
            align="center"
            className={claseCelda}
            onDoubleClick={() => {
              setTextExpandible({
                ...TextExpandible,
                distri_Personas: !TextExpandible["distri_Personas"],
              });
            }}
            sx={{ cursor: "pointer" }}
          >
            {rowSubHijo.disTipoDotacion === "PUESTO" ? (
              rowSubHijo.disPuestoCliente
            ) : TextExpandible["distri_Personas"] ? (
              [...rowSubHijo.distri_Personas].map((itemPersona, index) => {
                if (itemPersona.docnumero) {
                  return (
                    <Typography
                      color="initial"
                      sx={{
                        lineHeight: 1,
                        fontSize: ".7rem",
                        marginBottom: ".5rem",
                      }}
                    >
                      {index + 1 + " - "}
                      {itemPersona.nombres + " " + itemPersona.docnumero}
                    </Typography>
                  );
                } else {
                  return (
                    <Typography
                      color="initial"
                      sx={{
                        lineHeight: 1,
                        fontSize: ".7rem",
                        marginBottom: ".5rem",
                      }}
                    >
                      {index + 1 + " - "}
                      {itemPersona.perDocNumero +
                        " " +
                        itemPersona.perNombres +
                        " " +
                        itemPersona.perApellidos}
                    </Typography>
                  );
                }
              })
            ) : (
              <>
                {[...rowSubHijo.distri_Personas]
                  .map((itemPersona) => {
                    if (itemPersona.docnumero) {
                      return itemPersona.nombres + " " + itemPersona.docnumero;
                    } else {
                      return (
                        itemPersona.perApellidos +
                        " " +
                        itemPersona.perNombres +
                        " " +
                        itemPersona.perDocNumero
                      );
                    }
                  })
                  .join(" - ")
                  .substring(0, 30)}
                <Typography
                  component={"span"}
                  sx={{ fontSize: ".7rem", ml: 1, fontWeight: "bold" }}
                >
                  Ver mas
                </Typography>
              </>
            )}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowSubHijo.distri_Entrega}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowSubHijo.distri_TipoDotacion}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {" "}
            {new Date(rowSubHijo.distri_FecUpd).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowSubHijo.distri_User}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
