import { Dashboard as DashboardIcon } from "@mui/icons-material";

//Dashboard
//empresa
//MENU
import _Empresa from "./views/ArchivosCards/_Empresa/_Empresa";
import ActaEntregaRecepcion from "./views/Personas/ActaEntregaRecepcion/ActaEntregaRecepcion";
import Actividades from "./views/Configuración/Actividades/Actividades";
import ActividadesAsignadas from "./views/Configuración/ActividadesAsignadas/ActividadesAsignadas";
import ActividadesCabecera from "./views/ActividadesCabecera/ActividadesCabecera";
import ActividadesOperativas from "./views/ArchivosCards/ActividadesOperativas/ActividadesOperativas";
import Anticipos from "./views/Anticipos/Anticipos";
import Vacaciones from "./views/Vacaciones/Vacaciones";
import Aportes from "./views/Configuración/Aportes/Aportes";
import Asistencias from "./views/Asistencias/Asistencias";
import Ausencia from "./views/Ausencia/Ausencia";
import Avisos from "./views/Personas/Avisos/Avisos";
import AvisoSalida from "./views/AvisoSalida/AvisoSalida";
import InventarioFactura from "./views/InventarioFactura/InventarioFactura";
import Bancos from "./views/Configuración/Bancos/Bancos";
import Bitacora from "./views/Bitacora/Bitacora";
import Bitacora_ from "./views/ArchivosCards/Bitacora_/Bitacora_";
import Bodega from "./views/Configuración/Bodega/Bodega";
import Categorias from "./views/Configuración/Categorias/Categorias.js";
import Bodegas from "./views/Configuración/Bodegas/Bodegas";
import Stocks from "./views/Configuración/Stocks/Stocks.js";
import CambioPuesto from "./views/CambioPuesto/CambioPuesto";
import Capacitacion from "./views/Capacitacion/Capacitacion";
import Ciudades from "./views/Configuración/Ciudades/Ciudades";
import Comprobantes from "./views/Configuración/Comprobantes/Comprobantes";
import FormasPagos from "./views/Configuración/FormasPagos/FormasPagos";
import Actualizaciones from "./views/Configuración/Actualizaciones/Actualizaciones.js";
import Clientes from "./views/Personas/Clientes/Clientes";
import Compras from "./views/Configuración/Compras/Compras.js";
import CuentasPorCobrar from "./views/Configuración/CuentasCobrar/CuentasPorCobrar.js";
import Configuraciones from "./views/ArchivosCards/Configuraciones/Configuraciones";
import Consignas from "./views/Personas/Consignas/Consignas";
import CumplimientoActividades from "./views/CumplimientoActividades/CumplimientoActividades";
import Dashboard from "./views/Dashboard/Dashboard";
import DatosOperativos from "./views/DatosOperativos/DatosOperativos";
import Distribucion from "./views/Distribucion/Distribucion";
import Distribuciones from "./views/Distribuciones/Distribuciones";
import Documentos from "./views/Configuración/Documentos/Documentos";
import DocumentosExpirados from "./views/DocumentosExpirados/DocumentosExpirados";
import Empresas from "./views/Configuración/Empresas/Empresas";
import EncuestasEvaluaciones from "./views/EncuestasEvaluaciones/EncuestasEvaluaciones";
import EstadoCuentas from "./views/Personas/EstadoCuentas/EstadoCuentas";
import Evaluaciones from "./views/Configuración/Evaluaciones/Evaluaciones";
import EventosActividades from "./views/EventosActividades/EventosActividades";
import FOP from "./views/FOP/FOP";
import GOP from "./views/GOP/GOP";
import Horarios from "./views/Horarios/Horarios.js";
import Informes from "./views/Informes/Informes";
import Informes_ from "./views/ArchivosCards/Informes_/Informes_";
import Inventario from "./views/Inventario/Inventario";
import IOP from "./views/IOP/IOP";
import ISO from "./views/ISO/ISO";
import Iso_ from "./views/ArchivosCards/Iso_/Iso_";
import Logistica from "./views/ArchivosCards/Logistica/Logistica";
import LugarTrabajo from "./views/Configuración/LugarTrabajo/LugarTrabajo";
import MejoraContinua from "./views/MejoraContinua/MejoraContinua";
import Menu from "./views/Menu/Menu";
import Nomina from "./views/ArchivosCards/Nomina/Nomina";
import Liquidacion from "./views/ArchivosCards/Liquidacion/Liquidacion";
import NominaNovedades from "./views/Configuración/NominaNovedades/NominaNovedades.js";
import Novedades from "./views/Configuración/Novedades/Novedades";
import Operaciones from "./views/ArchivosCards/Operaciones/Operaciones";
import Permisos from "./views/Permisos/Permisos";
import Personal from "./views/ArchivosCards/Personal/Personal";
import Personas from "./views/Personas/Personas/Personas";
import HorariosyTurnos from "./views/Personas/HorariosyTurnos/HorariosyTurnos.js";
import Planificacion from "./views/Planificacion/Planificacion";
import Prestamos from "./views/Configuración/Prestamos/Prestamos.js";
import Proveedores from "./views/Personas/Proveedores/Proveedores";
import Registros from "./views/Registros/Registros";
import Residentes from "./views/Residentes/Residentes";
import Rolpagos from "./views/Rolpagos/Rolpagos";
import Transacciones from "./views/Ventas/Transacciones";
import Tthh from "./views/ArchivosCards/Tthh/Tthh";
import TurnoExtra from "./views/TurnoExtra/TurnoExtra";
import TurnosExtras from "./views/TurnosExtras/TurnosExtras";
import Tutoriales from "./views/Tutoriales/Tutoriales";
import Ventas from "./views/ArchivosCards/Ventas/Ventas";
import Productos from "./views/Productos/Productos.js";
import Subcategorias from "./views/Configuración/Subcategorias/Subcategorias.js";
import PlanDeCuentas from "./views/Configuración/PlanDeCuentas/PlanDeCuentas.js";
import ChatParticipants from "./views/Configuración/ChatParticipants/ChatParticipants";
import ChatsGroup from "./views/Configuración/ChatsGroup/ChatsGroup";
const dashboardRoutes = [
  //DASHBOARD
  {
    path: "/Dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/Seguridad",
    tipo: "categoria",
  },
  //VENTAS
];
export const componentes = {
  _Empresa,
  Categorias,
  ActaEntregaRecepcion,
  Actividades,
  ActividadesAsignadas,
  ActividadesCabecera,
  ActividadesOperativas,
  Anticipos,
  Vacaciones,
  Aportes,
  Asistencias,
  Ausencia,
  Avisos,
  AvisoSalida,
  Bancos,
  Bitacora_,
  Bitacora,
  Bodega,
  Bodegas,
  CambioPuesto,
  Capacitacion,
  Ciudades,
  Clientes,
  Compras,
  Configuraciones,
  Consignas,
  CumplimientoActividades,
  Dashboard,
  DatosOperativos,
  Distribucion,
  Distribuciones,
  Documentos,
  DocumentosExpirados,
  Empresas,
  EncuestasEvaluaciones,
  EstadoCuentas,
  Evaluaciones,
  EventosActividades,
  FOP,
  GOP,
  Horarios,
  Informes_,
  Informes,
  Inventario,
  IOP,
  Iso_,
  ISO,
  Logistica,
  LugarTrabajo,
  MejoraContinua,
  Menu,
  Nomina,
  NominaNovedades,
  Novedades,
  Operaciones,
  Permisos,
  Personal,
  Personas,
  HorariosyTurnos,
  Planificacion,
  Prestamos,
  Proveedores,
  Registros,
  Residentes,
  Rolpagos,
  Transacciones,
  Tthh,
  TurnoExtra,
  TurnosExtras,
  Tutoriales,
  Ventas,
  Productos,
  Stocks,
  Liquidacion,
  Comprobantes,
  FormasPagos,
  InventarioFactura,
  Subcategorias,
  PlanDeCuentas,
  ChatParticipants,
  ChatsGroup,
  CuentasPorCobrar: CuentasPorCobrar,
  Actualizaciones,
};

export default dashboardRoutes;
