import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  TextField,
  Divider,
  Typography,
  IconButton,
  useTheme,
  MenuItem,
  Modal,
  lighten,
  Button,
} from "@mui/material";
import {
  Backspace,
  Clear,
  ExpandLess,
  ExpandMore,
  FindInPage,
} from "@mui/icons-material";
import CamposBusqueda from "./CamposBusqueda";
import { useModalStyle } from "../styles/styleModal";
import Draggable from "react-draggable";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "55%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2),
    borderRadius: "15px",
    marginTop: "4vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "0",
      width: "45%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
}));

const ModalB = (props) => {
  const {
    titulo,
    fullBuscador,
    arrayProps: arrayColumnas,
    handleChange,
    funcionB,
    buscando,
    funcionDefault,
    openMB,
    setopenMB,
  } = props;
  const [expandido, setexpandido] = useState(false);
  const [arrayProps] = useState(arrayColumnas);
  const [arrayOcultarProps, setarrayOcultarProps] = useState([]);

  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStylesMenuItems(name, permisos, theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor: permisos.indexOf(name) === -1 ? "white" : "#31b2f7",
    };
  }
  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);
  return (
    <Modal
      open={openMB}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => setopenMB(false)}
    >
      <Box>
        <Draggable handle="#handleMulta">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                style={{ cursor: "-webkit-grab" }}
              >
                <Typography
                  id="handleBusqueda"
                  style={{ cursor: "-webkit-grab" }}
                  fontWeight={"900"}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {titulo}
                </Typography>
                <Box display="flex" alignItems="center">
                  {/* {buscando ? (
                    <IconButton
                      aria-label=""
                      color="secondary"
                      autoFocus
                      onKeyPress={(e) => {}}
                      onClick={async () => {
                        await funcionDefault();
                      }}
                    >
                      <Backspace color="error" />
                    </IconButton>
                  ) : null}{" "} */}
                  <IconButton
                    aria-label=""
                    color="error"
                    onClick={() => {
                      setopenMB(false);
                    }}
                  >
                    <Clear color={expandido ? "secondary" : "inherit"} />
                  </IconButton>
                  {/* <IconButton
                    aria-label=""
                    style={{ margin: "0px", padding: ".3rem" }}
                    onClick={() => {
                      funcionB();
                    }}
                  >
                    <FindInPage
                      fontSize="large"
                      color={expandido ? "secondary" : "inherit"}
                    />
                  </IconButton> */}
                  {/* <IconButton
              aria-label=""
              style={{ margin: "0px", padding: ".3rem" }}
              onClick={() => {
                setexpandido((ref) => !ref);
              }}
            >
              {expandido ? (
                <ExpandLess fontSize="large" />
              ) : (
                <ExpandMore fontSize="large" />
              )}
            </IconButton> */}
                </Box>
              </Box>

              <Divider />

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box></Box>
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                mb={2}
                p={1}
              >
                <TextField
                  variant="outlined"
                  className={clases.formAllW}
                  size="small"
                  label="Propiedad"
                  value={arrayOcultarProps}
                  onChange={(e) => {
                    setarrayOcultarProps(e.target.value);
                  }}
                  select
                  MenuProps={MenuProps}
                  // InputLabelProps={{ shrink: true }}
                  SelectProps={{
                    displayEmpty: true,
                    multiple: true,
                    renderValue: (selected) => {
                      return selected
                        .map((value, index) => {
                          return value;
                        })
                        .join(" -- ");
                    },
                  }}
                >
                  <MenuItem disabled value="">
                    Seleccione.
                  </MenuItem>
                  {arrayProps
                    .sort((a, b) => a.orden - b.orden)
                    .map((item) => {
                      if (item.ocultar) {
                        return null;
                      }
                      return (
                        <MenuItem
                          style={getStylesMenuItems(
                            item.label,
                            arrayOcultarProps,
                            theme
                          )}
                          value={item.label}
                        >
                          {item.label}
                        </MenuItem>
                      );
                    })}
                </TextField>
                {arrayProps
                  .sort((a, b) => a.orden - b.orden)
                  .map((campoObjectItem) => {
                    const existe = arrayOcultarProps.some(
                      (item) => item === campoObjectItem.label
                    );
                    if (campoObjectItem.ocultar) {
                      return null;
                    }
                    if (!existe) {
                      return null;
                    }
                    return (
                      <CamposBusqueda
                        campoObjectItem={campoObjectItem}
                        handleChange={handleChange}
                        fullBuscador={fullBuscador}
                      />
                    );
                  })}
              </Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                  {buscando && (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={async () => {
                        await funcionDefault();
                      }}
                    >
                      Cancelar Busqueda <Backspace color="inherit" />
                    </Button>
                  )}
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    funcionB();
                  }}
                >
                  Buscar <FindInPage color="inherit" />
                </Button>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default ModalB;
