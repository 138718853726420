import React, { memo, useState, useContext, useEffect, useRef } from "react";

import { Box, Button, Divider } from "@mui/material";

import Tabla from "./Componentes/Tabla";
import BarraTablas from "../../components/BarraTablas";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import Modal from "./Componentes/Modal";
import io from "socket.io-client";
import { socketUrl } from "../../../config/const";
import ModalB from "../../components/ModalB";
import DialogOpciones from "../../components/DialogOpciones";
import {
  Add,
  Menu,
  MenuBook,
  PictureAsPdf,
  PictureAsPdfOutlined,
  PictureAsPdfRounded,
  PictureAsPdfTwoTone,
  Search,
  SearchRounded,
  Upload,
  UploadFile,
} from "@mui/icons-material";
import { usePermiso } from "../../hooks/usePermiso";
import { useCrud } from "../../hooks/useCrud";
import { columns, objectDefault } from "./data";
import { objectDefault as objectDefaultAnticipo } from "../../Anticipos/data";
import ModalAnticipo from "../../Anticipos/Componentes/Modal";
import { objectDefault as objectDefaultMulta } from "../../Configuración/NominaNovedades/data";
import ModalMulta from "../../Configuración/NominaNovedades/Componentes/Modal";
import { objectDefault as objectDefaultTurnoExtra } from "../../TurnoExtra/data";
import ModalTurnoExtra from "../../TurnoExtra/Componentes/Modal";
import { objectDefault as objectDefaultAusencia } from "../../Ausencia/data";
import ModalAusencia from "../../Ausencia/Componentes/Modal";

const Personas = ({ fromHorarios }) => {
  // DATA DEL STATE
  const [rows, setrows] = useState(null);

  const defaultFullBuscador = () => {
    const json = {};
    [
      ...columns,
      {
        label: "Turno Iniciado",
        minWidth: 25,
        tipo: "string",
        orden: 1,
        propiedad: "turno_iniciado",
        align: "center",
        propiedad: "turno_iniciado",
        variante: "autocompleteComun",
        data: ["SI", "NO"],
      },
      {
        label: "Tiene Horario",
        minWidth: 25,
        tipo: "string",
        orden: 1,
        propiedad: "tiene_horario",
        align: "center",
        propiedad: "tiene_horario",
        variante: "autocompleteComun",
        data: ["SI", "NO"],
      },
    ].forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (itemPropiedad.tipo === "numero") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "fecha") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });
    return json;
  };
  const [ObjectoActivo, setObjetoActivo] = useState(objectDefault);
  const [pagination, setpagination] = useState(null);
  // FUNCIONES STATE
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estadoRequest,
    notificacion = false,
    idRegistro = 0
  ) => {
    try {
      const url = `/horarios_y_turnos/lisByPagination/?cantidad=${cantidad}&page=${page}&search=${
        search ? search : ""
      }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
        datos ? datos : ""
      }&rucempresa=${rucempresa ? rucempresa : ""}&perfil=${
        estadoRequest ? estadoRequest : ""
      }&notificacion=${notificacion}&idRegistro=${idRegistro}`;
      console.log({ datos: JSON.parse(datos) });

      const res = await clienteAxios.get(url);

      return res;
    } catch (error) {
      mostrarAlerta("Hubo un error");
    }
  };

  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estadoRequest,
    notificacion = false,
    idRegistro = 0
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estadoRequest,
      notificacion,
      idRegistro
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  const addRowsState = async (items) => {
    setrows([...rows, ...items]);
  };
  const remplazarRowsState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estadoRequest
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estadoRequest
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };

  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
  };

  // FIN FUNCIONES STATE
  // FIN DATA STATE

  // FUNCIONES SOCKET
  const [tabla] = useState("horarios_y_turnos");
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });

  const socket = useRef();
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      if (data.tabla === "anticipo") {
        console.log(data);

        //*ANTICIPOS
        // antIdPersona: "",
        // antDocuPersona: "",
        // antNomPersona: "",

        setitemSocket({
          tipo: "aumentarConteo",
          item: {
            rucempresa: data.rucempresa,
            msg: data.msg,
            perId: data.result[0]?.antIdPersona,
          },
        });
      }
      if (data.tabla === "nominanovedad") {
        //*MULTA
        // nomIdPer: itemF.perId,
        // nomDocuPer: itemF.perDocNumero,
        // nomNombrePer:
        //   itemF.perApellidos + " " + itemF.perNombres,
        // nomPuesto: itemF.perPuestoServicio,
        // nomCiudad: itemF.perLugarTrabajo,
        setitemSocket({
          tipo: "aumentarConteo",
          item: {
            rucempresa: data.rucempresa,
            msg: data.msg,
            perId: data.nomIdPer,
          },
        });
      }
      if (data.tabla === "ausencia") {
        //*AUSENCIA
        // ausDocuPersona: itemF.perDocNumero,
        // ausNomPersona:
        //   itemF.perApellidos +
        //   " " +
        //   itemF.perNombres,
        // ausIdPersona: itemF.perId,
        // ausPerIdCliente: itemF.perIdCliente,
        // ausPerDocuCliente: itemF.perDocuCliente,
        // ausPerNombreCliente: itemF.perNombreCliente,
        setitemSocket({
          tipo: "aumentarConteo",
          item: {
            rucempresa: data.rucempresa,
            msg: data.msg,
            perId: data.ausIdPersona,
          },
        });
      }
      if (data.tabla === "turnoextra") {
        //*TURNO EXTRA */
        // turDocuPersona: options[0],
        // turNomPersona: options[1].trim(),
        // turIdPersona: options[2],
        setitemSocket({
          tipo: "aumentarConteo",
          item: {
            rucempresa: data.rucempresa,
            msg: data.msg,
            perId: data.turIdPersona,
          },
        });
      }

      if (data.tabla === tabla) {
        setitemSocket({
          tipo: "agregar",
          item: data,
        });
      }
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      console.log({ data });
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      console.log({ data });

      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }
    if (!rows) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }

    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };
    console.log({ rows, item, tipo });
    if (tipo === "aumentarConteo") {
      let itemF = rows.find((row) => row.perId === Number(item.perId));
      console.log({ itemF });
      if (itemF) {
        itemF.total_registros++;
        editarState(itemF);
        funcionAalerta();
        setitemSocket({ tipo: "", item: {} });
      }
    }

    if (item.tabla === tabla) {
      if (tipo === "agregar") {
        agregarState(item);
      }
      if (tipo === "editar") {
        editarState(item);
      }
      if (tipo === "eliminar") {
        eliminarState(item[campoExport]);
      }
      if (tipo === "eliminarSeleccion") {
        eliminarSeleccionState(item.lista);
      }
      funcionAalerta();
      setitemSocket({ tipo: "", item: {} });
    }

    // eslint-disable-next-line
  }, [itemSocket]);
  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    usuario: { rucempresa },
    usuario,
  } = useContext(AuthContext);
  const { tienePermiso, alertaPermiso } = usePermiso("Personas");
  const [estadoRequest, setestadoRequest] = useState("GUARDIAS");
  const [open, setopen] = useState(false);
  const [buscando, setbuscando] = useState(false);
  const [editar, seteditar] = useState(false);
  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [campoExport] = useState("perId");
  const {
    agregarState,
    editarState,
    eliminarState,
    eliminarSeleccionState,
  } = useCrud(rows, setrows, campoExport, "perUser", usuario);
  const [input, setinput] = useState({
    id: campoExport,
    label: "ID",
    minWidth: 150,
    tipo: "string",
    propiedad: campoExport,
  });
  const [arrayExport, setarrayExport] = useState([]);

  const [openDialogOpciones, setopenDialogOpciones] = useState(false);
  const [orden, setorden] = useState(false);
  const [openMB, setopenMB] = useState(false);
  const [fullBuscador, setfullBuscador] = useState(defaultFullBuscador());

  //pagina actual
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  const funcionB = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };

  const handleSearch = async () => {
    setarrayExport([]);
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setbuscando(true);
    setPage(0);
    setcargando(false);
  };

  const funcionDefault = async () => {
    setcargando(true);
    const fullBRes = defaultFullBuscador();
    setfullBuscador(fullBRes);
    await remplazarRowsState(
      rowsPerPage,
      0,
      "",
      input.id,
      !orden,
      JSON.stringify(fullBRes),
      rucempresa,
      estadoRequest
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };
  const inputEl = useRef();
  const funcArchivo = async (e) => {
    if (!e.target.files[0]) {
      return;
    } else {
      if (e.target.files[0].name.split(".")[1] !== "xlsx") {
        return mostrarAlerta("El archivo no es xlsx", "error");
      } else {
        try {
          setcargando(true);
          const formData = new FormData();
          formData.append("archivo", e.target.files[0]);
          const res = await clienteAxios.post(
            "/personas/importar_from_excel/0",
            formData
          );
          setrows(res.data.data);
          setpagination({
            current: 0,
            next: 1,
            numRows: res.data.data.length,
            perPage: 10,
          });
          setcargando(false);
          return mostrarAlerta("Importación exitosa", "success");
        } catch (error) {
          return mostrarAlerta(
            "Error Importar Revisar que todas columna este llenas",
            "error"
          );
        }
      }
    }
  };
  const [estadoRequestAnticipo, setestadoRequestAnticipo] = useState(
    "Anticipos"
  );
  const [ObjectoActivoAnticipo, setObjetoActivoAnticipo] = useState(
    objectDefaultAnticipo
  );

  const [openAnticipo, setopenAnticipo] = useState(false);

  //*MULTA
  const [openMulta, setopenMulta] = useState(false);
  const [ObjectoActivoMulta, setObjetoActivoMulta] = useState(
    objectDefaultMulta
  );

  const [estadoRequestMulta, setestadoRequestMulta] = useState("EN PROCESO");

  //*AUSENCIA
  const [openAusencia, setopenAusencia] = useState(false);
  const [ObjectoActivoAusencia, setObjetoActivoAusencia] = useState(
    objectDefaultAusencia
  );

  const [estadoRequestAusencia, setestadoRequestAusencia] = useState(
    "EN PROCESO"
  );

  //* TURNO EXTRA
  // OBJETO ACTIVO O PLANTILLA
  const [ObjectoActivoTurnoExtra, setObjetoActivoTurnoExtra] = useState(
    objectDefaultTurnoExtra
  );
  // CAMBIA EL OBJETO ACTIVO DEL STATE GENERALMENTE PARA EDITAR

  const [estadoRequestTurnoExtra, setestadoRequestTurnoExtra] = useState(
    "APROBADO"
  );
  const [openTurnoExtra, setopenTurnoExtra] = useState(false);
  return (
    <Box sx={{ width: "100%" }}>
      {/** MODALES  */}
      {/* <Button
        variant="text"
        color="primary"
        onClick={() => setopenAnticipo(true)}
      >ASDAS</Button> */}
      {/* <Button
        variant="text"
        color="primary"
        onClick={() => setopenMulta(true)}
      >
        ASDAS
      </Button> */}
      {/* <Button
        variant="text"
        color="primary"
        onClick={() => setopenAusencia(true)}
      >
        ASDAS
      </Button> */}
      {/* <Button
        variant="text"
        color="primary"
        onClick={() => setopenTurnoExtra(true)}
      >
        ASDAS
      </Button> */}
      {socket.current && (
        <>
          <ModalAnticipo
            fromHorarios
            // tabla: "anticipo",
            // antEstado: fromHorarios ? "APROBADA" : item.antEstado,
            // antEstado === "APROBADA"
            estadoRequest={estadoRequestAnticipo}
            editar={false}
            seteditar={() => {}}
            setopen={setopenAnticipo}
            open={openAnticipo}
            ObjectoActivo={ObjectoActivoAnticipo}
            changeObjetoActivoState={setObjetoActivoAnticipo}
            socket={socket}
            desdeDis={false}
          />
          <ModalMulta
            fromHorarios
            // tabla: "nominanovedad",
            // nomEstado: "EN PROCESO",
            editar={false}
            seteditar={() => {}}
            setopen={setopenMulta}
            open={openMulta}
            ObjectoActivo={ObjectoActivoMulta}
            changeObjetoActivoState={setObjetoActivoMulta}
            socket={socket}
            estadoRequest={estadoRequestMulta}
          />
          <ModalAusencia
            //  id="ausEstado"
            //         label="Estado"
            //         value={ausEstado}
            //         name="ausEstado"
            //         error={error.ausEstado}
            //         onChange={(e) => handleChange(e)}
            //         select
            //         InputLabelProps={{ shrink: true }}
            //         SelectProps={{ displayEmpty: true }}
            //       >
            //         <MenuItem disabled value="">
            //           Seleccione.
            //         </MenuItem>
            //         {["EN PROCESO", "APROBADO", "ANULADA"].map((item) => {
            fromHorarios
            // tabla: "ausencia",
            // ausEstado: "APROBADO",
            editar={false}
            seteditar={() => {}}
            setopen={setopenAusencia}
            open={openAusencia}
            ObjectoActivo={ObjectoActivoAusencia}
            changeObjetoActivoState={setObjetoActivoAusencia}
            socket={socket}
          />{" "}
          <ModalTurnoExtra
            // id="turEstado"
            // label="Estado"
            // value={turEstado}
            // name="turEstado"
            // error={error.turEstado}
            // onChange={(e) => handleChange(e)}
            // select
            // InputLabelProps={{ shrink: true }}
            // SelectProps={{ displayEmpty: true }}
            // >
            // <MenuItem disabled value="">
            //   Seleccione.
            // </MenuItem>
            // {["EN PROCESO", "APROBADO", "ANULADA"].map((item) => {
            //   return <MenuItem value={item}>{item} </MenuItem>;
            // })}
            fromHorarios
            // tabla: "turnoextra",
            // turEstado: "APROBADO",
            editar={false}
            seteditar={() => {}}
            setopen={setopenTurnoExtra}
            open={openTurnoExtra}
            ObjectoActivo={ObjectoActivoTurnoExtra}
            changeObjetoActivoState={setObjetoActivoTurnoExtra}
            socket={socket}
          />
        </>
      )}

      <input
        style={{ display: "none" }}
        type="file"
        name="myImage"
        onChange={async (e) => {
          funcArchivo(e);
        }}
        ref={inputEl}
      />
      <DialogOpciones
        open={openDialogOpciones}
        setopen={setopenDialogOpciones}
        botones={[
          // {
          //   tooltip: "Archivo Importación",
          //   funcion: () => {
          //     window.open("https://backsafe.neitor.com/datos_personas.xlsx");
          //     setopenDialogOpciones(false);
          //   },
          //   Icon: MenuBook,
          //   color: "success",
          //   id: 1,
          //   disabled: false,
          // },
          // {
          //   tooltip: "Importar Registros",
          //   funcion: () => {
          //     inputEl.current.click();
          //     setopenDialogOpciones(false);
          //   },
          //   Icon: UploadFile,
          //   color: "primary",
          //   id: 1,
          //   disabled: false,
          // },
          // {
          //   tooltip: "PDF - ACTIVO",
          //   funcion: () => {
          //     window.open(
          //       `${clienteAxios.defaults.baseURL}reportes/info_personal_empresa?codigo=${usuario.codigo}&rucempresa=${usuario.rucempresa}`
          //     );
          //     setopenDialogOpciones(false);
          //   },
          //   Icon: PictureAsPdfRounded,
          //   color: "secondary",
          //   id: 1,
          //   disabled: false,
          // },
          // {
          //   tooltip: "PDF - INACTIVO",
          //   funcion: () => {
          //     window.open(
          //       `${
          //         clienteAxios.defaults.baseURL
          //       }reportes/info_personal_empresa?codigo=${
          //         usuario.codigo
          //       }&rucempresa=${usuario.rucempresa}${"&estado=inactivo"}`
          //     );
          //     setopenDialogOpciones(false);
          //   },
          //   Icon: PictureAsPdfOutlined,
          //   color: "error",
          //   id: 1,
          //   disabled: false,
          // },
          {
            tooltip: "Busquedas avanzadas",
            funcion: () => {
              setopenMB(true);
            },
            Icon: Search,
            color: "error",
            id: 1,
            disabled: false,
          },
        ]}
        titulo={"Importación"}
      />

      <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        LabelBuscador="Buscar: Cédula, Nombre, Nómina Pago"
        funcionDefault={funcionDefault}
        ocultarMigasDePan={false}
        botones={[
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              setopen(true);
            },
            disabled: false,
            Icon: Add,
            color: "primary",
            id: 1,
            ocultar: true,
            tipo: "icono",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              if (!tienePermiso("añadir")) {
                return alertaPermiso("añadir");
              }
              setopen(true);
            },
            disabled: false,
            Icon: Add,
            color: "primary",
            id: 2,
            ocultar: true,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
          // {
          //   tooltip: "OPCIONES",
          //   texto: "OPCIONES",
          //   funcion: () => {
          //     setopenDialogOpciones(true);
          //     // window.open("https://backsafe.neitor.com/datos_personas.xlsx");
          //   },
          //   disabled: false,
          //   Icon: Menu,
          //   color: "success",
          //   id: 2,
          //   ocultar: false,
          //   tipo: "boton",
          //   variante: "contained",
          //   size: "medium",
          //   sx: {
          //     ml: 1,
          //   },
          // },
          {
            tooltip: "Busqueda avanzada",
            texto: "",
            funcion: () => {
              setopenMB(true);
            },
            disabled: false,
            Icon: SearchRounded,
            color: "success",
            id: 2,
            ocultar: false,
            tipo: "icono",
            variante: "contained",
            size: "medium",
            sx: {
              ml: 1,
            },
          },
          {
            tooltip: "Importar Registros",
            texto: "",
            funcion: () => {
              inputEl.current.click();
            },
            disabled: false,
            Icon: UploadFile,
            color: "primary",
            id: 2,
            ocultar: true,
            tipo: "icono",
            variante: "contained",
            size: "medium",
            sx: {
              ml: 1,
            },
          },
        ]}
      />
      <ModalB
        defaultFullBuscador={defaultFullBuscador}
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={"Busqueda avanzada (Horarios Y Turnos)"}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        arrayProps={[
          ...columns,
          {
            label: "Turno Iniciado",
            minWidth: 25,
            tipo: "string",
            orden: 1,
            propiedad: "turno_iniciado",
            align: "center",
            propiedad: "turno_iniciado",
            variante: "autocompleteComun",
            data: ["SI", "NO"],
          },
          {
            label: "Tiene Horario",
            minWidth: 25,
            tipo: "string",
            orden: 1,
            propiedad: "tiene_horario",
            align: "center",
            propiedad: "tiene_horario",
            variante: "autocompleteComun",
            data: ["SI", "NO"],
          },
        ]}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        funcionDefault={funcionDefault}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems=" stretch"
        width="100%"
      >
        <Box>
          <Button
            size="small"
            variant={estadoRequest === "GUARDIAS" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRowsState(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify(fullBuscador),
                rucempresa,
                "GUARDIAS"
              );

              setPage(0);
              setestadoRequest("GUARDIAS");
              setcargando(false);
            }}
            disableElevation
            disabled={cargando}
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="primary"
          >
            GUARDIAS
          </Button>
          <Button
            size="small"
            variant={estadoRequest === "SUPERVISORES" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRowsState(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify(fullBuscador),
                rucempresa,
                "SUPERVISORES"
              );

              setPage(0);
              setestadoRequest("SUPERVISORES");
              setcargando(false);
            }}
            disableElevation
            disabled={cargando}
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="primary"
          >
            SUPERVISORES
          </Button>
          {usuario.rol.some(
            (rol) =>
              rol === "ADMIN" || rol === "GERENTE" || rol === "ADMINISTRACION"
          ) ? (
            <Button
              size="small"
              variant={estadoRequest === "ADMINISTRACIÓN" ? "contained" : null}
              onClick={async () => {
                setcargando(true);
                await remplazarRowsState(
                  rowsPerPage,
                  0,
                  "",
                  input.id,
                  orden,
                  JSON.stringify(fullBuscador),
                  rucempresa,
                  "ADMINISTRACIÓN"
                );

                setPage(0);
                setestadoRequest("ADMINISTRACIÓN");
                setcargando(false);
              }}
              disableElevation
              disabled={cargando}
              style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
              color="primary"
            >
              ADMINISTRACIÓN
            </Button>
          ) : null}
        </Box>
        <Box>
          <Button
            size="small"
            variant={estadoRequest === "INACTIVO" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRowsState(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify(fullBuscador),
                rucempresa,
                "INACTIVO"
              );

              setPage(0);
              setestadoRequest("INACTIVO");
              setcargando(false);
            }}
            disableElevation
            disabled={cargando}
            style={{
              padding: ".5rem",
              borderRadius: "15px 15px  0px 0px",
              background: estadoRequest === "INACTIVO" ? "#ccc" : "",
            }}
          >
            INACTIVO
          </Button>
          {/* 
          <Button
            size="small"
            variant={"contained"}
            onClick={async () => {
              window.open(
                `${clienteAxios.defaults.baseURL}reportes/info_personal_empresa?codigo=${usuario.codigo}&rucempresa=${usuario.rucempresa}`
              );
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color={"success"}
          >
            PDF
          </Button>
          <Button
            size="small"
            variant={"contained"}
            onClick={async () => {
              window.open(
                `${
                  clienteAxios.defaults.baseURL
                }reportes/info_personal_empresa?codigo=${
                  usuario.codigo
                }&rucempresa=${usuario.rucempresa}${"&estado=inactivo"}`
              );
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color={"error"}
          >
            PDF
          </Button> */}
        </Box>
      </Box>
      <Divider />
      <Tabla
        //PAGINACION Y BUSQUEDA
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setopen={setopen}
        search={search}
        input={input}
        orden={orden}
        setinput={setinput}
        setorden={setorden}
        cargando={cargando}
        setcargando={setcargando}
        fullBuscador={fullBuscador}
        //ESTADO
        rows={rows}
        columns={columns}
        pagination={pagination}
        getDataState={getDataState}
        obtenerState={obtenerState}
        addRowsState={addRowsState}
        remplazarRowsState={remplazarRowsState}
        changeObjetoActivoState={changeObjetoActivoState}
        seteditar={seteditar}
        arrayExport={arrayExport}
        setarrayExport={setarrayExport}
        socket={socket}
        campoExport={campoExport}
        estadoRequest={estadoRequest}
        tabla={tabla}
        setestadoRequest={setestadoRequest}
        setsearch={setsearch}
        setbuscando={setbuscando}
        fromHorarios={fromHorarios}
        setestadoRequestAnticipo={setestadoRequestAnticipo}
        changeObjetoActivoStateAnticipo={setObjetoActivoAnticipo}
        setopenAnticipo={setopenAnticipo}
        setopenMulta={setopenMulta}
        changeObjetoActivoStateMulta={setObjetoActivoMulta}
        setestadoRequestMulta={setestadoRequestMulta}
        setopenAusencia={setopenAusencia}
        changeObjetoActivoStateAusencia={setObjetoActivoAusencia}
        setestadoRequestAusencia={setestadoRequestAusencia}
        changeObjetoActivoStateTurnoExtra={setObjetoActivoTurnoExtra}
        setestadoRequestTurnoExtra={setestadoRequestTurnoExtra}
        setopenTurnoExtra={setopenTurnoExtra}
      />
      <Modal
        editar={editar}
        seteditar={seteditar}
        setopen={setopen}
        open={open}
        ObjectoActivo={ObjectoActivo}
        changeObjetoActivoState={changeObjetoActivoState}
        socket={socket}
        estadoRequest={estadoRequest}
      />
    </Box>
  );
};

export default memo(Personas);
