import React, { useContext, useEffect, useState } from "react";

// @mui/material components
// import { makeStyles } from "@mui/styles";
import {
  IconButton,
  Box,
  Badge,
  MenuItem,
  MenuList,
  Paper,
  ClickAwayListener,
  Avatar,
  Button,
  Popper,
  Fade,
  Drawer,
  lighten,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import Typography from "@mui/material/Typography";
// @material-ui/icons
import { Campaign, Message, Notifications } from "@mui/icons-material";
import SiluetaPolice from "../../../assets/img/Recurso 1.png";
import AuthContext from "../../../Context/Auth/AuthContext.js";
// import guardiaPng from "../../../assets/img/guardiaPng.png";
import clienteAxios from "../../../config/axios.js";
import { trimPropsItem } from "../../../config/const.js";
import { NavLink, withRouter } from "react-router-dom";
import Perfil from "./Perfil";
import Encuestas from "./Encuestas";
import { useProvideSocket } from "../../../views/Chat/socket/useProvideSocket.js";
import DialogEmpresas from "./DialogEmpresas.js";
const NotificactionsButtons = (props) => {
  const { usuario, socket, cerrarSesion } = useContext(AuthContext);
  const [openEncuestas, setopenEncuestas] = useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openEmpresasDialog, setopenEmpresasDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu((previousOpen) => !previousOpen);
  };
  const canBeOpen = openMenu && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  // NOTIFICACION 1
  const [openNotificacion1, setOpenNotificacion1] = React.useState(false);
  const [anchorElNotificacion1, setAnchorElNotificacion1] = React.useState(
    null
  );
  const handleClickNotificacion1 = (event) => {
    setAnchorElNotificacion1(event.currentTarget);
    setOpenNotificacion1((previousOpen) => !previousOpen);
  };
  const canBeOpenNotificacion1 =
    openNotificacion1 && Boolean(anchorElNotificacion1);
  const idNotificacion1 = canBeOpenNotificacion1
    ? "transition-popperNotificacion1"
    : undefined;

  // NOTIFICACION 2
  const [openNotificacion2, setOpenNotificacion2] = React.useState(false);
  const [anchorElNotificacion2, setAnchorElNotificacion2] = React.useState(
    null
  );
  const handleClickNotificacion2 = (event) => {
    setAnchorElNotificacion2(event.currentTarget);
    setOpenNotificacion2((previousOpen) => !previousOpen);
  };
  const canBeOpenNotificacion2 =
    openNotificacion2 && Boolean(anchorElNotificacion2);
  const idNotificacion2 = canBeOpenNotificacion2
    ? "transition-popperNotificacion2 "
    : undefined;

  const [openPerfil, setopenPerfil] = useState(false);

  const handleEditar = (notificacion) => {
    socket.emit(
      "client:actualizarData",
      trimPropsItem({
        ...notificacion,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        notUser: usuario.usuario,
        notEmpresa: usuario.rucempresa,
        tabla: "notificacionleido",
      })
    );
  };
  const [notificacionesData, setnotificacionesData] = useState({
    notificacion1: [],
    notificacion2: [],
    estado: false,
  });
  const [itemEdit, setitemEdit] = useState(false);
  const [nuevoItem, setnuevoItem] = useState(false);
  useEffect(() => {
    const getData = async () => {
      const resNotificaciones = await clienteAxios.get(
        "/notificaciones/listado"
      );

      if (
        resNotificaciones.data.data.notificacion1 ||
        resNotificaciones.data.data.notificacion2
      ) {
        setnotificacionesData({ estado: true, ...resNotificaciones.data.data });
      }
    };
    if (!notificacionesData.estado) {
      getData();
    }
    // eslint-disable-next-line
  }, []);
  const notificacionesDataRef = React.useRef(notificacionesData);
  useEffect(() => {
    // eslint-disable-next-line
  }, [notificacionesDataRef, socket]);

  useEffect(() => {
    socket.on("server:actualizadoExitoso", async (data) => {
      if (usuario.usuario !== data.notNotificacionPertenece) {
        return;
      }
      setitemEdit(data);
      //(data);
      return () => {
        socket.close();
      };
    });
    socket.on("server:nuevanotificacion", async (data) => {
      if (usuario.usuario !== data.notNotificacionPertenece) {
        return;
      }
      if (usuario.rucempresa !== data.notEmpresa) {
        return;
      }
      setnuevoItem(data);
      //(data);
      return () => {
        socket.close();
      };
    });
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  useEffect(() => {
    if (itemEdit) {
      if (itemEdit.notTipo === "ACTIVIDAD" || itemEdit.notTipo === "CONSIGNA") {
        const resNotificaciones = notificacionesData.notificacion1.map(
          (itemNotificacion) => {
            if (itemNotificacion.notId === itemEdit.notId) {
              return itemEdit;
            } else {
              return itemNotificacion;
            }
          }
        );
        setnotificacionesData({
          ...notificacionesData,
          notificacion1: resNotificaciones,
        });
      } else {
        const resNotificaciones = notificacionesData.notificacion2.map(
          (itemNotificacion) => {
            if (itemNotificacion.notId === itemEdit.notId) {
              return itemEdit;
            } else {
              return itemNotificacion;
            }
          }
        );

        setnotificacionesData({
          ...notificacionesData,
          notificacion2: resNotificaciones,
        });
      }
      //refresh the local state
      setitemEdit(false);
    }

    if (nuevoItem) {
      if (
        nuevoItem.notTipo === "ACTIVIDAD" ||
        nuevoItem.notTipo === "CONSIGNA"
      ) {
        setnotificacionesData({
          ...notificacionesData,
          notificacion1: [nuevoItem, ...notificacionesData.notificacion1],
        });
      } else {
        setnotificacionesData({
          ...notificacionesData,
          notificacion2: [nuevoItem, ...notificacionesData.notificacion2],
        });
      }
      //refresh the local state
      setnuevoItem(false);
    }
    // eslint-disable-next-line
  }, [itemEdit, nuevoItem]);

  const [mensaje, setmensaje] = useState({});
  useEffect(() => {
    const getMensaje = async () => {
      try {
        const res = await clienteAxios.get(
          "/actualizaciones/mostrarMensaje/web"
        );
        setmensaje(res?.data || {});
      } catch (error) {
        console.log({ error });
      }
    };
    getMensaje();
  }, []);
  const [openDrawerChat, setopenDrawerChat] = useState(false);
  const onToogleDrawerChat = () => {
    setopenDrawerChat(!openDrawerChat);
  };
  const { socket: socketChat, online, traerGruposEffect } = useProvideSocket();
  const [grupos, setgrupos] = useState([]);
  const [totales, settotales] = useState(0);
  useEffect(() => {
    const handleListaChatsGrupos = (data) => {
      setgrupos(data);
    };

    const handleTotalesActualizados = (data) => {
      if (data) {
        setgrupos(data.refresh_groups);
        settotales(data.unread_messages);
      }
    };

    const handleSendMensaje = (data) => {
      socketChat?.emit("client:totales-actualizados", {
        rucempresa: usuario.rucempresa,
        id_user: usuario.id,
      });
    };

    socketChat?.on("server:lista-chats-grupos", handleListaChatsGrupos);
    socketChat?.on("server:totales-actualizados", handleTotalesActualizados);
    socketChat?.on("server:send-mensaje", handleSendMensaje);

    return () => {
      socketChat?.off("server:lista-chats-grupos", handleListaChatsGrupos);
      socketChat?.off("server:totales-actualizados", handleTotalesActualizados);
      socketChat?.off("server:send-mensaje", handleSendMensaje);
    };
  }, [socketChat, online]);

  const getData = async () => {
    const res = await clienteAxios.get("/messages/chatsNoLeidoYGruposChats/0");

    setgrupos(res.data.refresh_groups);
    settotales(res.data.unread_messages);
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (traerGruposEffect > 1) {
      console.log("traer");
      getData();
    }
  }, [traerGruposEffect]);

  return (
    <>
      <Drawer
        anchor={"right"}
        open={openDrawerChat}
        onClose={onToogleDrawerChat}
        sx={(theme) => ({
          zIndex: theme.zIndex.drawer + 4,
        })}
        PaperProps={{
          sx: (theme) => ({
            backgroundColor: lighten(theme.palette.primary.dark, 0.5),
          }),
        }}
      >
        <Box
          sx={{
            width: 250,
          }}
          role="presentation"
          onClick={onToogleDrawerChat}
          onKeyDown={onToogleDrawerChat}
        >
          <List>
            {grupos
              // .filter((usuario) => usuario.id !== authInfo.id)
              .map((grupo) => (
                <ListItemButton
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                  }}
                  key={grupo.id}
                  onClick={() => {
                    props.history.push(
                      `/Seguridad/chat/?chat_id=${grupo.chat_id}&name=${grupo.chat_name}`
                    );
                  }}
                >
                  <ListItemAvatar>
                    <Badge
                      // color={grupo.online ? "success" : "error"}
                      badgeContent={grupo.unread_messages}
                      color="primary"
                    >
                      <Avatar src={grupo.unread_messages} />
                    </Badge>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{
                      color: (theme) => theme.palette.primary.contrastText,
                    }}
                    primary={grupo.chat_name}
                  />
                </ListItemButton>
              ))}
          </List>
        </Box>
      </Drawer>
      <Box display="flex" alignItems={"center"}>
        <Perfil open={openPerfil} setopen={setopenPerfil} />
        <Encuestas open={openEncuestas} setopen={setopenEncuestas} />
        <Badge badgeContent={totales} color="primary">
          <IconButton
            onClick={() => {
              // props.history.push("/Seguridad/Chat");
              onToogleDrawerChat();
            }}
            variant="text"
            sx={{
              color: (theme) =>
                props.mobile
                  ? theme.palette.secondary.contrastText
                  : theme.palette.primary.contrastText,
            }}
          >
            <Message />
          </IconButton>
        </Badge>
        {/* NOTIFICACIONES 1 */}
        <Box display="flex" alignItems="center">
          <IconButton
            aria-describedby={idNotificacion1}
            type="button"
            onClick={handleClickNotificacion1}
            sx={{
              color: (theme) =>
                props.mobile
                  ? theme.palette.secondary.contrastText
                  : theme.palette.primary.contrastText,
            }}
          >
            <Badge
              badgeContent={
                notificacionesData.notificacion1.filter(
                  (itemNotificacion) => itemNotificacion.notVisto === "NO"
                ).length
              }
            >
              <Campaign />
            </Badge>
          </IconButton>
        </Box>
        <Popper
          sx={{ zIndex: "3500" }}
          id={idNotificacion1}
          open={openNotificacion1}
          anchorEl={anchorElNotificacion1}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener
                  onClickAway={() => setOpenNotificacion1(false)}
                >
                  <MenuList
                    style={{
                      minWidth: "30rem",
                      maxHeight: "20rem",
                      overflow: "scroll",
                    }}
                    role="menu"
                    color="primary"
                  >
                    {[...notificacionesData.notificacion1].map((item, key) => {
                      return (
                        <Box
                          onClick={() => {
                            if (item.notVisto === "SI") {
                              return;
                            }
                            setOpenNotificacion1(false);
                            setopenEncuestas(true);
                          }}
                          maxWidth={"30rem"}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            minHeight: "2.5rem",

                            backgroundColor:
                              item.notVisto === "NO" ? "#096d9c" : "white",
                            borderBottom: "1px solid #e1e1e1",
                            padding: "1rem",
                          }}
                        >
                          <Box maxWidth={"23rem"} minWidth={"23rem"}>
                            <Typography
                              component={"p"}
                              fontSize=".9rem"
                              fontWeight="bold"
                              color={
                                item.notVisto === "NO"
                                  ? "common.white"
                                  : "secondary"
                              }
                            >
                              {item.notTitulo}
                            </Typography>
                            <Typography
                              component={"p"}
                              fontSize=".8rem"
                              color={
                                item.notVisto === "NO"
                                  ? "common.white"
                                  : "secondary"
                              }
                            >
                              {item.notNombrePersona}
                            </Typography>
                            <Typography
                              component={"p"}
                              fontSize=".8rem"
                              color={
                                item.notVisto === "NO"
                                  ? "common.white"
                                  : "secondary"
                              }
                            >
                              {item.notDocuPersona}
                            </Typography>
                            <Typography
                              component={"p"}
                              fontSize=".8rem"
                              color={
                                item.notVisto === "NO"
                                  ? "common.white"
                                  : "secondary"
                              }
                            >
                              {item.notContenido}
                            </Typography>
                            <Typography
                              component={"p"}
                              fontSize=".8rem"
                              color={
                                item.notVisto === "NO"
                                  ? "common.white"
                                  : "secondary"
                              }
                            >
                              {item.notUser}
                            </Typography>
                          </Box>

                          <Box>
                            <Box>
                              <Avatar
                                sx={{ minWidth: "3rem", minHeight: "3rem" }}
                                src={item.notFoto}
                              />
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
        {/* NOTIFICACIONES 2 */}
        <Box mr={2}>
          <IconButton
            aria-describedby={idNotificacion2}
            type="button"
            onClick={handleClickNotificacion2}
          >
            <Badge
              badgeContent={
                notificacionesData.notificacion2.filter(
                  (itemNotificacion) => itemNotificacion.notVisto === "NO"
                ).length
              }
              sx={{
                color: (theme) =>
                  props.mobile
                    ? theme.palette.secondary.contrastText
                    : theme.palette.primary.contrastText,
              }}
            >
              <Notifications />
            </Badge>
          </IconButton>
        </Box>
        <Popper
          sx={{ zIndex: "3500" }}
          id={idNotificacion2}
          open={openNotificacion2}
          anchorEl={anchorElNotificacion2}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener
                  onClickAway={() => setOpenNotificacion2(false)}
                >
                  <MenuList
                    style={{
                      minWidth: "15rem",
                      maxHeight: "20rem",
                      overflow: "scroll",
                    }}
                    role="menu"
                    color="primary"
                  >
                    {[...notificacionesData.notificacion2].map((item, key) => {
                      return (
                        <NavLink
                          to={
                            item.notInformacion.urlweb
                              ? item.notInformacion.urlweb
                              : ""
                          }
                          onClick={() => {
                            if (item.notVisto === "SI") {
                              return;
                            }
                            handleEditar(item);
                          }}
                        >
                          <Box
                            maxWidth={"30rem"}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              minHeight: "2.5rem",

                              backgroundColor:
                                item.notVisto === "NO" ? "#096d9c" : "white",
                              borderBottom: "1px solid #e1e1e1",
                              padding: "",
                            }}
                          >
                            <Box maxWidth={"23rem"} minWidth={"23rem"}>
                              <Typography
                                component={"p"}
                                fontSize=".9rem"
                                fontWeight="bold"
                                color={
                                  item.notVisto === "NO"
                                    ? "common.white"
                                    : "secondary"
                                }
                              >
                                {item.notTitulo}
                              </Typography>
                              <Typography
                                component={"p"}
                                fontSize=".8rem"
                                color={
                                  item.notVisto === "NO"
                                    ? "common.white"
                                    : "secondary"
                                }
                              >
                                {item.notDocuPersona} - {item.notNombrePersona}
                              </Typography>

                              <Typography
                                component={"p"}
                                fontSize=".8rem"
                                color={
                                  item.notVisto === "NO"
                                    ? "common.white"
                                    : "secondary"
                                }
                              >
                                {item.notContenido}
                              </Typography>
                              <Typography
                                component={"p"}
                                fontSize=".8rem"
                                color={
                                  item.notVisto === "NO"
                                    ? "common.white"
                                    : "secondary"
                                }
                              >
                                {item.notUser}
                              </Typography>
                            </Box>

                            <Box>
                              <Box>
                                <Avatar
                                  sx={{ minWidth: "3rem", minHeight: "3rem" }}
                                  src={item.notFoto}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </NavLink>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
        {/* SUBMENU */}
        <Badge
          color="warning"
          badgeContent={
            (mensaje.tipo === "ACTUALIZACION" &&
              usuario.actualizacion_web === 1) ||
            mensaje.tipo === "MANTENIMIENTO"
              ? mensaje.detalle
              : null
          }
          sx={{ ":hover": { cursor: "pointer" } }}
          componentsProps={{
            badge: {
              style: { padding: "1rem" },
              onClick: async () => {
                if (usuario.actualizacion_web === 0) {
                  return;
                }
                const res = await clienteAxios.put(
                  "actualizaciones/actualizar_estado",
                  {
                    tipo: "web",
                  }
                );
                console.log({ res });
                window.location.reload(true);
              },
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Button
            color="inherit" 
            type="button"
            onClick={handleClick}
            sx={{ fontSize: ".8rem", p: 0.25 }}
          >
            <Avatar
              alt={usuario.nombres}
              style={{ marginRight: ".1rem", marginLeft: ".1rem" }}
              src={usuario.foto}
            />

            {usuario.nombre}
          </Button>
        </Badge>{" "}
        <DialogEmpresas
          open={openEmpresasDialog}
          setopen={setopenEmpresasDialog}
        />
        <Popper
          sx={{ zIndex: "3500" }} 
          open={openMenu}
          anchorEl={anchorEl}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
                  <MenuList role="menu" color="primary">
                    {mensaje.tipo === "ACTUALIZACION" &&
                      usuario.actualizacion_web === 1 && (
                        <MenuItem
                          sx={{ color: (theme) => theme.palette.warning.main }}
                          onClick={async () => {
                            const res = await clienteAxios.put(
                              "actualizaciones/actualizar_estado",
                              {
                                tipo: "web",
                              }
                            );
                            window.location.reload(true);
                          }}
                        >
                          Actualizar
                        </MenuItem>
                      )}

                    <MenuItem
                      onClick={() => {
                        props.history.push("/Seguridad/Dashboard");
                      }}
                    >
                      Inicio
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setopenEncuestas(true);
                      }}
                    >
                      Encuestas
                    </MenuItem>
                    {usuario.mis_empresas.length > 0 && (
                      <MenuItem
                        onClick={() => {
                          setopenEmpresasDialog(true);
                          setOpenMenu(false);
                        }}
                      >
                        <Badge
                          badgeContent={usuario.mis_empresas.length}
                          color="primary"
                        >
                          <Typography variant="inherit">
                            Mis Empresas
                          </Typography>
                        </Badge>
                      </MenuItem>
                    )}

                    <MenuItem
                      onClick={() => {
                        setopenPerfil(true);
                      }}
                    >
                      Mis Datos
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        cerrarSesion();

                        props.history.push("/login");
                      }}
                    >
                      Cerrar Sesión
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </Box>
    </>
  );
};

export default withRouter(NotificactionsButtons);
