import React, { useState, useContext, useEffect, memo } from "react";
import Draggable from "react-draggable";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";
import { Autocomplete, Popper } from "@mui/material";
import { replaceCaracteres, trimPropsItem } from "../../../config/const";

// MATERIAL
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  FormControl,
  MenuItem,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { objectDefault } from "../data";
import CalendarioPlus from "../../components/CalendarioPlus";
import { useModalStyle } from "../../styles/styleModal";
import { mismoDia } from "../../functions/fechas";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}
const ModalAsistencia = (props) => {
  const [focusedDate, setFocusedDate] = useState("");
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    desdeAusencia,
    fromHorarios,
    // tabla: "turnoextra",
    // turEstado: "APROBADO",
  } = props;
  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const [clientesData, setclientesData] = useState([]);
  const [personasData, setpersonasData] = useState([]);
  const [puestosNuevos, setpuestosNuevos] = useState([]);
  const [errorFechas, seterrorFechas] = useState({
    errorPermiso: "",
    errorTurnoPersona: "",
  });
  const [turnos, setturnos] = useState([]);
  const PopperMy = function(props) {
    return <Popper {...props} placement="bottom-start" />;
  };
  // eslint-disable-next-line
  const {
    // turIdPersona,
    turDocuPersona,
    turNomPersona,
    // turIdCliente,
    turDocuCliente,
    turNomCliente,
    turPuesto,
    turMotivo,
    turAutorizado,
    turFechas,
    turFechasConsultaDB,
    // turDiasTurno,
    turDetalle,
    turStatusDescripcion,
    turEstado,
    fechasPermiso,
    itemsTurnos,
    fechasPermisoConsultaDB,
  } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({});
  const getCLiente = async () => {
    console.log("hacer lo necesario", ObjectoActivo.turDocuPersona);
    const res = await clienteAxios.get(
      `/personas/filtro/0?search=${ObjectoActivo.turDocuPersona}`
    );

    setpersonasData(res.data.data);
    const resTurnos = res.data.data[0].perTurno.reduce((acc, obj) => {
      return [
        ...acc,
        ...obj.fechasConsultaDB.map((fechaConsulta) => ({
          ...fechaConsulta,
          id: obj.id,
        })),
      ];
    }, []);
    setturnos(resTurnos);
  };
  useEffect(() => {
    if (ObjectoActivo.turDocuPersona !== "") {
      getCLiente();
    }
    if (ObjectoActivo.cliDocNumero !== "" && open) {
      // setpuestosNuevos(itemF.cliDatosOperativos);
      const getDataPuestos = async () => {
        try {
          const resDataClientes = await clienteAxios.get(
            `/clientes/filtro/0?search=${ObjectoActivo.turDocuCliente}&estado=CLIENTES`
          );
          setpuestosNuevos(resDataClientes.data.data[0].cliDatosOperativos);
          const itemConDatosRepuesto =
            ObjectoActivo.turPuesto.length === 0
              ? {
                  ...ObjectoActivo,
                  turPuesto: [
                    {
                      ubicacion: "",
                      puesto: "",
                      id: "",
                    },
                  ],
                }
              : {
                  ...ObjectoActivo,
                  turPuesto: [
                    {
                      ubicacion:
                        resDataClientes.data.data[0].cliDatosOperativos[0]
                          .ubicacion,
                      puesto:
                        resDataClientes.data.data[0].cliDatosOperativos[0]
                          .puesto,
                      id: resDataClientes.data.data[0].cliDatosOperativos[0].id,
                    },
                  ],
                };
          const resJefeOperaciones = await clienteAxios(
            "/personas/filtroporareadepartamento/0?search=&estado=JEFE DE OPERACIONES"
          );
          if (ObjectoActivo.turDocuCliente === "") {
            if (resJefeOperaciones.data.data.length === 0) {
              setitem({
                ...itemConDatosRepuesto,
              });
            } else {
              setitem({
                ...itemConDatosRepuesto,
                turAutorizado:
                  resJefeOperaciones.data.data[0].perNombres +
                  " " +
                  resJefeOperaciones.data.data[0].perApellidos,
              });
            }
          } else {
            setitem({
              ...itemConDatosRepuesto,
            });
          }
        } catch (error) {}
      };

      getDataPuestos();
    }

    // eslint-disable-next-line
  }, [open]);

  const defaultActive = () => {
    changeObjetoActivoState(objectDefault);
    setopen(false);
    seteditar(false);
    seterror({});
    setseccionActive({
      DatosPrincipales: true,
      Apelacion: false,
    });
    setpuestosNuevos([]);
    setturnos([]);
    setcargando(false);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);
  const compararFechas = (obj1, obj2) => {
    const desde1 = new Date(obj1.desde);
    const hasta1 = new Date(obj1.hasta);
    const desde2 = new Date(obj2.desde);
    const hasta2 = new Date(obj2.hasta);

    if (desde1 < hasta2 && hasta1 > desde2) {
      return true;
    } else {
      return false;
    }
  };

  const validacion = () => {
    if (
      turDocuPersona.trim() === "" ||
      turMotivo.trim() === "" ||
      turDocuCliente.trim() === "" ||
      turPuesto[0].puesto.trim() === "" ||
      turFechasConsultaDB.length === 0
    ) {
      seterror({
        turDocuPersona: turDocuPersona.trim() === "" ? true : false,
        turMotivo: turMotivo.trim() === "" ? true : false,
        turDocuCliente: turDocuCliente.trim() === "" ? true : false,
        turPuesto: turPuesto[0].puesto.trim() === "" ? true : false,
        turFechasConsultaDB: turFechasConsultaDB.length === 0,
      });
      return true;
    } else {
      return false;
    }
  };
  const [seccionActive, setseccionActive] = useState({
    DatosPrincipales: true,
    DatosFechas: false,
    Apelacion: false,
  });
  // eslint-disable-next-line
  const { DatosPrincipales, DatosFechas, Apelacion } = seccionActive;
  // eslint-disable-next-line
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }

    try {
      setcargando(true);
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          turDiasTurno: turFechasConsultaDB.length,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          turUser: usuario.usuario,
          turEmpresa: usuario.rucempresa,
          tabla: "turnoextra",
          fromHorarios,
          // tabla: "turnoextra",
          turEstado: fromHorarios ? "APROBADO" : item.turEstado,
        })
      );

      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    try {
      setcargando(true);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          turDiasTurno: turFechasConsultaDB.length,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          turUser: usuario.usuario,
          turEmpresa: usuario.rucempresa,
          tabla: "turnoextra",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  // eslint-disable-next-line

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handleTurnoExtra">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handleTurnoExtra"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"} Turno Extra.
                </Typography>
                <Typography
                  variant="h5"
                  color="initial"
                  align="center"
                ></Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box display={"flex"} flexWrap={"wrap"}>
                  <Button
                    variant={DatosPrincipales ? "contained" : "outlined"}
                    disableElevation
                    className={clases.buttonTab}
                    color={
                      error.turDocuPersona ||
                      error.turMotivo ||
                      error.turDocuCliente ||
                      error.turPuesto
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ DatosPrincipales: true })}
                  >
                    Datos Principales
                  </Button>
                  <Button
                    disabled={turnos.length === 0}
                    variant={DatosFechas ? "contained" : "outlined"}
                    disableElevation
                    className={clases.buttonTab}
                    color={error.turFechasConsultaDB ? "error" : "secondary"}
                    onClick={() => setseccionActive({ DatosFechas: true })}
                  >
                    Fechas {turnos.length === 0 ? "NO TIENE TURNOS" : ""}
                  </Button>
                  {editar ? (
                    <Button
                      variant={Apelacion ? "contained" : "outlined"}
                      disableElevation
                      className={clases.buttonTab}
                      color="secondary"
                      onClick={() => setseccionActive({ Apelacion: true })}
                    >
                      Status
                    </Button>
                  ) : null}
                </Box>
                {/* <FormControlLabel
                control={
                  <Switch
                    checked={
                      conEstado === "ACTIVO" || conEstado === "ACTIVA"
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setitem({
                        ...item,
                        conEstado: e.target.checked ? "ACTIVA" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                    color="primary"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />
              <Box className={clases.container}>
                {DatosPrincipales ? (
                  <>
                    {/* PERSONAS */}
                    <FormControl className={clases.formAllW}>
                      <Autocomplete
                        disabled={fromHorarios}
                        disableClearable={true}
                        options={personasData.map((item) => {
                          return (
                            item.perDocNumero +
                            "/+/" +
                            item.perApellidos +
                            " " +
                            item.perNombres +
                            "/+/" +
                            item.perId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").split("/+/")[0];
                        }}
                        value={
                          (turDocuPersona + " " + turNomPersona).trim() === ""
                            ? ""
                            : turDocuPersona + " " + turNomPersona
                        }
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            try {
                              const itemF = personasData.find((itemPersona) => {
                                return itemPersona.perId === Number(options[2]);
                              });

                              const resTurnos = itemF.perTurno.reduce(
                                (acc, obj) => {
                                  return [
                                    ...acc,
                                    ...obj.fechasConsultaDB.map(
                                      (fechaConsulta) => ({
                                        ...fechaConsulta,
                                        id: obj.id,
                                      })
                                    ),
                                  ];
                                },
                                []
                              );
                              setturnos(resTurnos);
                              setitem({
                                ...item,
                                turDocuPersona: options[0],
                                turNomPersona: options[1].trim(),
                                turIdPersona: options[2],
                                camActualPuesto: [
                                  {
                                    ruccliente: "",
                                    razonsocial: "",
                                    ubicacion: "",
                                    puesto: "",
                                  },
                                ],
                              });
                            } catch (error) {
                              return mostrarAlerta("No hay personas", "error");
                            }
                          } else {
                            setitem({
                              ...item,
                              turDocuPersona: "",
                              turNomPersona: "",
                              turIdPersona: "",
                              camActualPuesto: [
                                {
                                  ruccliente: "",
                                  razonsocial: "",
                                  ubicacion: "",
                                  puesto: "",
                                },
                              ],
                            });
                            setpersonasData([]);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            disabled={fromHorarios}
                            required
                            fullWidth
                            label="Persona"
                            error={error.turDocuPersona}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                setpersonasData([]);
                                return mostrarAlerta("Ingrese datos", "error");
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/personas/filtro/0?search=${e.target.value}`
                                );

                                setpersonasData(res.data.data);
                              } catch (error) {
                                setitem({
                                  ...item,
                                  turDocuPersona: "",
                                  turNomPersona: "",
                                  turIdPersona: "",
                                  camActualPuesto: [
                                    {
                                      ruccliente: "",
                                      razonsocial: "",
                                      ubicacion: "",
                                      puesto: "",
                                    },
                                  ],
                                });
                                setpersonasData([]);

                                return mostrarAlerta(
                                  "No hay personas",
                                  "error"
                                );
                              }
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    {/* CLIENTES */}
                    <FormControl className={clases.formD}>
                      <Autocomplete
                        disableClearable={true}
                        options={clientesData.map((item) => {
                          return (
                            item.cliRazonSocial +
                            "/+/" +
                            item.cliDocNumero +
                            "/+/" +
                            item.cliId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").split("/+/")[0];
                        }}
                        value={
                          (turNomCliente + " " + turDocuCliente).trim() === ""
                            ? ""
                            : turNomCliente + " " + turDocuCliente
                        }
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            try {
                              const itemF = clientesData.find((itemPersona) => {
                                return itemPersona.cliId === Number(options[2]);
                              });

                              setpuestosNuevos(itemF.cliDatosOperativos);
                              setitem({
                                ...item,
                                turIdCliente: options[2],
                                turDocuCliente: options[1],
                                turNomCliente: options[0],
                                turPuesto: [
                                  {
                                    ubicacion: "",
                                    puesto: "",
                                    id: "",
                                  },
                                ],
                              });
                            } catch (error) {
                              return mostrarAlerta("No hay personas", "error");
                            }
                          } else {
                            setitem({
                              ...item,
                              turIdCliente: "",
                              turDocuCliente: "",
                              turNomCliente: "",
                              turPuesto: [
                                {
                                  ubicacion: "",
                                  puesto: "",
                                  id: "",
                                },
                              ],
                            });
                            setclientesData([]);
                            setpuestosNuevos([]);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            fullWidth
                            label="Lugar de Destino Cliente"
                            error={error.turDocuCliente}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta("Ingrese datos", "error");
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                                );

                                setclientesData(res.data.data);
                              } catch (error) {
                                setitem({
                                  ...item,
                                  turIdCliente: "",
                                  turDocuCliente: "",
                                  turNomCliente: "",
                                  turPuesto: [
                                    {
                                      ubicacion: "",
                                      puesto: "",
                                      id: "",
                                    },
                                  ],
                                });
                                setclientesData([]);

                                return mostrarAlerta(
                                  "No hay clientes",
                                  "error"
                                );
                              }
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    {/* PUESTOS NUEVOS */}
                    <FormControl className={clases.formD}>
                      <Autocomplete
                        disableClearable={true}
                        options={puestosNuevos.map((item) => {
                          return item.puesto + "/+/" + item.ubicacion;
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " - ");
                        }}
                        value={
                          (
                            turPuesto[0].puesto +
                            " " +
                            turPuesto[0].ubicacion
                          ).trim() === ""
                            ? ""
                            : turPuesto[0].puesto +
                              " - " +
                              turPuesto[0].ubicacion
                        }
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            const itemF = puestosNuevos.find(
                              (itemPuesto) =>
                                itemPuesto.puesto === options[0] &&
                                itemPuesto.ubicacion === options[1]
                            );
                            setitem({
                              ...item,
                              turPuesto: [
                                {
                                  ubicacion: itemF.ubicacion,
                                  puesto: itemF.puesto,
                                  id: itemF.id,
                                },
                              ],
                            });
                          } else {
                            setitem({
                              ...item,
                              turPuesto: [
                                {
                                  ubicacion: "",
                                  puesto: "",
                                  id: "",
                                },
                              ],
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            fullWidth
                            label="Nuevo Puesto"
                            error={error.turPuesto}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <Autocomplete
                      disabled={editar}
                      className={clases.formD}
                      PopperComponent={PopperMy}
                      disableClearable={true}
                      options={
                        desdeAusencia
                          ? [
                              "EVENTO ESPECIAL",
                              "ENFERMEDAD IESS",
                              "PERMISO PERSONAL",
                              "PERMISO MEDICO",
                              "PATERNIDAD",
                              "DEFUNCION FAMILIAR",
                              "INJUSTIFICADA",
                            ].map((item) => item)
                          : ["EVENTO ESPECIAL"].map((item) => item)
                      }
                      getOptionLabel={(option) => {
                        return option;
                      }}
                      id="controlled-demo"
                      value={turMotivo}
                      paperprops
                      onChange={async (event, newValue) => {
                        if (newValue) {
                          setitem({ ...item, turMotivo: newValue });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Motivo"
                          fullWidth
                          error={error.turMotivo}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />

                    <TextField
                      className={clases.formD}
                      size="small"
                      label="Autorizado por"
                      value={turAutorizado}
                      name="turAutorizado"
                      error={error.turAutorizado}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      multiline
                      rows={3}
                      variant="outlined"
                      className={clases.formAllW}
                      size="small"
                      label="Detalle"
                      value={turDetalle}
                      name="turDetalle"
                      error={error.turDetalle}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : null}

                {DatosFechas ? (
                  <>
                    {error.turFechasConsultaDB ? (
                      <Typography
                        variant="body1"
                        color={"error"}
                        className={clases.formD}
                      >
                        Seleccione al menos una fecha
                      </Typography>
                    ) : null}
                    {errorFechas.errorPermiso !== "" && (
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        width={"100%"}
                      >
                        <Box
                          width={"50%"}
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            sx={{ mb: "1rem" }}
                            variant="body1"
                            color={"error"}
                            align="center"
                          >
                            Error Permiso
                          </Typography>
                          <Box>
                            <Typography
                              variant="body2"
                              color={"error"}
                              align="center"
                            >
                              Desde:{" "}
                              <Typography
                                variant="body2"
                                color={"error"}
                                align="center"
                                component={"span"}
                              >
                                {errorFechas.errorPermiso.desde.replace(
                                  "T",
                                  " "
                                )}
                              </Typography>
                            </Typography>
                            <Typography
                              variant="body2"
                              color={"error"}
                              align="center"
                            >
                              Hasta:{" "}
                              <Typography
                                variant="body2"
                                color={"error"}
                                align="center"
                                component={"span"}
                              >
                                {errorFechas.errorPermiso.hasta.replace(
                                  "T",
                                  " "
                                )}
                              </Typography>
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          width={"50%"}
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            sx={{ mb: "1rem" }}
                            variant="body1"
                            color={"error"}
                            align="center"
                          >
                            Error de turno de {turNomPersona}
                          </Typography>
                          <Box>
                            <Typography
                              variant="body2"
                              color={"error"}
                              align="center"
                            >
                              Desde:{" "}
                              <Typography
                                variant="body2"
                                color={"error"}
                                align="center"
                                component={"span"}
                              >
                                {errorFechas.errorTurnoPersona.desde.replace(
                                  "T",
                                  " "
                                )}
                              </Typography>
                            </Typography>
                            <Typography
                              variant="body2"
                              color={"error"}
                              align="center"
                            >
                              Hasta:{" "}
                              <Typography
                                variant="body2"
                                color={"error"}
                                align="center"
                                component={"span"}
                              >
                                {errorFechas.errorTurnoPersona.hasta.replace(
                                  "T",
                                  " "
                                )}
                              </Typography>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                    <CalendarioPlus
                      filtro={(dias) => {
                        const filtroPorTurnosdePermiso = (fechasAfiltrar) => {
                          return fechasAfiltrar.filter((date) => {
                            const dia = turnos.find((turno) => {
                              return (
                                turno.desde.substring(0, 10) ===
                                new Date(date).toISOString().substring(0, 10)
                              );
                            });
                            if (dia) {
                              const horarioInterrumpido = fechasPermisoConsultaDB.some(
                                (fechaPermisoDB) => {
                                  return compararFechas(dia, fechaPermisoDB);
                                }
                              );
                              return !horarioInterrumpido;
                            }
                            return true;
                          });
                        };
                        const res = fechasPermisoConsultaDB
                          ? filtroPorTurnosdePermiso(dias)
                          : dias.filter(
                              (dia) =>
                                !turnos.some((turno) =>
                                  mismoDia(
                                    Date.parse(dia),
                                    new Date(turno.desde)
                                  )
                                )
                            );
                        const filtroFechasPermioso = (fechas) => {
                          return fechas.filter((fecha) =>
                            fechasPermiso.some((fechaPermiso) => {
                              return mismoDia(fecha, fechaPermiso.desde);
                            })
                          );
                        };
                        const resFechasPermiso = fechasPermiso
                          ? filtroFechasPermioso(res)
                          : res;
                        const filtroFechasTurnos = (fechas) => {
                          return fechas.filter((fecha) => {
                            return !itemsTurnos.some((itemTurno) => {
                              return itemTurno.turFechas.some(
                                (fechaItemTurno) => {
                                  return mismoDia(
                                    Date.parse(fecha),
                                    fechaItemTurno.desde
                                  );
                                }
                              );
                            });
                          });
                        };
                        const resFechasTurno = itemsTurnos
                          ? filtroFechasTurnos(resFechasPermiso)
                          : resFechasPermiso;
                        return resFechasTurno;
                      }}
                      propiedad={turFechasConsultaDB}
                      propiedadDB={turFechasConsultaDB}
                      focusedDate={focusedDate}
                      setFocusedDate={(e) => {
                        if (!e) {
                          seterrorFechas({
                            errorPermiso: "",
                            errorTurnoPersona: "",
                          });
                          return setFocusedDate("");
                        }
                        seterrorFechas({
                          errorPermiso: "",
                          errorTurnoPersona: "",
                        });
                        if (fechasPermisoConsultaDB) {
                          const dia = turnos.find((turno) => {
                            return (
                              turno.desde.substring(0, 10) ===
                              new Date(e).toISOString().substring(0, 10)
                            );
                          });
                          if (dia) {
                            const horarioInterrumpido = fechasPermisoConsultaDB.find(
                              (fechaPermisoDB) => {
                                return compararFechas(fechaPermisoDB, dia);
                              }
                            );
                            if (horarioInterrumpido) {
                              seterrorFechas({
                                errorPermiso: horarioInterrumpido,
                                errorTurnoPersona: dia,
                              });
                            }
                          }
                        }

                        setFocusedDate(e);
                      }}
                      item={item}
                      setitem={(e) => {
                        if (fechasPermiso) {
                          const res = e.turFechasConsultaDB.map(
                            (fechaConsulta) => {
                              const itemF = fechasPermisoConsultaDB.find(
                                (turnoFechaConsulta) =>
                                  fechaConsulta.desde.substring(0, 10) ===
                                  turnoFechaConsulta.desde.substring(0, 10)
                              );
                              if (itemF) {
                                return itemF;
                              }
                              return fechaConsulta;
                            }
                          );
                          return setitem({ ...e, turFechasConsultaDB: res });
                        }
                        return setitem(e);
                      }}
                      TextoPropiedad={"turFechas"}
                      textoPropiedadDB={"turFechasConsultaDB"}
                      // openCalendario={true}
                      // setopenCalendario={setopenCalendario}
                      mapDays={({ date, isSameDate }) => {
                        console.log(turnos);

                        let props = {};

                        if (fechasPermiso) {
                          fechasPermiso.forEach((fecha) => {
                            if (mismoDia(Date.parse(date), fecha.desde)) {
                              props.style = { backgroundColor: "yellow" };
                            }
                          });
                        }
                        turFechas.forEach((ausFecha) => {
                          if (mismoDia(Date.parse(date), ausFecha.desde)) {
                            props.style = { backgroundColor: "lightgreen" };
                          }
                        });

                        if (itemsTurnos) {
                          itemsTurnos.forEach((itemTurno) => {
                            itemTurno.turFechas.forEach((fechaItemTurno) => {
                              if (
                                mismoDia(Date.parse(date), fechaItemTurno.desde)
                              ) {
                                props.style = { backgroundColor: "green" };
                              }
                            });
                          });
                        }

                        if (fechasPermisoConsultaDB) {
                          const dia = turnos.find((turno) => {
                            return (
                              turno.desde.substring(0, 10) ===
                              new Date(date).toISOString().substring(0, 10)
                            );
                          });
                          if (dia) {
                            const horarioInterrumpido = fechasPermisoConsultaDB.some(
                              (fechaPermisoDB) => {
                                return compararFechas(fechaPermisoDB, dia);
                              }
                            );
                            if (horarioInterrumpido) {
                              props.style = { backgroundColor: "red" };
                              return props;
                            }
                          }
                        } else {
                          turnos.forEach((turno) => {
                            if (
                              mismoDia(
                                Date.parse(turno.desde.substring(0, 10)),
                                Date.parse(
                                  new Date(date).toISOString().substring(0, 10)
                                )
                              )
                            ) {
                              console.log(turno.desde);

                              props.style = { backgroundColor: "orange" };
                            }
                          });
                        }

                        if (isSameDate(date, focusedDate)) {
                          props.style = { backgroundColor: "orange" };
                        }

                        return props;
                      }}
                    />

                    {/* <CalendarioPlus
                      filtro={(dias) => {
                        const res = dias.filter((dia) =>
                          turnos.some((turno) =>
                            turno.fechas.some((fecha) =>
                              mismoDia(Date.parse(dia), fecha.desde)
                            )
                          )
                        );
                        return res;
                      }}
                      propiedad={ausFechas}
                      propiedadDB={ausFechasConsultaDB}
                      focusedDate={focusedDate}
                      setFocusedDate={(e) => {
                        const res = turnos.some((turno) =>
                          turno.fechas.some((fecha) =>
                            mismoDia(Date.parse(e), fecha.desde)
                          )
                        ); 
                        setFocusedDate(res ? e : "");
                      }}
                      item={item}
                      setitem={setitem}
                      TextoPropiedad={"ausFechas"}
                      textoPropiedadDB={"ausFechasConsultaDB"}
                      mapDays={({ date, isSameDate }) => {
                        let props = {};
                        turnos.forEach((turno) => {
                          turno.fechas.forEach((fecha) => {
                            if (mismoDia(Date.parse(date), fecha.desde)) {
                              props.style = { backgroundColor: "lightblue" };
                            }
                          });
                        });
                        ausFechas.forEach((ausFecha) => {
                          if (mismoDia(Date.parse(date), ausFecha.desde)) {
                            props.style = { backgroundColor: "lightgreen" };
                          }
                        });
                        if (isSameDate(date, focusedDate)) {
                          props.style = { backgroundColor: "red" };
                        }

                        return props;
                      }}
                      // openCalendario={true}
                      // setopenCalendario={setopenCalendario}
                    /> */}
                  </>
                ) : null}

                {Apelacion ? (
                  <>
                    <Box
                      className={clases.formAllW}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={2}
                    >
                      <Divider style={{ width: "42%" }} />
                      <Typography
                        style={{ width: "100%" }}
                        variant="button"
                        color="primary"
                        align="center"
                        component={"span"}
                      >
                        Verificación
                      </Typography>
                      <Divider style={{ width: "42%" }} />
                    </Box>
                    <TextField
                      className={clases.formAllW}
                      size="small"
                      id="turEstado"
                      label="Estado"
                      value={turEstado}
                      name="turEstado"
                      error={error.turEstado}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem disabled value="">
                        Seleccione.
                      </MenuItem>
                      {["EN PROCESO", "APROBADO", "ANULADA"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      multiline
                      rows={3}
                      variant="outlined"
                      className={clases.formAllW}
                      size="small"
                      id="turStatusDescripcion"
                      label="Status"
                      value={turStatusDescripcion}
                      name="turStatusDescripcion"
                      error={error.turStatusDescripcion}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : null}
              </Box>
              {/* <Divider /> */}
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={
                    cargando ||
                    ((ObjectoActivo.turEstado === "ANULADA" ||
                      ObjectoActivo.turEstado === "APROBADO") &&
                      editar &&
                      !usuario.rol.includes("ADMIN"))
                  }
                  onClick={() => {
                    if (editar) {
                      handleEditar();
                    } else {
                      handleCrear();
                    }
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
