import React, { memo, useState, useContext, useEffect } from "react";

import { Box, Divider, Button } from "@mui/material";

import Tabla from "../../components/Tablas/Informes/Tabla";
import Modal from "../../components/Tablas/Informes/Modal";
import ModalB from "../../components/Tablas/Informes/ModalB";
import AuthContext from "../../Context/Auth/AuthContext";
import BarraTablas from "../../components/BarraTablas/BarraTablas";
import clienteAxios from "../../config/axios";
import AlertaContext from "../../Context/Alerta/AlertaContext";
import InformesContext from "../../Context/Informes/InformesContext";
import { Add, Search } from "@mui/icons-material";

const Informes = () => {
  const [estadoRequest, setestadoRequest] = useState("OPERACIONES");
  const [open, setopen] = useState(false);
  const [buscando, setbuscando] = useState(false);
  const [editar, seteditar] = useState(false);
  const [buscados, setbuscados] = useState([]);
  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [input, setinput] = useState({
    id: "infId",
    label: "ID",
    minWidth: 150,
    tipo: "string",
    profundidad: 1,
    campo1: "infId",
    campo2: "",
    campo3: "",
  });

  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    usuario: { rucempresa },
  } = useContext(AuthContext);
  const [orden, setorden] = useState(false);
  const [openMB, setopenMB] = useState(false);
  const [fullBuscador, setfullBuscador] = useState({
    infFecReg1: "",
    infFecReg2: "",
  });
  //pagina actual
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // input
  //
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  const funcionB = async () => {
    setcargando(true);
    await remplazarRows(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      "padre" // "grupo",
      // fecha
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };
  const { rows, columns, remplazarRows, rowsImport } = useContext(
    InformesContext
  );

  useEffect(() => {
    if (rows && buscando) {
      const _ids = buscados.map((item) => item._id);

      const res = rows.filter((item) => {
        return _ids.indexOf(item._id) >= 0;
      });
      setbuscados(res);
    } // eslint-disable-next-line
  }, [rows]);

  const handleSearch = async () => {
    setcargando(true);
    await remplazarRows(
      rowsPerPage,
      0,
      search,
      input.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      "padre" // "grupo",
      // fecha
    );

    setPage(0);
    setcargando(false);
  };
  const funcionDefault = async () => {
    setcargando(true);
    setfullBuscador({
      infFecReg1: "",
      infFecReg2: "",
    });
    await remplazarRows(
      rowsPerPage,
      0,
      "",
      input.id,
      !orden,
      JSON.stringify({
        infFecReg1: "",
        infFecReg2: "",
      }),
      rucempresa,
      estadoRequest,
      "padre" // "grupo",
      // fecha
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };
  const funcArchivo = async (e) => {
    if (!e.target.files[0]) {
      return;
    } else {
      if (e.target.files[0].type !== "text/plain") {
        return mostrarAlerta("El archivo no es de texto");
      } else {
        const formDataLogos = new FormData();
        formDataLogos.append("archivo", e.target.files[0]);
        formDataLogos.append("tipo", "compras");
        const res = await clienteAxios.post(
          "/uploads_comprastxt",
          formDataLogos
        );
        rowsImport({ res });
      }
    }
  };
  return (
    <Box>
      <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        LabelBuscador="Buscar por todos los campos"
        funcionDefault={funcionDefault}
        botones={[
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              setopen(true);
            },
            disabled: false,
            Icon: Add,
            color: "primary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },        {
                      tooltip: "Busqueda avanzada",
                      texto: "",
                      funcion: () => {
                        setopenMB(true);
                      },
                      disabled: false,
                      Icon: Search,
                      color: "success",
                      id: 2,
                      ocultar: false,
                      tipo: "icono",
                      variante: "contained",
                      size: "medium",
                      sx: {
                        ml: 1,
                      },
                    },
        ]}
        ocultarMigasDePan={false}
      />{" "}
      <ModalB
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={`Busqueda avanzada ${"Informes"} `}
        columns={columns}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        setbuscando={setbuscando}
        funcionDefault={funcionDefault}
      />{" "}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems=" stretch"
        width="100%"
      >
        <Box>
          <Button
            size="small"
            variant={estadoRequest === "OPERACIONES" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRows(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify({
                  fechaFactura1: "",
                  fechaFactura2: "",
                  fechaAutorizacion1: "",
                  fechaAutorizacion2: "",
                  infFecReg1: "",
                  infFecReg2: "",
                }),
                rucempresa,
                "OPERACIONES",
                "padre" // "grupo",
                // fecha
              );

              setPage(0);
              setestadoRequest("OPERACIONES");
              setcargando(false);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="primary"
          >
            OPERACIONES
          </Button>
          <Button
            size="small"
            variant={estadoRequest === "SUPERVISORES" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRows(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify({
                  fechaFactura1: "",
                  fechaFactura2: "",
                  fechaAutorizacion1: "",
                  fechaAutorizacion2: "",
                  infFecReg1: "",
                  infFecReg2: "",
                }),
                rucempresa,
                "SUPERVISORES",
                "padre" // "grupo",
                // fecha
              );

              setPage(0);
              setestadoRequest("SUPERVISORES");
              setcargando(false);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="primary"
          >
            SUPERVISORES
          </Button>
          <Button
            size="small"
            variant={estadoRequest === "GUARDIAS" ? "contained" : null}
            onClick={async () => {
              setcargando(true);
              await remplazarRows(
                rowsPerPage,
                0,
                "",
                input.id,
                orden,
                JSON.stringify({
                  fechaFactura1: "",
                  fechaFactura2: "",
                  fechaAutorizacion1: "",
                  fechaAutorizacion2: "",
                  infFecReg1: "",
                  infFecReg2: "",
                }),
                rucempresa,
                "GUARDIAS",
                "padre" // "grupo",
                // fecha
              );

              setPage(0);
              setestadoRequest("GUARDIAS");
              setcargando(false);
            }}
            disableElevation
            style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
            color="primary"
          >
            GUARDIAS
          </Button>
        </Box>
      </Box>
      <Divider />
      <Tabla
        estadoRequest={estadoRequest}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        search={search}
        input={input}
        orden={orden}
        setinput={setinput}
        setorden={setorden}
        seteditar={seteditar}
        setopen={setopen}
        rows={rows}
        buscando={buscando}
        buscados={buscados}
        cargando={cargando}
        setcargando={setcargando}
        fullBuscador={fullBuscador}
        setbuscando={setbuscando}
        setsearch={setsearch}
        setestadoRequest={setestadoRequest}
      />
      <Modal
        estadoRequest={estadoRequest}
        editar={editar}
        seteditar={seteditar}
        setopen={setopen}
        open={open}
      />
    </Box>
  );
};

export default memo(Informes);
