import React, { memo, useContext } from "react";

import { TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/ConfirmacionDialog";
import ImagenCelda from "../../../../components/ImagenCelda";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../components/ButtonsAction";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import {
  Cancel,
  Create,
  DeleteForever,
  PictureAsPdf,
  Restore,
} from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import { trimPropsItem } from "../../../../../config/const";
import clienteAxios from "../../../../../config/axios";
import CeldaUser from "../../../../components/CeldaUser";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    // HOOK DE PERMISO
    tienePermiso,
    alertaPermiso,
    // ROW
    index,
    rowHijo,
    rowPadre,
    // OPEN Y EDIT
    setopen,
    seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // FUNCION STATE
    changeObjetoActivoState,
    // SOCKET
    socket,
    tabla,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowHijo.editado === true
      ? classes.celdaEditado
      : rowHijo.nuevo === true
      ? classes.celdaNuevo
      : rowHijo.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        nomUser: usuario.usuario,
        nomEmpresa: usuario.rucempresa,
        [campoExport]: rowHijo[campoExport],
        nomFecReg: rowHijo.nomFecReg,
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...rowHijo,
          nombre: usuario.nombre,
          tabla,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          nomUser: usuario.usuario + "/+/" + rowHijo[campoExport],
          nomEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {}
  };
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={rowHijo}
          agrupado={true}
          rowPadre={rowPadre}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar();
              },
              disabled: usuario.usuario !== rowHijo.nomUser.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: rowHijo.eliminado || true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                setopen(true);
                seteditar(true);
                changeObjetoActivoState(rowHijo);
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "PDF",
              texto: "",
              funcion: () => {
                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/renuncia_individual?nomId=${rowHijo.nomId}&rucempresa=${rowHijo.nomEmpresa}&usuario=${usuario.usuario}&nombre=${usuario.nombre}`
                );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 2,
              ocultar: rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.nomDocuPersona + " " + rowHijo.nomNomPersona}
          </TableCell>
          <ImagenCelda
            propiedad={rowHijo.nomFotos}
            array={true}
            claseCelda={claseCelda}
          />
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.nomMotivo}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {`${rowHijo?.nomCliRuc ?? "-"} ${rowHijo?.nomCliRazonSocial ?? ""}`}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.nomDetalle}
          </TableCell>
          <TableCell
            size="small"
            align="center"
            className={`${claseCelda}  `}
            style={{
              color:
                rowHijo.nomEstado === "APROBADO"
                  ? "green"
                  : rowHijo.nomEstado === "EN PROCESO"
                  ? "orange"
                  : rowHijo.nomEstado === "ANULADA"
                  ? "red"
                  : "red",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            {rowHijo.nomEstado}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.nomFecha}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(rowHijo.nomFecReg).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>

          <CeldaUser user={rowHijo.nomUser} claseCelda={claseCelda} />
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
