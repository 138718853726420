import React, { useState, useContext, useEffect } from "react";
import { blue } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Hidden,
  IconButton,
  InputAdornment,
  Grid,
  Alert,
} from "@mui/material";
import AlertaContext from "../../Context/Alerta/AlertaContext";
import AuthContext from "../../Context/Auth/AuthContext";
import {
  VisibilityOff,
  Visibility,
  Person,
  Business,
} from "@mui/icons-material";
import Fondo from "../../assets/img/bg.jpg";
import imgLogoNeitor from "../../assets/img/sigeoplogo.png";
import clienteAxios from "../../config/axios";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "calc(100vh - 20px)",
  },

  imagen: {
    textAlign: "center",
    width: "100%",
    minHeight: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
  },
  BG: {
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${Fondo})`,
    },
  },
  movilBorder: {
    width: "80%",
    margin: "0 auto",
    padding: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3), 
      border: "5px solid #0275a4",
      borderRadius: "15px",
    },
  },

  font: {
    color: "#5ad2f5",
  },
  enlace: {
    color: "#5ad2f5",
    fontSize: "14px",
    "&:hover": {
      color: "#2e3b9b",
    },
  },
  boton: {
    backgroundColor: "#5ad2f5",
    "&:hover": {
      backgroundColor: blue[500],
    },
  },
  or: {
    position: "fixed",
  },
  width: {
    width: "42%",
  },
  botonValidar: {
    backgroundColor: "#2c3a47",
    color: "white",
    "&:hover": {
      boxShadow: "1px 2px 3px solid #0f1d28 ",
      backgroundColor: "#0f1d28",
    },
  },
}));

export default function Login(props) {
  const { alerta, mostrarAlerta } = useContext(AlertaContext);
  const { iniciarSesion, mensaje, autenticado } = useContext(AuthContext);
  const [check, setCheck] = useState(false);
  const [recuperarForm, setrecuperarForm] = useState({
    email: "",
    empresa: "",
  });
  const [form, setform] = useState({
    empresa: "",
    usuario: "",
    password: "",
  });
  const [verpass, setverpass] = useState(false);
  const { empresa, usuario, password } = form;
  const [recuperarContraseña, setrecuperarContraseña] = useState(false);
  const [cargando, setcargando] = useState(false);
  const classes = useStyles();
  const handleCheck = (event) => {
    setCheck(event.target.checked);
  };
  const login = () => {
    setrecuperarContraseña(false);
  };
  const recuperar = () => {
    setrecuperarContraseña(true);
  };

  const botonRecuperar = () => {
    if (recuperar.email !== "" || recuperar.empresa !== "") {
      return true;
    }
  };
  const botonLogin = () => {
    if (
      empresa.trim() !== "" &&
      usuario.trim() !== "" &&
      password.trim() !== ""
    ) {
      return true;
    }
    return false;
  };

  const handleChange = (e) => {
    setform({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (botonLogin() === false) {
      return mostrarAlerta("Los campos son obligatorios", "error");
    }

    iniciarSesion({ usuario, password, empresa, from: "web" });
    if (check) {
      localStorage.setItem("usuario", usuario);
      localStorage.setItem("password", password);
      localStorage.setItem("empresa", empresa);
      localStorage.setItem("recordar", true);
    } else {
      localStorage.setItem("usuario", "");
      localStorage.setItem("password", "");
      localStorage.setItem("empresa", "");
      localStorage.setItem("recordar", false);
    }
  };

  useEffect(() => {
    if (autenticado) {
      props.history.push("/Seguridad/Dashboard");
    }
    if (mensaje) {
      mostrarAlerta(mensaje.msg, mensaje.categoria);
    }
    //eslint-disable-next-line
  }, [mensaje, autenticado]);

  useEffect(() => {
    const recordar = localStorage.getItem("recordar") === "true" ? true : false;

    setCheck(recordar);
    if (recordar) {
      const usuario = localStorage.getItem("usuario");
      const password = localStorage.getItem("password");
      const empresa = localStorage.getItem("empresa");

      setform({
        ...form,
        usuario: usuario ? usuario : "",
        password: password ? password : "",
        empresa: empresa ? empresa : "",
      });
    }
    //eslint-disable-next-line
  }, []);
  return (
    <div className={`${classes.imagen} ${classes.BG}`}>
      <Grid>
        <Grid
          container
          spacing={0}
          sx={{
            border: "12px solid #0275a4",
            minHeight: "calc(100vh)",
            borderRadius: "10px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={5}
            xl={5}
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box
              display="flex"
              flexDirection="column"
              className={classes.movilBorder}
              sx={
                {
                  // position: "relative",
                  // height: "100vh",
                  // backgroundRepeat: "no-repeat",
                  // backgroundSize: "cover",
                  // "&::before": {
                  //   content: "close-quote",
                  //   backgroundColor: "white",
                  //   position: "absolute",
                  //   top: 0,
                  //   left: 0,
                  //   right: 0,
                  //   bottom: 0,
                  //   opacity: 0.75,
                  // },
                }
              }
            >
              <Box sx={{ position: "relative" }}>
                {alerta ? (
                  <Alert severity={alerta.categoria} variant="filled">
                    {alerta.msg}
                  </Alert>
                ) : null}
                <br />

                <Box display="flex" justifyContent={"center"} width="100%">
                  <img
                    src={imgLogoNeitor}
                    alt="logo"
                    style={{ width: "60%" }}
                  />
                </Box>

                {recuperarContraseña ? (
                  <>
                    <Box mt={4} mb={3}>
                      <Typography variant="h4" color="secondary" align="center">
                        Recuperar Contraseña
                      </Typography>
                    </Box>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={recuperarForm.empresa}
                      id="empresa"
                      label="Empresa"
                      name="empresa"
                      autoComplete="empresa"
                      onChange={(e) =>
                        setrecuperarForm({
                          ...recuperarForm,
                          [e.target.name]: e.target.value.toUpperCase(),
                        })
                      }
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={recuperarForm.email}
                      id="email"
                      label="Usuario"
                      name="email"
                      autoComplete="email"
                      onChange={(e) => {
                        setrecuperarForm({
                          ...recuperarForm,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                    <Box display="flex" justifyContent="center" mt={2}>
                      <Button
                        onClick={async () => {
                          try {
                            const res = await clienteAxios.post(
                              "auth/password",
                              {
                                usuario: recuperarForm.email,
                                empresa: recuperarForm.empresa,
                              }
                            );
                            console.log({ res });
                            mostrarAlerta(res?.data?.msg, "success");
                          } catch (error) {
                            setcargando(false);
                            mostrarAlerta(
                              "Hubo un error al recuperar",
                              "error"
                            );
                          }
                        }}
                        disabled={cargando}
                        type="button"
                        variant="contained"
                        fullWidth
                        color={botonRecuperar() ? "secondary" : "inherit"}
                      >
                        Recuperar
                      </Button>
                    </Box>
                    <Box display="flex" justifyContent="center" mx={2}>
                      <Button
                        to="#!"
                        color="secondary"
                        sx={{
                          fontSize: ".6rem",
                          padding: ".2rem",
                          marginTop: "1rem",
                        }}
                        onClick={() => login()}
                      >
                        Iniciar Sesión
                      </Button>
                    </Box>
                  </>
                ) : (
                  // LOGIN
                  <>
                    <Typography
                      variant="h4"
                      color="secondary"
                      align="center"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Iniciar Sesión
                    </Typography>

                    <form
                      className={classes.form}
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        value={empresa}
                        fullWidth
                        id="empresa"
                        onChange={(e) => {
                          setform({
                            ...form,
                            [e.target.name]: e.target.value.toUpperCase(),
                          });
                        }}
                        label="Código Empresa"
                        name="empresa"
                        color="primary"
                        autoComplete="Código empresa"
                        autoFocus
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton aria-label="">
                                <Business color="secondary" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="usuario"
                        color="primary"
                        label="Email / Usuario"
                        name="usuario"
                        value={usuario}
                        onChange={handleChange}
                        autoComplete="usuario"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton aria-label="">
                                <Person color="secondary" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        type={verpass ? "text" : "password"}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        id="password"
                        value={password}
                        onChange={handleChange}
                        autoComplete="current-password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              {verpass ? (
                                <IconButton
                                  aria-label=""
                                  onClick={() => setverpass(!verpass)}
                                >
                                  <Visibility color="secondary" />
                                </IconButton>
                              ) : (
                                <IconButton
                                  aria-label=""
                                  onClick={() => setverpass(!verpass)}
                                >
                                  <VisibilityOff color="secondary" />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box
                        width="100% "
                        justifyContent="space-between"
                        display="flex"
                        pl={1}
                        alignItems="center"
                        mb={1}
                        mt={1}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={check}
                              onChange={handleCheck}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label="Recuerdame"
                        />
                        <Button
                          color="secondary"
                          style={{
                            fontSize: ".6rem",
                            padding: ".2rem",
                            margin: ".2rem",
                          }}
                          onClick={() => recuperar()}
                        >
                          ¿Olvidaste tu contraseña ?
                        </Button>
                      </Box>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color={botonLogin() ? "secondary" : "inherit"}
                      >
                        Iniciar
                      </Button>
                    </form>

                    {/* <Box
                  mb={1}
                  mt={4}
                  pr={2}
                  pl={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Divider className={classes.width} /> O 
                  <Divider className={classes.width} />
                </Box>

                <Button className={classes.font && classes.enlace}>
                  Registrarse 
                </Button> */}
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
