import React, { memo, useContext } from "react";

import { useState } from "react";
import AuthContext from "../../../../../Context/Auth/AuthContext";
// MATERIAL
import { MenuItem, TableCell, TableRow, TextField } from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";

import { trimPropsItem } from "../../../../../config/const";
import ButtonsAction from "../../../../components/ButtonsAction";
import { itemHijoDefecto } from "../../data";
import CampoDoc from "../../../../components/CampoDoc";

const RowAgregarItem = (props) => {
  // eslint-disable-next-line
  const [cargando, setcargando] = useState(false);
  const { itemPadre, setagregarItemLista, socket } = props;
  const { usuario } = useContext(AuthContext);

  const [itemNuevo, setItemNuevo] = useState(itemHijoDefecto);
  const botonDisabled = () => {
    if (
      itemNuevo.detalle === "" ||
      itemNuevo.fecha === "" ||
      itemNuevo.cuota === "" ||
      itemNuevo.estado === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const botones = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        setagregarItemLista(false);
        setItemNuevo(itemHijoDefecto);
      },
      disabled: false,
      Icon: Cancel,
      color: "secondary",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: () => {
        handleAgregar();
        setagregarItemLista(false);
        setItemNuevo(itemHijoDefecto);
      },
      disabled: botonDisabled() || cargando,
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];

  const classes = useRowStyles();

  const handleChange = (e) => {
    setItemNuevo({
      ...itemNuevo,
      [e.target.name]: e.target.value,
    });
  };

  const handleAgregar = async () => {
    try {
      const estCuotas = [
        trimPropsItem({ ...itemNuevo, user: usuario.usuario }),
        ...itemPadre.estCuotas,
      ];
      console.log({
        ...itemPadre,
        tabla: "estadocuenta",
        estCuotas,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        planUser: usuario.usuario,
        planEmpresa: usuario.rucempresa,
      });

      socket.current.emit("client:actualizarData", {
        ...itemPadre,
        tabla: "estadocuenta",
        estCuotas,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        planUser: usuario.usuario,
        planEmpresa: usuario.rucempresa,
      });
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ButtonsAction botones={botones} />

        {/* las demas columnas */}

        <>
          <TableCell fontSize="small" align="center" className={classes.celda}>
            <CampoDoc
              setcargando={setcargando}
              propiedad={"documento"}
              item={itemNuevo}
              setitem={setItemNuevo}
              celda={true}
              disabled={cargando}
            />
          </TableCell>
          <TableCell fontSize="small" align="center" className={classes.celda}>
            <TextField
              multiline
              rows={1}
              autoFocus
              value={itemNuevo.detalle}
              name="detalle"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell fontSize="small" align="center" className={classes.celda}>
            <TextField
              type="date"
              value={itemNuevo.fecha}
              name="fecha"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            fontSize="small"
            padding="none"
            align="center"
            className={classes.celda}
          >
            <TextField
              type="number"
              value={itemNuevo.cuota}
              name="cuota"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            fontSize="small"
            padding="none"
            align="center"
            className={classes.celda}
          >
            <TextField
              value={itemNuevo.estado}
              name="estado"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione.</MenuItem>
              {["PENDIENTE", "CANCELADO"].map((item) => {
                return <MenuItem value={item}>{item} </MenuItem>;
              })}
            </TextField>
          </TableCell>
          <TableCell
            fontSize="small"
            padding="none"
            align="center"
            className={classes.celda}
          >
            <TextField
              type="date"
              value={itemNuevo.fechaCancelacion}
              name="fechaCancelacion"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            fontSize="small"
            padding="none"
            align="center"
            className={classes.celda}
          >
            <TextField
              multiline
              maxRows={3}
              value={itemNuevo.observacion}
              name="observacion"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            fontSize="small"
            padding="none"
            align="center"
            className={classes.celda}
          >
            <TextField
              value={itemNuevo.pagoCliente}
              name="pagoCliente"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell fontSize="small" align="center" className={classes.celda}>
            <CampoDoc
              disabled={cargando}
              setcargando={setcargando}
              propiedad={"documento2"}
              item={itemNuevo}
              setitem={setItemNuevo}
              celda={true}
            />
          </TableCell>
          <TableCell
            fontSize="small"
            padding="none"
            align="center"
            className={classes.celda}
          >
            {usuario.usuario}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarItem);
