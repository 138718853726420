import React, { memo, useContext } from "react";

import { TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../components/ButtonsAction";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import {
  Cancel,
  Create,
  DeleteForever,
  Email,
  PhoneAndroid,
  PictureAsPdf,
  Restore,
} from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import { trimPropsItem } from "../../../../../config/const";
import clienteAxios from "../../../../../config/axios";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    // HOOK DE PERMISO
    tienePermiso,
    alertaPermiso,
    // ROW
    index,
    rowSubHijo,
    // rowHijo,
    rowPadre,
    // OPEN Y EDIT
    setopen,
    seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // FUNCION STATE
    changeObjetoActivoState,
    // SOCKET
    socket,
    tabla,
    // ESTADO,
    estadoRequest,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowSubHijo.editado === true
      ? classes.celdaEditado
      : rowSubHijo.nuevo === true
      ? classes.celdaNuevo
      : rowSubHijo.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;

  // CONTEXT
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        rolpUser: usuario.usuario,
        rolpEmpresa: usuario.rucempresa,
        [campoExport]: rowSubHijo[campoExport],
        rolpPeriodo: rowSubHijo.rolpPeriodo,
        rolpDocNumCliente: rowSubHijo.rolpDocNumCliente,
        rolpIdCliente: rowSubHijo.rolpIdCliente,
        option: "subhijo",
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...rowSubHijo,
          nombre: usuario.nombre,
          tabla,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          rolpUser: usuario.usuario + "/+/" + rowSubHijo[campoExport],
          rolpEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {}
  };
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={rowSubHijo}
          agrupado={true}
          rowPadre={rowPadre}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar();
              },
              disabled: usuario.usuario !== rowSubHijo.rolpUser.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !rowSubHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: rowSubHijo.eliminado || estadoRequest === "PROCESADO",
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                setopen(true);
                seteditar(true);
                changeObjetoActivoState(rowSubHijo);
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: rowSubHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Enviar Email",
              texto: "",
              funcion: async () => {
                try {
                  const res = await clienteAxios.post(`rolpagos/enviarcorreo`, {
                    option: "individual",
                    rolpIdPersona: rowSubHijo.rolpIdPersona,
                    rolpPeriodo: rowSubHijo.rolpPeriodo,
                    rolpDocNumCliente: rowSubHijo.rolpDocNumCliente,
                  });
                  mostrarAlerta(res.data.data);
                } catch (error) {
                  mostrarAlerta("Hubo un error");
                }
              },
              disabled: false,
              Icon: Email,
              color: "primary",
              id: 1,
              ocultar: rowSubHijo.eliminado || estadoRequest !== "PROCESADO",
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: `PDF - ${rowSubHijo.rolpEmpresa}`,
              texto: "",
              funcion: () => {
                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/rolpagoindividual?rolpId=${rowSubHijo.rolpId}&rucempresa=${rowSubHijo.rolpEmpresa}`
                );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 2,
              ocultar: rowSubHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: `Ver en APP`,
              texto: "",
              funcion: () => {},
              disabled: rowSubHijo.visualizacion_app === "NO",
              Icon: PhoneAndroid,
              color: "success",
              id: 2,
              ocultar: rowSubHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowSubHijo.rolpCedula}
          </TableCell>

          <TableCell size="small" align="center" className={claseCelda}>
            {rowSubHijo.rolpNombres}
          </TableCell>

          <TableCell size="small" align="center" className={claseCelda}>
            {rowSubHijo.rolpCargo}
          </TableCell>

          <TableCell size="small" align="center" className={claseCelda}>
            {rowSubHijo.rolpCiudad}
          </TableCell>

          <TableCell size="small" align="center" className={claseCelda}>
            ${rowSubHijo.rolpSueldo}
          </TableCell>

          <TableCell size="small" align="center" className={claseCelda}>
            {rowSubHijo.rolpDiaLabo}
          </TableCell>

          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(rowSubHijo.rolpFecReg).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowSubHijo.rolpUser.split("/+/")[0]}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
